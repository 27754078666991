import React, { Component } from 'react';
import ClickOutsideListener from "./ClickOutsideListener"

class CookieBanner extends Component {
	constructor(props) {
		super(props);

		this.cookieAcceptance = this.cookieAcceptance.bind(this);
		this.isCookieAccept = this.isCookieAccept.bind(this);
		this.closePolicyPopUp = this.closePolicyPopUp.bind(this);
		this.showPolicyPopUp = this.showPolicyPopUp.bind(this);

		this.cookiePopUp = React.createRef();
		this.cookieBanner = React.createRef();
	}
	componentDidMount(){
		this.isCookieAccept();
	}
	isCookieAccept(){
		if(localStorage.getItem('isCookieAccept') === null){
			this.cookieBanner.current.classList.add('show');
		}
	}
	cookieAcceptance (){
		localStorage.setItem('isCookieAccept', true);

		this.cookieBanner.current.classList.add('hide');
	}

	showPolicyPopUp (){
		let body = document.querySelector(".page-wrap");
		body.classList.add('disable-scrolling');
		body.classList.add('overlay');
		this.cookiePopUp.current.classList.add('show');
	}
	closePolicyPopUp (){
		let body = document.querySelector(".page-wrap");
		body.classList.remove('disable-scrolling');
		body.classList.remove('overlay');
		this.cookiePopUp.current.classList.remove('show')
	}
	render(){
		return(
			<>
				<ClickOutsideListener onClickOutside={this.closePolicyPopUp}>
					<div className="cookie-policy-popUp" ref={this.cookiePopUp}>
						<button className="closePopUpBtn" onClick={this.closePolicyPopUp}><i className="fas fa-times"></i></button>
						<h2>Cookies Policy</h2>
						<p>Last updated: 13.01.2020</p>
						<p>DNH Soft LTD (&quotus&quot, &quotwe&quot, or &quotour&quot) uses cookies on https://dnhsoft.com (the &quotService&quot).
							By using the Service, you consent to the use of cookies.</p>
						<p>Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with
						may use cookies on the Service, your choices regarding cookies and further information about cookies.</p>
						<h3>What are cookies</h3>
						<p>Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in
						your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the
						Service more useful to you.</p>
						<p>Cookies can be &quotpersistent&quot or &quotsession&quot cookies.</p>
						<h3>How DNH Soft LTD uses cookies</h3>
						<p>When you use and access the Service, we may place a number of cookies files in your web browser.</p>
						<p>We use cookies for the following purposes: to enable certain functions of the Service, to provide analytics,
						to store your preferences, to enable advertisements delivery, including behavioral advertising.</p>
						<p>We use both session and persistent cookies on the Service and we use different types of cookies to run the Service:</p>
						<p>- Essential cookies. We may use essential cookies to authenticate users and prevent fraudulent useof user accounts.</p>
						<h3>Third-party cookies</h3>
						<p>In addition to our own cookies, we may also use various third-parties cookies to report usage statistics of the Service,
						deliver advertisements on and through the Service, and so on.</p>
						<h3>What are your choices regarding cookies</h3>
						<p>If you&aposd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.</p>
						<p>Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be
						able to store your preferences, and some of our pages might not display properly.</p>
						<h3>Where can your find more information about cookies</h3>
						<p>You can learn more about cookies and the following third-party websites:</p>
						<ul>
							<li>AllAboutCookies: <a href="http://www.allaboutcookies.org/" target="_blank" rel="noopener noreferrer">www.allaboutcookies.org/</a></li>
							<li>Network Advertising Initiative: <a href="http://www.networkadvertising.org/" target="_blank" rel="noopener noreferrer">www.networkadvertising.org/</a></li>
						</ul>
					</div>
				</ClickOutsideListener>
				<div className="cookie-policy-banner" ref={this.cookieBanner}>
					<p>
						We use cookies to ensure that we give you the best possible experience on our website.
						If you continue using the website, we assume that you agree with our <button id="cookiePolicyBtn" onClick={this.showPolicyPopUp}>Cookie Policy</button>.
					</p>
					<button id="cookieBtn" onClick={this.cookieAcceptance}>OK</button>
				</div>
			</>
		);
	}
}

export default CookieBanner
