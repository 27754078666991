import React from "react"

const CurlyLineDot = () => (
	<svg className="curly-line-dot" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.666 108.719">
		<g transform="translate(-960 -1850.4)">
			<path className="a" d="M-887.1,41.8c-5.5,5.5-11.5,5.1-16.4,4.8-4.3-.3-7.7-.5-11,2.8s-3,6.7-2.8,11c.3,4.9.7,10.9-4.8,16.4s-11.5,5.1-16.4,4.8c-4.3-.3-7.7-.5-11,2.8s-3,6.7-2.8,11c.3,4.9.7,10.9-4.8,16.4s-11.5,5.1-16.4,4.8c-4.3-.3-7.7-.5-11,2.8a3.383,3.383,0,0,1-4.9,0,3.383,3.383,0,0,1,0-4.9c5.5-5.5,11.5-5.1,16.4-4.8,4.3.3,7.7.5,11-2.8s3-6.7,2.8-11c-.3-4.9-.7-10.9,4.8-16.4s11.5-5.1,16.4-4.8c4.3.3,7.7.5,11-2.8s3-6.7,2.8-11c-.3-4.9-.7-10.9,4.8-16.4s11.5-5.1,16.4-4.8c4.3.3,7.7.5,11-2.8a3.383,3.383,0,0,1,4.9,0A3.565,3.565,0,0,1-887.1,41.8Z" transform="matrix(-0.966, -0.259, 0.259, -0.966, 94.79, 1737.399)" />
			<circle className="b" cx="11.2" cy="11.2" r="11.2" transform="translate(1017.181 1897.891) rotate(-112.5)" />
		</g>
	</svg>
)

export default CurlyLineDot
