import React from "react"
import { StaticQuery, graphql } from "gatsby"
import GraphImg from "graphcms-image"

const HeaderInfo = () => (
	<StaticQuery
		query={graphql`
			query {
				dnhCms {
					contactses(where: {title: "Phone", status: PUBLISHED}) {
						id
						contactInfo
					}
					images(where: {title: "Help"}) {
						alt
						asset {
							handle
							height
							width
						}
					}
				}
			}
		`}
		render={data => (
			<>
				<div className="info-box">
					<GraphImg
						image={data.dnhCms.images[0].asset}
						fit="scale"
						blurryPlaceholder={false}
						backgroundColor={false}
						withWebp={true}
						alt={data.dnhCms.images[0].alt} />
					<p>Have any questions?</p>
					{data.dnhCms.contactses.map(info => (
						<p key={info.id}>
							{info.contactInfo.split(',').map((phone, index) => (
								<a key={index} href={`tel:${phone}`}>{phone}</a>
							))}
						</p>
					))}
				</div>
			</>
		)}
	/>
)
export default HeaderInfo
