import React from "react"
import Slider from "react-slick"
import { Link, useStaticQuery, graphql } from "gatsby"
const MainSlider = () => {
	var settings = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true
	};
	const data = useStaticQuery(graphql`
		query {
			dnhCms {
				mainSliderContents(where: {status: PUBLISHED}){
					id
					heading
					copy
					subPage {
						pageTitle
						page {
							pageTitle
						}
					}
				}
			}
		}
	`);
	return (
		<div className="main-slider">
			<Slider {...settings}>
				{data.dnhCms.mainSliderContents.map(sliderItem => (
					<div key={sliderItem.id} className="main-slider__item">
						<h2>{sliderItem.heading}</h2>
						<p>{sliderItem.copy}</p>
						<Link
							to={`/${sliderItem.subPage.page.pageTitle.replace(/\s+/g, '-').toLowerCase()}/${sliderItem.subPage.pageTitle.replace(/\s+/g, '-').toLowerCase()}`}>
							Find out more
							<i className="fas fa-arrow-right"></i>
						</Link>
					</div>
				))}
			</Slider>
		</div>
	);
}
export default MainSlider;
