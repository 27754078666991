import React from "react"
import PropTypes from "prop-types"
import { Link, StaticQuery, graphql } from "gatsby"

const Logo = ({type, helpClass}) => (
	<StaticQuery
		query={graphql`
			query {
				dnhCms {
					logoes(where: {status: PUBLISHED}){
						id
						name
						alternativeText
						logo{
							url(
								transformation: {
									image: { resize: {
										width: 200
										fit: scale
									} }
								}
							)
						}
					}
				}
			}
		`}
		render={data => (
			<>
				{data.dnhCms.logoes.map(logo => (
					logo.name === type &&
						<Link key={logo.id} className={`main-logo ${helpClass ? helpClass : ''}`} to="/">
							<img src={logo.logo.url} alt={logo.alternativeText}/>
						</Link>
				))}
			</>
		)}
	/>
)

Logo.propTypes = {
	type: PropTypes.string,
	helpClass: PropTypes.string
}

export default Logo
