import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import MainSlider from "./mainSlider"
import HeaderWaveSvg from "./headereWaveSvg"
import PageBannerVideo from "./pageBannerVideo"
import CurlyLineDot from "./curlyLineDot"
import Dots from "./dots"

const PageBanner = ({ bannerHeading, bannerUrl, location }) => {
	const data = useStaticQuery(graphql`
	  query {
			dnhCms {
				images(where: {title: "Video Bg"}) {
					asset {
						url
					}
				}
			}
		}
	`)
	return(
		<>
			{location === "/" ?
				<div className="page-banner">
					<MainSlider />
					<div className="page-banner__left">
						<div className="dots second white">
							<Dots />
						</div>
						<HeaderWaveSvg />
					</div>
					<div className="page-banner__right" style={{backgroundImage: `url(${data.dnhCms.images[0].asset.url})`}}>
						<PageBannerVideo />
					</div>
					<div className="dots first white">
						<Dots />
					</div>
					<div className="circle-set page-banner-top">
						<div className="circle white stroke-18 size-50"></div>
						<div className="circle white size-20"></div>
						<div className="circle white size-6"></div>
					</div>
					<div className="circle-set page-banner-bottom">
						<div className="circle white stroke-18 size-50 opacity-05"></div>
						<div className="circle white stroke-3 size-16 opacity-05"></div>
						<div className="circle white size-20 opacity-05"></div>
						<div className="circle white size-6 opacity-05"></div>
					</div>
					<div className="circle-line-set">
						<CurlyLineDot/>
						<CurlyLineDot/>
					</div>
					<div className="lines orange"></div>
				</div>
				:
				<div className="page-banner secondary" style={{backgroundImage: `url(${bannerUrl})`}}>
					{bannerHeading && <h1 className="container">{bannerHeading}</h1>}
					<div className="dots white">
						<Dots />
					</div>
					<div className="circle-set">
						<div className="circle white stroke-18 size-50 opacity-05"></div>
						<div className="circle white stroke-3 size-16 opacity-05"></div>
						<div className="circle white size-20 opacity-05"></div>
						<div className="circle white size-6 opacity-05"></div>
					</div>
				</div>
			}
		</>
	)
}

PageBanner.propTypes = {
	bannerHeading: PropTypes.string,
	bannerUrl: PropTypes.string,
	location: PropTypes.string
}

export default PageBanner
