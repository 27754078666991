import React, { Component } from 'react'

class BackToTop extends Component {

	componentDidMount(){
		const trackScroll = () => {
			var scrolled = window.pageYOffset;
			var coords = document.documentElement.clientHeight;

			if (scrolled > coords) {
				goTopBtn.classList.add('back-to-top--show');
			}
			if (scrolled < coords) {
				goTopBtn.classList.remove('back-to-top--show');
			}
		}

		let goTopBtn = document.querySelector('.back-to-top');
		window.addEventListener('scroll', trackScroll);
	}

	render(){
		return(
			<a href="#top" className="back-to-top">
				<i className="fas fa-chevron-up"></i>
			</a>
		);
	}
}

export default BackToTop
