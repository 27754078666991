import React from "react"
import PropTypes from "prop-types"

const TextSection = ({ children, sectionClass, textSize, headingType, heading, copy, textColor, bgColor, overlay, bgImage }) => (
	<div className={`text-section ${sectionClass ? sectionClass : ''} ${textColor ? textColor : ''} ${bgColor ? bgColor : ''} ${overlay ? overlay : ''} ${textSize ? textSize : ''}`} style={bgImage && {backgroundImage: `url(${bgImage})`}}>
		<div className="text-wrap">
			{headingType === "primary" ? <h1>{heading}</h1> : <h2>{heading}</h2>}
			<p>{copy}</p>
		</div>
		{children}
	</div>
)

TextSection.propTypes = {
	children: PropTypes.node,
	sectionClass: PropTypes.string,
	textSize: PropTypes.string,
	textColor: PropTypes.string,
	headingType: PropTypes.string,
	heading: PropTypes.string.isRequired,
	copy: PropTypes.string,
	overlay: PropTypes.string,
	bgColor: PropTypes.string,
	bgImage: PropTypes.string
}

export default TextSection
