import React ,{Component} from 'react'
import PropTypes from "prop-types"

import SocialNav from './socialNav'
import Logo from './logo'
import MainNav from './mainNav'
import HeaderInfo from './headerInfo'
import BurgerIcon from './burgerIcon'
import OpeningsLink from './openingsLink'

class Header extends Component {
	render(){
		const {location, toggleClass, closeNav} = this.props;
		return(
			<header className="header">
				<div id="js-header-top-bar" className="top-bar container">
					<OpeningsLink />
					<SocialNav navLinkColor="orange"/>
				</div>
				<div id="js-header-bottom-bar" className="bottom-bar">
					<div className="bottom-bar-wrap container">
						<BurgerIcon toggleClass={toggleClass} closeNav={closeNav}/>
						<Logo type="Black" />
						<Logo type="Small" helpClass="small-logo"/>
						<MainNav  location={location}/>
						<HeaderInfo />
					</div>
				</div>
			</header>
		);
	}
}

Header.propTypes = {
	toggleClass: PropTypes.func.isRequired,
	closeNav: PropTypes.func.isRequired,
	location: PropTypes.string
}

export default Header
