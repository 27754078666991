import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

const LegalPageList = () => (
	<StaticQuery
		query={graphql`
			query {
				dnhCms {
					pages(where: {legalPage: true, status: PUBLISHED}) {
						id
						pageTitle
					}
				}
			}
		`}
		render={data => (
			<ul className="legal-menu">
				{data.dnhCms.pages.map(page => (
					<li key={page.id}>
						<Link
							to={`/${page.pageTitle.replace(/\s+/g, '-').toLowerCase()}/`}
							activeClassName="active">
							{page.pageTitle}
						</Link>
					</li>
				))}
			</ul>
		)}
	/>
)

export default LegalPageList
