import React from 'react'
// import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';

const isDescendant = (el, target) => {
	if (target !== null) {
		return el === target || isDescendant(el, target.parentNode);
	}
	return false;
};

// https://github.com/mui-org/material-ui/blob/386edcf7c0437b120d259794df29b40ac5e14996/src/utils/ClickAwayListener.js

class ClickOutsideListener extends React.Component {
	static propTypes = {
		children: PropTypes.node,
		onClickOutside: PropTypes.any,
	};

	constructor(props) {
		super(props)
		this.handleClickOutside = this.handleClickOutside.bind(this)
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside)
		document.addEventListener('touchstart', this.handleClickOutside)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside)
		document.removeEventListener('touchstart', this.handleClickOutside)
	}

	handleClickOutside(event) {
		// Ignore events that have been `event.preventDefault()` marked.
		if (event.defaultPrevented) {
			return;
		}

		const el = this.node;

		if (
			event.target instanceof HTMLElement &&
			document.documentElement &&
			document.documentElement.contains(event.target) &&
			!isDescendant(el, event.target)
		) {
			this.props.onClickOutside(event);
		}
	}
	render() {
		return this.props.children
	}
}

export default ClickOutsideListener
