import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

const SocialNav = ({ navLinkColor }) => (
	<StaticQuery
		query={graphql`
			query {
				dnhCms {
					socialMedias(where: {status: PUBLISHED}) {
						id
						title
						icon
						url
					}
				}
			}
		`}
		render={data => (
			<ul className="social-nav">
				{data.dnhCms.socialMedias.map(media => (
					<li key={media.id} className="social-nav__item">
						<a href={media.url}
							className={`social-nav__link social-nav__link--${ navLinkColor }`}
							target="_blank"
							rel="noopener noreferrer">
							<i className={media.icon}></i>
							{media.title}
						</a>
					</li>
				))}
			</ul>
		)}
	/>
)

SocialNav.propTypes = {
	navLinkColor: PropTypes.string
}

export default SocialNav
