import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const InfoCard = () => {
	const data = useStaticQuery(graphql`
		query {
			dnhCms {
				contactses(where: {status: PUBLISHED}) {
					id
					title
					icon {
						fileName
						url
					}
					contactInfo
				}
			}
		}
	`)

	const contactInfoSwitch = (title, contact) =>{
		switch(title){
			case 'Address':
				return(
					<span>
						<a href={`https://www.google.com/maps/place/${contact}`} target="_blank" rel="noopener noreferrer">{contact}</a>
					</span>
				)
			case 'Email':
				return(
					<span>
						<a href={`mailto:${contact}`}>{contact}</a>
					</span>
				)
			case 'Work time':
				return(
					<span>
						{contact}
					</span>
				)
			case 'Phone':
				return(
					<span>
						{contact.split(',').map((phone, index) => (
							<a key={index} href={`tel:${phone}`}>{phone}</a>
						))}
					</span>
				)
			default:
				return(
					<span>
						{contact}
					</span>
				)
		}
	}

	return (
		<ul>
			{data.dnhCms.contactses.map(info => (
				<li key={info.id}>
					<img src={info.icon.url} alt={info.icon.fileName}/>
					{contactInfoSwitch(info.title, info.contactInfo)}
				</li>
			))}
		</ul>
	)
}

export default InfoCard
