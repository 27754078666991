import React, { Component } from 'react'
import PropTypes from "prop-types"
// import ClickOutsideListener from "./ClickOutsideListener"


class BurgerIcon extends Component {
	render(){
		return(
			// <ClickOutsideListener onClickOutside={this.props.closeNav}>
				<div id="burgerIcon" onClick={this.props.toggleClass}>
					<span></span>
					<span></span>
					<span></span>
				</div>
			// </ClickOutsideListener>
		);
	}
}

BurgerIcon.propTypes = {
	toggleClass: PropTypes.func
}

export default BurgerIcon
