/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from 'react'
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import BackToTop from "./backToTop"
import PageBanner from "./pageBanner"
import CookieBanner from "./cookieBanner"
import "../css/main.scss"

class Layout extends Component {
	constructor(props) {
		super(props);

		this.pageWrapperRef = React.createRef();
		this.headerRef = React.createRef();
		this.toggleClass = this.toggleClass.bind(this);
		this.closeNav = this.closeNav.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
	}
	componentDidMount(){
		window.addEventListener('scroll', this.handleScroll);
	}
	componentWillUnmount(){
		window.removeEventListener('scroll', this.handleScroll);
	}
	handleScroll(){
		let stickyTopEl = document.getElementById("js-header-top-bar");
		let stickyBotoomEl = document.getElementById("js-header-bottom-bar");
		if(window.innerWidth >= 768){
			if(window.pageYOffset > stickyTopEl.offsetHeight){
				stickyBotoomEl.classList.add("fixed");
			}else{
				stickyBotoomEl.classList.remove("fixed");
			}
		}
	}
	toggleClass(){
		if(this.pageWrapperRef.current.classList.contains("mobile-nav")){
			this.pageWrapperRef.current.classList.remove("mobile-nav")
		}else {
			this.pageWrapperRef.current.classList.add("mobile-nav")
		}
	}

	closeNav(){
		this.pageWrapperRef.current.classList.remove("mobile-nav")
	}

	render(){
		let { children, bannerHeading, bannerUrl, location, data } = this.props;
		return (
			<div className="page-wrap" ref={this.pageWrapperRef}>
				<Header
					ref={this.headerRef}
					siteTitle={data.site.siteMetadata.title}
					location={location}
					toggleClass={this.toggleClass}
					closeNav={this.closeNav}/>
				<PageBanner location={location} bannerHeading={bannerHeading} bannerUrl={bannerUrl} />
				<main>{children}</main>
				{/* <ContactForm /> */}
				<Footer />
				<BackToTop />
				<CookieBanner />
			</div>
		)
	}
}

Layout.propTypes = {
	bannerUrl: PropTypes.string,
	bannerHeading: PropTypes.string,
	children: PropTypes.node.isRequired,
	data: PropTypes.object.isRequired,
	location: PropTypes.string
}

const LayoutContainer = (props) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	return (
		<Layout data={data} {...props}/>
	)
}

export default LayoutContainer
