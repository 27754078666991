import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"
import SocialNav from './socialNav'

class MainNav extends Component {
	// pages(where: {showOnMainMenu: true, status: PUBLISHED}) {
	// subPages(where: {status: PUBLISHED}) {

	constructor(props) {
		super(props);

		this.intViewportWidth = typeof window !== `undefined` ?  window.innerWidth : null
		this.subNavToggle = this.subNavToggle.bind(this);
	}

	subNavToggle = (event) => {
		let currentItem = event.target;

		if(this.intViewportWidth < 768){
			currentItem.classList.toggle('show');
		}
	}

	componentDidMount(){
		if(this.intViewportWidth < 768){
			let subNavs = [...document.getElementsByClassName("navArrow active")];

			if(subNavs.length !== 0) {
				subNavs[0].classList.add("show");
			}
		}
	}

	render(){
		let { location, data } = this.props;
		return(
			<nav>
				<SocialNav navLinkColor="orange"/>
				<ul className="main-nav">
					{data.dnhCms.pages.map(page => (
						<li key={page.id} className="main-nav__item">
							{page.subPages.length > 0 ?
								<>
									<span	className={`navArrow ${location.split(/[/]/)[1] === page.pageTitle.replace(/\s+/g, '-').toLowerCase() ? 'active': ''}`}
											onClick={this.subNavToggle}>
										{page.pageTitle}
										<i className="fas fa-chevron-up arrow-up"></i>
										<i className="fas fa-chevron-down arrow-down"></i>
									</span>
									<ul className={`sub-nav ${page.subPages.length <= 3 ? 'small': ''}`}>
										{page.subPages.map(subPage => (
											<li key={subPage.id}
												className="main-nav__item--sub">
												<Link
													to={`/${page.pageTitle.replace(/\s+/g, '-').toLowerCase()}/${subPage.pageTitle.replace(/\s+/g, '-').toLowerCase()}/`}
													key={subPage.id}
													activeClassName="active">
													{subPage.icon !== null &&
														<img src={subPage.icon.url} alt={subPage.icon.fileName} />
													}
													{subPage.pageTitle}
												</Link>
											</li>
										))}
									</ul>
								</>
								:
								<Link to={`/${page.pageTitle.replace(/\s+/g, '-').toLowerCase()}/`} activeClassName="active">
									{page.pageTitle}
								</Link>
							}
						</li>
					))}
				</ul>
				<div className="circle-set">
					<div className="circle blue stroke-18 size-50 opacity-05"></div>
					<div className="circle blue stroke-3 size-16 opacity-05"></div>
					<div className="circle blue size-20 opacity-05"></div>
					<div className="circle blue size-6 opacity-05"></div>
				</div>
			</nav>
		)
	}
}

MainNav.propTypes = {
	data: PropTypes.object.isRequired,
	location: PropTypes.string
}

const MainNavContainer = (props) => {
	const data = useStaticQuery(graphql`
		query {
			dnhCms {
				pages(where: {showOnMainMenu: true}) {
					id
					pageTitle
					subPages{
						id
						pageTitle
						icon{
							url
							fileName
						}
					}
				}
			}
		}
	`)

	return (
		<MainNav data={data} {...props}/>
	)
}

export default MainNavContainer
