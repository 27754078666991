import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

const OpeningsLink = () => (
	<StaticQuery
		query={graphql`
			query {
				dnhCms {
					openingses(where: {status: PUBLISHED}) {
						id
					}
					openingsLinks{
						text
					}
				}
			}
		`}
		render={data => (
			<Link className="top-bar__hiring" to={`/company/careers#${data.dnhCms.openingses.length === 0 ? 'openings-contact' : 'openings'}`}>
				{data.dnhCms.openingsLinks[0].text}
			</Link>
		)}
	/>
)
export default OpeningsLink
