import React from 'react'
import { Link, StaticQuery, graphql } from "gatsby"

import Logo from "./logo"
import SocialNav from "./socialNav"
import LegalPageList from "./legalPageList"
import InfoCard from "./infoCard"

const Footer = () => (
	<StaticQuery
		query={graphql`
			query {
				dnhCms {
					pages(where: {showOnFooter: true, status: PUBLISHED}) {
						id
						pageTitle
						subPages {
							id
							pageTitle
						}
					}
					contactses(where: {status: PUBLISHED}) {
						id
						icon {
							id
							url
							fileName
						}
						contactInfo
					}
					images(where: {title: "IDIT"}) {
						asset {
							url
						}
					}
				}
			}
		`}
		render={data => (
			<footer>
				<div className="top-bar container">
					<div className="logo-wrap">
						<Logo type="White" />
						<p>
							Our vision is that the software industry has the power to build a better future.
							We are socially responsible company following the best industry performance standards.
						</p>
						<SocialNav navLinkColor="white" />
						<LegalPageList />
					</div>
					{data.dnhCms.pages.map(page => (
						<div key={page.id} className="menu-wrap">
							<h3>
								{page.pageTitle}
							</h3>
							{page.subPages.length > 0 &&
								<ul>
									{page.subPages.map(subPage => (
										<li key={subPage.id}>
											<Link
												to={`/${page.pageTitle.replace(/\s+/g, '-').toLowerCase()}/${subPage.pageTitle.replace(/\s+/g, '-').toLowerCase()}/`}
												activeClassName="active">
												{subPage.pageTitle}
											</Link>
										</li>
									))}
								</ul>
							}
						</div>
					))}
					<address className="contact-wrap">
						<h3>Contacts</h3>
						<InfoCard />
					</address>
				</div>
				<div className="bottom-bar">
					<div className="left-side">
						<span>
							{new Date().getFullYear()} © Copyrights DNH Soft
						</span>
					</div>
					<div className="right-side">
						<span>Design by</span>
						<a href="https://www.idit.bg/" target="_blank" alt="" rel="noopener noreferrer">
							idit.bg
							<img className="idit-logo" src={data.dnhCms.images[0].asset.url} alt="Idit logo" />
						</a>
					</div>
				</div>
			</footer>
		)}
	/>
)

export default Footer
