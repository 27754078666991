import React from "react"

const HeaderWaveSvg = () => (
	<svg className="page-banner__wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 305 746">
		<defs>
			<clipPath clipPathUnits="userSpaceOnUse" id="cp1">
				<path d="M0.34 -0.07L69.44 0C6.72 119.71 -29.99 242.47 95.64 384.26L232.35 540.33C310.93 648.54 311.78 697.6 297.39 746L0.34 746.17L0.34 -0.07Z" />
			</clipPath>
			<linearGradient id="grd1" gradientUnits="userSpaceOnUse" x1="113.16" y1="494.178" x2="39.301" y2="587.712">
				<stop offset="0" stopColor="#121d50" />
				<stop offset="1" stopColor="#040610" />
			</linearGradient>
		</defs>
		<path className="a" id="path4534" fillRule="evenodd" d="M0.34 -0.07L69.44 0C3.04 125.19 -37.08 255.03 128.58 421.34C340.56 643.47 305.04 703.1 297.39 746L0.34 746.17L0.34 -0.07Z" />
		<g className="b" id="Clip-Path: Group_1887" clipPath="url(#cp1)">
			<g id="Group_1887">
				<path id="Path_246" d="M32.45 257.16C32.45 257.16 -29.74 323.64 32.45 407.28C167.74 556.82 265.6 612.45 283.56 746.87C371.09 746.91 249.49 696.84 344.22 695.35C438.95 693.86 314.16 634.64 314.16 634.64L325.58 718.79L329.19 668.3C329.19 668.3 335.55 611.6 299.73 591.96C263.92 572.31 273.29 548.67 273.29 548.67C273.29 548.67 250.83 526.52 245.63 513.81C240.43 501.1 243.42 499.57 218.58 474.74C193.75 449.9 188.53 436.26 188.53 436.26C188.53 436.26 176.44 390.58 145.25 390.58C114.06 390.58 110.99 326.52 96.55 332.87C82.11 339.22 32.45 257.16 32.45 257.16L32.45 257.16Z" />
			</g>
		</g>
	</svg>
)

export default HeaderWaveSvg
