import React from "react"

const Dots = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219 130" width="219" height="130">
	<g >
		<path  className="shp0" d="M104.36 3.38C103.65 2.68 103.65 1.54 104.36 0.83C105.06 0.13 106.2 0.13 106.9 0.83C107.61 1.53 107.61 2.67 106.9 3.38C106.2 4.08 105.06 4.08 104.36 3.38Z" />
		<path  className="shp0" d="M104.44 14.76C103.73 14.06 103.73 12.92 104.44 12.22C105.14 11.51 106.28 11.51 106.98 12.22C107.69 12.92 107.69 14.06 106.98 14.76C106.28 15.47 105.14 15.47 104.44 14.76Z" />
		<path  className="shp0" d="M105.72 26.64C105.49 26.64 105.26 26.6 105.04 26.51C104.83 26.42 104.63 26.29 104.47 26.13C104.3 25.97 104.17 25.77 104.08 25.55C103.99 25.34 103.95 25.11 103.95 24.88C103.95 24.64 103.99 24.41 104.08 24.2C104.17 23.98 104.3 23.78 104.46 23.62C104.63 23.45 104.82 23.32 105.04 23.24C105.25 23.15 105.49 23.1 105.72 23.11C105.95 23.1 106.18 23.15 106.4 23.24C106.61 23.32 106.81 23.45 106.97 23.62C107.14 23.78 107.27 23.98 107.36 24.19C107.45 24.41 107.49 24.64 107.49 24.87C107.49 25.1 107.45 25.34 107.36 25.55C107.27 25.77 107.14 25.96 106.98 26.13C106.81 26.29 106.62 26.42 106.4 26.51C106.19 26.6 105.95 26.64 105.72 26.64Z" />
		<path  className="shp0" d="M105.66 38.03C105.43 38.03 105.2 37.98 104.98 37.9C104.77 37.81 104.57 37.68 104.41 37.51C104.24 37.35 104.11 37.15 104.02 36.94C103.93 36.72 103.89 36.49 103.89 36.26C103.89 36.03 103.93 35.8 104.02 35.58C104.11 35.36 104.24 35.17 104.4 35C104.57 34.84 104.76 34.71 104.98 34.62C105.19 34.53 105.42 34.49 105.66 34.49C105.89 34.49 106.12 34.53 106.34 34.62C106.55 34.71 106.75 34.84 106.91 35C107.08 35.17 107.21 35.36 107.3 35.58C107.38 35.79 107.43 36.02 107.43 36.26C107.43 36.49 107.39 36.72 107.3 36.94C107.21 37.15 107.08 37.35 106.92 37.51C106.75 37.68 106.56 37.81 106.34 37.9C106.12 37.98 105.89 38.03 105.66 38.03Z" />
		<path  className="shp0" d="M105.74 49.41C105.51 49.41 105.28 49.37 105.06 49.28C104.85 49.19 104.65 49.06 104.49 48.9C104.32 48.73 104.19 48.54 104.1 48.32C104.01 48.11 103.97 47.88 103.97 47.64C103.97 47.41 104.01 47.18 104.1 46.96C104.19 46.75 104.32 46.55 104.48 46.39C104.65 46.22 104.84 46.09 105.06 46.01C105.27 45.92 105.51 45.87 105.74 45.87C105.97 45.87 106.2 45.92 106.42 46C106.63 46.09 106.83 46.22 106.99 46.39C107.16 46.55 107.29 46.75 107.38 46.96C107.46 47.18 107.51 47.41 107.51 47.64C107.51 47.87 107.47 48.1 107.38 48.32C107.29 48.54 107.16 48.73 107 48.9C106.83 49.06 106.64 49.19 106.42 49.28C106.2 49.37 105.97 49.41 105.74 49.41Z" />
		<path  className="shp0" d="M105.75 60.72C105.52 60.73 105.29 60.68 105.07 60.59C104.86 60.51 104.66 60.38 104.5 60.21C104.33 60.05 104.2 59.85 104.11 59.64C104.02 59.42 103.98 59.19 103.98 58.96C103.98 58.72 104.02 58.49 104.11 58.28C104.2 58.06 104.33 57.87 104.49 57.7C104.66 57.54 104.85 57.41 105.07 57.32C105.28 57.23 105.51 57.19 105.75 57.19C105.98 57.19 106.21 57.23 106.43 57.32C106.64 57.41 106.84 57.54 107 57.7C107.17 57.86 107.3 58.06 107.39 58.28C107.47 58.49 107.52 58.72 107.52 58.95C107.52 59.19 107.48 59.42 107.39 59.63C107.3 59.85 107.17 60.05 107.01 60.21C106.84 60.37 106.65 60.51 106.43 60.59C106.21 60.68 105.98 60.73 105.75 60.72Z" />
		<path  className="shp0" d="M104.49 71.61C103.78 70.91 103.78 69.77 104.48 69.07C105.19 68.36 106.33 68.36 107.03 69.07C107.73 69.77 107.74 70.91 107.03 71.61C106.33 72.32 105.19 72.32 104.49 71.61Z" />
		<path  className="shp0" d="M104.5 83.07C103.79 82.37 103.79 81.23 104.49 80.52C105.2 79.82 106.34 79.82 107.04 80.52C107.74 81.22 107.75 82.36 107.04 83.07C106.34 83.77 105.2 83.77 104.5 83.07Z" />
		<path  className="shp0" d="M105.78 94.95C105.55 94.95 105.32 94.91 105.1 94.82C104.89 94.73 104.69 94.6 104.52 94.44C104.36 94.27 104.23 94.08 104.14 93.86C104.05 93.65 104.01 93.41 104.01 93.18C104.01 92.95 104.05 92.72 104.14 92.5C104.23 92.29 104.36 92.09 104.52 91.93C104.69 91.76 104.88 91.63 105.1 91.54C105.31 91.45 105.54 91.41 105.78 91.41C106.01 91.41 106.24 91.45 106.46 91.54C106.67 91.63 106.87 91.76 107.03 91.92C107.2 92.09 107.33 92.28 107.42 92.5C107.5 92.71 107.55 92.95 107.55 93.18C107.55 93.41 107.5 93.64 107.42 93.86C107.33 94.07 107.2 94.27 107.03 94.43C106.87 94.6 106.67 94.73 106.46 94.82C106.24 94.91 106.01 94.95 105.78 94.95Z" />
		<path  className="shp0" d="M105.79 106.26C105.56 106.26 105.33 106.22 105.11 106.13C104.89 106.04 104.7 105.91 104.53 105.75C104.37 105.59 104.24 105.39 104.15 105.17C104.06 104.96 104.02 104.73 104.02 104.5C104.02 104.26 104.06 104.03 104.15 103.82C104.24 103.6 104.37 103.4 104.53 103.24C104.7 103.08 104.89 102.94 105.11 102.86C105.32 102.77 105.55 102.72 105.79 102.73C106.02 102.72 106.25 102.77 106.47 102.86C106.68 102.94 106.88 103.07 107.04 103.24C107.21 103.4 107.34 103.6 107.43 103.81C107.51 104.03 107.56 104.26 107.56 104.49C107.56 104.72 107.51 104.96 107.43 105.17C107.34 105.39 107.21 105.58 107.04 105.75C106.88 105.91 106.68 106.04 106.47 106.13C106.25 106.22 106.02 106.26 105.79 106.26Z" />
		<path  className="shp0" d="M105.8 117.72C105.57 117.72 105.34 117.68 105.12 117.59C104.9 117.5 104.71 117.37 104.54 117.2C104.38 117.04 104.25 116.84 104.16 116.63C104.07 116.41 104.03 116.18 104.03 115.95C104.03 115.72 104.07 115.49 104.16 115.27C104.25 115.06 104.38 114.86 104.54 114.69C104.71 114.53 104.9 114.4 105.12 114.31C105.33 114.22 105.56 114.18 105.8 114.18C106.03 114.18 106.26 114.22 106.48 114.31C106.69 114.4 106.89 114.53 107.05 114.69C107.22 114.86 107.35 115.05 107.43 115.27C107.52 115.48 107.57 115.71 107.57 115.95C107.54 116.17 107.48 116.39 107.39 116.6C107.29 116.8 107.16 116.99 107 117.15C106.84 117.31 106.65 117.44 106.45 117.54C106.24 117.63 106.02 117.7 105.8 117.72Z" />
		<path  className="shp0" d="M104.54 128.47C103.83 127.76 103.83 126.62 104.53 125.92C105.24 125.22 106.37 125.21 107.08 125.92C107.78 126.62 107.78 127.76 107.08 128.46C106.38 129.17 105.24 129.17 104.54 128.47Z" />
		<path  className="shp0" d="M96.44 3.39C95.73 2.68 95.73 1.54 96.44 0.84C97.14 0.14 98.28 0.13 98.98 0.84C99.69 1.54 99.69 2.68 98.98 3.38C98.28 4.09 97.14 4.09 96.44 3.39Z" />
		<path  className="shp0" d="M97.72 15.26C97.49 15.27 97.26 15.22 97.04 15.13C96.83 15.05 96.63 14.92 96.47 14.75C96.3 14.59 96.17 14.39 96.08 14.18C95.99 13.96 95.95 13.73 95.95 13.5C95.95 13.26 95.99 13.03 96.08 12.82C96.17 12.6 96.3 12.41 96.46 12.24C96.63 12.08 96.82 11.95 97.04 11.86C97.25 11.77 97.49 11.73 97.72 11.73C97.95 11.73 98.18 11.77 98.4 11.86C98.61 11.95 98.81 12.08 98.97 12.24C99.14 12.4 99.27 12.6 99.36 12.82C99.45 13.03 99.49 13.26 99.49 13.49C99.49 13.73 99.45 13.96 99.36 14.17C99.27 14.39 99.14 14.59 98.98 14.75C98.81 14.91 98.62 15.04 98.4 15.13C98.19 15.22 97.95 15.27 97.72 15.26Z" />
		<path  className="shp0" d="M96.46 26.15C95.75 25.45 95.75 24.31 96.46 23.61C97.16 22.9 98.3 22.9 99 23.61C99.71 24.31 99.71 25.45 99 26.15C98.3 26.86 97.16 26.86 96.46 26.15Z" />
		<path  className="shp0" d="M97.74 38.03C97.51 38.03 97.28 37.99 97.06 37.9C96.85 37.82 96.65 37.69 96.49 37.52C96.32 37.36 96.19 37.16 96.1 36.95C96.01 36.73 95.97 36.5 95.97 36.27C95.97 36.03 96.01 35.8 96.1 35.59C96.19 35.37 96.32 35.18 96.48 35.01C96.65 34.85 96.84 34.72 97.06 34.63C97.27 34.54 97.51 34.49 97.74 34.5C97.97 34.49 98.2 34.54 98.42 34.63C98.63 34.71 98.83 34.84 98.99 35.01C99.16 35.17 99.29 35.37 99.38 35.58C99.46 35.8 99.51 36.03 99.51 36.26C99.51 36.5 99.47 36.73 99.38 36.94C99.29 37.16 99.16 37.35 99 37.52C98.83 37.68 98.64 37.81 98.42 37.9C98.2 37.99 97.97 38.03 97.74 38.03Z" />
		<path  className="shp0" d="M97.82 49.42C97.59 49.42 97.36 49.38 97.14 49.29C96.93 49.2 96.73 49.07 96.57 48.91C96.4 48.74 96.27 48.55 96.18 48.33C96.09 48.11 96.05 47.88 96.05 47.65C96.05 47.42 96.09 47.19 96.18 46.97C96.27 46.76 96.4 46.56 96.56 46.4C96.73 46.23 96.92 46.1 97.14 46.01C97.35 45.92 97.59 45.88 97.82 45.88C98.05 45.88 98.28 45.92 98.5 46.01C98.71 46.1 98.91 46.23 99.07 46.39C99.24 46.56 99.37 46.75 99.46 46.97C99.55 47.18 99.59 47.41 99.59 47.65C99.59 47.88 99.55 48.11 99.46 48.33C99.37 48.54 99.24 48.74 99.08 48.9C98.91 49.07 98.72 49.2 98.5 49.29C98.28 49.37 98.05 49.42 97.82 49.42Z" />
		<path  className="shp0" d="M97.76 60.8C97.53 60.8 97.3 60.76 97.08 60.67C96.87 60.58 96.67 60.45 96.5 60.29C96.34 60.13 96.21 59.93 96.12 59.71C96.03 59.5 95.99 59.27 95.99 59.04C95.99 58.8 96.03 58.57 96.12 58.36C96.21 58.14 96.34 57.94 96.5 57.78C96.67 57.61 96.86 57.48 97.08 57.4C97.29 57.31 97.52 57.26 97.76 57.27C97.99 57.26 98.22 57.31 98.44 57.4C98.65 57.48 98.85 57.61 99.01 57.78C99.18 57.94 99.31 58.14 99.4 58.35C99.48 58.57 99.53 58.8 99.53 59.03C99.53 59.26 99.49 59.5 99.4 59.71C99.31 59.93 99.18 60.12 99.02 60.29C98.85 60.45 98.65 60.58 98.44 60.67C98.22 60.76 97.99 60.8 97.76 60.8Z" />
		<path  className="shp0" d="M97.77 72.12C97.54 72.12 97.31 72.07 97.09 71.99C96.88 71.9 96.68 71.77 96.51 71.6C96.35 71.44 96.22 71.24 96.13 71.03C96.04 70.81 96 70.58 96 70.35C96 70.12 96.04 69.89 96.13 69.67C96.22 69.45 96.35 69.26 96.51 69.09C96.68 68.93 96.87 68.8 97.09 68.71C97.3 68.62 97.53 68.58 97.77 68.58C98 68.58 98.23 68.62 98.45 68.71C98.66 68.8 98.86 68.93 99.02 69.09C99.19 69.26 99.32 69.45 99.41 69.67C99.49 69.88 99.54 70.11 99.54 70.35C99.54 70.58 99.49 70.81 99.41 71.03C99.32 71.24 99.19 71.44 99.02 71.6C98.86 71.77 98.66 71.9 98.45 71.98C98.23 72.07 98 72.12 97.77 72.12Z" />
		<path  className="shp0" d="M97.78 83.57C97.55 83.57 97.32 83.53 97.1 83.44C96.88 83.35 96.69 83.22 96.52 83.06C96.36 82.89 96.23 82.7 96.14 82.48C96.05 82.27 96.01 82.04 96.01 81.8C96.01 81.57 96.05 81.34 96.14 81.12C96.23 80.91 96.36 80.71 96.52 80.55C96.69 80.38 96.88 80.25 97.1 80.17C97.31 80.08 97.54 80.03 97.78 80.03C98.01 80.03 98.24 80.08 98.46 80.16C98.67 80.25 98.87 80.38 99.03 80.55C99.2 80.71 99.33 80.91 99.42 81.12C99.5 81.34 99.55 81.57 99.55 81.8C99.55 82.03 99.5 82.26 99.42 82.48C99.33 82.7 99.2 82.89 99.03 83.06C98.87 83.22 98.67 83.35 98.46 83.44C98.24 83.53 98.01 83.57 97.78 83.57Z" />
		<path  className="shp0" d="M97.79 94.88C97.56 94.89 97.33 94.84 97.11 94.75C96.89 94.67 96.7 94.54 96.53 94.37C96.37 94.21 96.24 94.01 96.15 93.8C96.06 93.58 96.02 93.35 96.02 93.12C96.02 92.88 96.06 92.65 96.15 92.44C96.24 92.22 96.37 92.03 96.53 91.86C96.7 91.7 96.89 91.57 97.11 91.48C97.32 91.39 97.55 91.35 97.79 91.35C98.02 91.35 98.25 91.39 98.47 91.48C98.68 91.57 98.88 91.7 99.04 91.86C99.21 92.02 99.34 92.22 99.43 92.44C99.51 92.65 99.56 92.88 99.56 93.11C99.56 93.35 99.51 93.58 99.43 93.79C99.34 94.01 99.21 94.21 99.04 94.37C98.88 94.53 98.68 94.67 98.47 94.75C98.25 94.84 98.02 94.89 97.79 94.88Z" />
		<path  className="shp0" d="M97.8 106.34C97.57 106.34 97.34 106.3 97.12 106.21C96.9 106.12 96.71 105.99 96.54 105.83C96.38 105.66 96.25 105.47 96.16 105.25C96.07 105.04 96.03 104.81 96.03 104.57C96.03 104.34 96.07 104.11 96.16 103.89C96.25 103.68 96.38 103.48 96.54 103.32C96.71 103.15 96.9 103.02 97.12 102.93C97.33 102.85 97.56 102.8 97.8 102.8C98.03 102.8 98.26 102.85 98.48 102.93C98.69 103.02 98.89 103.15 99.05 103.32C99.22 103.48 99.35 103.68 99.43 103.89C99.52 104.11 99.57 104.34 99.57 104.57C99.57 104.8 99.52 105.03 99.44 105.25C99.35 105.46 99.22 105.66 99.05 105.83C98.89 105.99 98.69 106.12 98.48 106.21C98.26 106.3 98.03 106.34 97.8 106.34Z" />
		<path  className="shp0" d="M97.81 117.65C97.58 117.66 97.35 117.61 97.13 117.52C96.91 117.44 96.72 117.31 96.55 117.14C96.39 116.98 96.26 116.78 96.17 116.57C96.08 116.35 96.04 116.12 96.04 115.89C96.04 115.65 96.08 115.42 96.17 115.21C96.26 114.99 96.39 114.8 96.55 114.63C96.72 114.47 96.91 114.34 97.13 114.25C97.34 114.16 97.57 114.12 97.81 114.12C98.04 114.11 98.27 114.16 98.49 114.25C98.7 114.33 98.9 114.46 99.06 114.63C99.23 114.79 99.36 114.99 99.44 115.2C99.53 115.42 99.58 115.65 99.58 115.88C99.58 116.12 99.53 116.35 99.45 116.56C99.36 116.78 99.23 116.97 99.06 117.14C98.9 117.3 98.7 117.43 98.49 117.52C98.27 117.61 98.04 117.65 97.81 117.65Z" />
		<path  className="shp0" d="M97.89 129.04C97.66 129.04 97.43 129 97.21 128.91C96.99 128.82 96.8 128.69 96.63 128.53C96.47 128.36 96.34 128.17 96.25 127.95C96.16 127.73 96.12 127.5 96.12 127.27C96.12 127.04 96.16 126.81 96.25 126.59C96.34 126.38 96.47 126.18 96.63 126.02C96.8 125.85 96.99 125.72 97.21 125.63C97.42 125.54 97.65 125.5 97.89 125.5C98.12 125.5 98.35 125.54 98.57 125.63C98.78 125.72 98.98 125.85 99.14 126.01C99.31 126.18 99.44 126.37 99.53 126.59C99.61 126.8 99.66 127.04 99.66 127.27C99.66 127.5 99.61 127.73 99.53 127.95C99.44 128.16 99.31 128.36 99.14 128.52C98.98 128.69 98.78 128.82 98.57 128.91C98.35 128.99 98.12 129.04 97.89 129.04L97.89 129.04Z" />
		<path  className="shp0" d="M89.72 3.89C89.49 3.89 89.26 3.84 89.04 3.76C88.83 3.67 88.63 3.54 88.47 3.37C88.3 3.21 88.17 3.01 88.08 2.8C87.99 2.58 87.95 2.35 87.95 2.12C87.95 1.89 87.99 1.66 88.08 1.44C88.17 1.23 88.3 1.03 88.46 0.86C88.63 0.7 88.82 0.57 89.04 0.48C89.25 0.39 89.49 0.35 89.72 0.35C89.95 0.35 90.18 0.39 90.4 0.48C90.61 0.57 90.81 0.7 90.97 0.86C91.14 1.03 91.27 1.22 91.36 1.44C91.45 1.65 91.49 1.88 91.49 2.12C91.49 2.35 91.45 2.58 91.36 2.8C91.27 3.01 91.14 3.21 90.98 3.37C90.81 3.54 90.62 3.67 90.4 3.76C90.19 3.84 89.95 3.89 89.72 3.89Z" />
		<path  className="shp0" d="M89.8 15.27C89.57 15.27 89.34 15.23 89.12 15.14C88.91 15.05 88.71 14.92 88.55 14.76C88.38 14.59 88.25 14.4 88.16 14.18C88.07 13.97 88.03 13.74 88.03 13.5C88.03 13.27 88.07 13.04 88.16 12.82C88.25 12.61 88.38 12.41 88.54 12.25C88.71 12.08 88.9 11.95 89.12 11.87C89.34 11.78 89.57 11.73 89.8 11.73C90.03 11.73 90.26 11.78 90.48 11.86C90.69 11.95 90.89 12.08 91.05 12.25C91.22 12.41 91.35 12.61 91.44 12.82C91.53 13.04 91.57 13.27 91.57 13.5C91.57 13.73 91.53 13.96 91.44 14.18C91.35 14.4 91.22 14.59 91.06 14.76C90.89 14.92 90.7 15.05 90.48 15.14C90.27 15.23 90.03 15.27 89.8 15.27Z" />
		<path  className="shp0" d="M89.74 26.65C89.51 26.66 89.28 26.61 89.06 26.53C88.85 26.44 88.65 26.31 88.49 26.14C88.32 25.98 88.19 25.78 88.1 25.57C88.01 25.35 87.97 25.12 87.97 24.89C87.97 24.66 88.01 24.43 88.1 24.21C88.19 23.99 88.32 23.8 88.48 23.63C88.65 23.47 88.84 23.34 89.06 23.25C89.27 23.16 89.51 23.12 89.74 23.12C89.97 23.12 90.2 23.16 90.42 23.25C90.63 23.34 90.83 23.47 90.99 23.63C91.16 23.8 91.29 23.99 91.38 24.21C91.46 24.42 91.51 24.65 91.51 24.89C91.51 25.12 91.47 25.35 91.38 25.57C91.29 25.78 91.16 25.98 91 26.14C90.83 26.31 90.64 26.44 90.42 26.52C90.2 26.61 89.97 26.66 89.74 26.65Z" />
		<path  className="shp0" d="M88.48 37.55C87.77 36.84 87.77 35.7 88.48 35C89.18 34.3 90.32 34.29 91.02 35C91.73 35.7 91.73 36.84 91.02 37.54C90.32 38.25 89.18 38.25 88.48 37.55Z" />
		<path  className="shp0" d="M88.56 48.93C87.85 48.23 87.85 47.09 88.56 46.38C89.26 45.68 90.4 45.68 91.1 46.38C91.81 47.09 91.81 48.22 91.1 48.93C90.4 49.63 89.26 49.63 88.56 48.93Z" />
		<path  className="shp0" d="M89.84 60.81C89.61 60.81 89.38 60.77 89.16 60.68C88.95 60.59 88.75 60.46 88.59 60.3C88.42 60.13 88.29 59.94 88.2 59.72C88.11 59.51 88.07 59.27 88.07 59.04C88.07 58.81 88.11 58.58 88.2 58.36C88.29 58.15 88.42 57.95 88.58 57.79C88.75 57.62 88.94 57.49 89.16 57.4C89.37 57.31 89.6 57.27 89.84 57.27C90.07 57.27 90.3 57.31 90.52 57.4C90.73 57.49 90.93 57.62 91.09 57.78C91.26 57.95 91.39 58.14 91.48 58.36C91.56 58.58 91.61 58.81 91.61 59.04C91.61 59.27 91.57 59.5 91.48 59.72C91.39 59.93 91.26 60.13 91.1 60.29C90.93 60.46 90.74 60.59 90.52 60.68C90.3 60.77 90.07 60.81 89.84 60.81Z" />
		<path  className="shp0" d="M89.78 72.19C89.55 72.2 89.32 72.15 89.1 72.06C88.88 71.98 88.69 71.85 88.52 71.68C88.36 71.52 88.23 71.32 88.14 71.11C88.05 70.89 88.01 70.66 88.01 70.43C88.01 70.19 88.05 69.96 88.14 69.75C88.23 69.53 88.36 69.34 88.52 69.17C88.69 69.01 88.88 68.88 89.1 68.79C89.31 68.7 89.54 68.66 89.78 68.66C90.01 68.65 90.24 68.7 90.46 68.79C90.67 68.87 90.87 69 91.03 69.17C91.2 69.33 91.33 69.53 91.42 69.74C91.5 69.96 91.55 70.19 91.55 70.42C91.55 70.66 91.5 70.89 91.42 71.1C91.33 71.32 91.2 71.51 91.03 71.68C90.87 71.84 90.67 71.97 90.46 72.06C90.24 72.15 90.01 72.19 89.78 72.19Z" />
		<path  className="shp0" d="M89.86 83.58C89.63 83.58 89.4 83.54 89.18 83.45C88.97 83.36 88.77 83.23 88.6 83.07C88.44 82.9 88.31 82.71 88.22 82.49C88.13 82.27 88.09 82.04 88.09 81.81C88.09 81.58 88.13 81.35 88.22 81.13C88.31 80.92 88.44 80.72 88.6 80.56C88.77 80.39 88.96 80.26 89.18 80.17C89.39 80.08 89.62 80.04 89.86 80.04C90.09 80.04 90.32 80.08 90.54 80.17C90.75 80.26 90.95 80.39 91.11 80.55C91.28 80.72 91.41 80.91 91.5 81.13C91.58 81.34 91.63 81.57 91.63 81.81C91.63 82.04 91.58 82.27 91.5 82.49C91.41 82.7 91.28 82.9 91.11 83.06C90.95 83.23 90.75 83.36 90.54 83.45C90.32 83.53 90.09 83.58 89.86 83.58Z" />
		<path  className="shp0" d="M89.87 94.89C89.64 94.89 89.41 94.85 89.19 94.76C88.98 94.67 88.78 94.54 88.61 94.38C88.45 94.21 88.32 94.02 88.23 93.8C88.14 93.59 88.1 93.36 88.1 93.12C88.1 92.89 88.14 92.66 88.23 92.45C88.32 92.23 88.45 92.03 88.61 91.87C88.78 91.7 88.97 91.57 89.19 91.49C89.4 91.4 89.63 91.35 89.87 91.36C90.1 91.35 90.33 91.4 90.55 91.48C90.76 91.57 90.96 91.7 91.12 91.87C91.29 92.03 91.42 92.23 91.51 92.44C91.59 92.66 91.64 92.89 91.64 93.12C91.64 93.35 91.59 93.59 91.51 93.8C91.42 94.02 91.29 94.21 91.12 94.38C90.96 94.54 90.76 94.67 90.55 94.76C90.33 94.85 90.1 94.89 89.87 94.89Z" />
		<path  className="shp0" d="M88.61 105.78C87.9 105.08 87.9 103.94 88.6 103.24C89.31 102.53 90.45 102.53 91.15 103.23C91.85 103.94 91.86 105.07 91.15 105.78C90.45 106.48 89.31 106.48 88.61 105.78Z" />
		<path  className="shp0" d="M89.89 117.66C89.66 117.66 89.43 117.62 89.21 117.53C88.99 117.44 88.8 117.31 88.63 117.15C88.47 116.98 88.34 116.79 88.25 116.57C88.16 116.36 88.12 116.13 88.12 115.89C88.12 115.66 88.16 115.43 88.25 115.21C88.34 115 88.47 114.8 88.63 114.64C88.8 114.47 88.99 114.34 89.21 114.25C89.42 114.17 89.65 114.12 89.89 114.12C90.12 114.12 90.35 114.17 90.57 114.25C90.78 114.34 90.98 114.47 91.14 114.64C91.31 114.8 91.44 115 91.53 115.21C91.61 115.43 91.66 115.66 91.66 115.89C91.66 116.12 91.61 116.35 91.53 116.57C91.44 116.79 91.31 116.98 91.14 117.15C90.98 117.31 90.78 117.44 90.57 117.53C90.35 117.62 90.12 117.66 89.89 117.66Z" />
		<path  className="shp0" d="M89.83 129.04C89.6 129.05 89.36 129 89.15 128.91C88.93 128.83 88.74 128.7 88.57 128.53C88.41 128.37 88.28 128.17 88.19 127.96C88.1 127.74 88.06 127.51 88.06 127.28C88.06 127.04 88.1 126.81 88.19 126.6C88.28 126.38 88.41 126.19 88.57 126.02C88.74 125.86 88.93 125.73 89.15 125.64C89.36 125.55 89.59 125.51 89.83 125.51C90.06 125.51 90.29 125.55 90.5 125.64C90.72 125.73 90.92 125.86 91.08 126.02C91.25 126.18 91.38 126.38 91.46 126.6C91.55 126.81 91.6 127.04 91.59 127.27C91.6 127.51 91.55 127.74 91.47 127.95C91.38 128.17 91.25 128.37 91.08 128.53C90.92 128.7 90.72 128.83 90.51 128.91C90.29 129 90.06 129.05 89.83 129.04Z" />
		<path  className="shp0" d="M81.8 3.89C81.57 3.9 81.34 3.85 81.12 3.76C80.91 3.68 80.71 3.55 80.55 3.38C80.38 3.22 80.25 3.02 80.16 2.81C80.07 2.59 80.03 2.36 80.03 2.13C80.03 1.89 80.07 1.66 80.16 1.45C80.25 1.23 80.38 1.04 80.54 0.87C80.71 0.71 80.9 0.58 81.12 0.49C81.34 0.4 81.57 0.36 81.8 0.36C82.03 0.35 82.26 0.4 82.48 0.49C82.69 0.57 82.89 0.7 83.05 0.87C83.22 1.03 83.35 1.23 83.44 1.44C83.53 1.66 83.57 1.89 83.57 2.12C83.57 2.36 83.53 2.59 83.44 2.8C83.35 3.02 83.22 3.21 83.06 3.38C82.89 3.54 82.7 3.67 82.48 3.76C82.27 3.85 82.03 3.89 81.8 3.89Z" />
		<path  className="shp0" d="M80.54 14.78C79.83 14.08 79.83 12.94 80.54 12.24C81.24 11.53 82.38 11.53 83.08 12.24C83.79 12.94 83.79 14.08 83.08 14.78C82.38 15.49 81.24 15.49 80.54 14.78Z" />
		<path  className="shp0" d="M81.82 26.66C81.59 26.66 81.36 26.62 81.14 26.53C80.93 26.44 80.73 26.31 80.57 26.15C80.4 25.99 80.27 25.79 80.18 25.57C80.09 25.36 80.05 25.13 80.05 24.9C80.05 24.66 80.09 24.43 80.18 24.22C80.27 24 80.4 23.8 80.56 23.64C80.73 23.48 80.92 23.34 81.14 23.26C81.35 23.17 81.59 23.12 81.82 23.13C82.05 23.12 82.28 23.17 82.5 23.26C82.71 23.34 82.91 23.47 83.07 23.64C83.24 23.8 83.37 24 83.46 24.21C83.55 24.43 83.59 24.66 83.59 24.89C83.59 25.13 83.55 25.36 83.46 25.57C83.37 25.79 83.24 25.98 83.08 26.15C82.91 26.31 82.72 26.44 82.5 26.53C82.28 26.62 82.05 26.66 81.82 26.66Z" />
		<path  className="shp0" d="M81.76 38.05C81.53 38.05 81.3 38 81.08 37.92C80.87 37.83 80.67 37.7 80.5 37.53C80.34 37.37 80.21 37.17 80.12 36.96C80.03 36.74 79.99 36.51 79.99 36.28C79.99 36.05 80.03 35.82 80.12 35.6C80.21 35.39 80.34 35.19 80.5 35.02C80.67 34.86 80.86 34.73 81.08 34.64C81.29 34.55 81.52 34.51 81.76 34.51C81.99 34.51 82.22 34.55 82.44 34.64C82.65 34.73 82.85 34.86 83.01 35.02C83.18 35.19 83.31 35.38 83.4 35.6C83.48 35.81 83.53 36.04 83.53 36.28C83.53 36.51 83.48 36.74 83.4 36.96C83.31 37.17 83.18 37.37 83.01 37.53C82.85 37.7 82.65 37.83 82.44 37.92C82.22 38 81.99 38.05 81.76 38.05Z" />
		<path  className="shp0" d="M81.84 49.43C81.61 49.43 81.38 49.39 81.16 49.3C80.95 49.21 80.75 49.08 80.59 48.92C80.42 48.75 80.29 48.56 80.2 48.34C80.11 48.13 80.07 47.9 80.07 47.66C80.07 47.43 80.11 47.2 80.2 46.99C80.29 46.77 80.42 46.57 80.58 46.41C80.75 46.24 80.94 46.11 81.16 46.03C81.37 45.94 81.6 45.89 81.84 45.9C82.07 45.89 82.3 45.94 82.52 46.02C82.73 46.11 82.93 46.24 83.09 46.41C83.26 46.57 83.39 46.77 83.48 46.98C83.56 47.2 83.61 47.43 83.61 47.66C83.61 47.89 83.57 48.12 83.48 48.34C83.39 48.56 83.26 48.75 83.1 48.92C82.93 49.08 82.74 49.21 82.52 49.3C82.3 49.39 82.07 49.43 81.84 49.43Z" />
		<path  className="shp0" d="M81.85 60.74C81.62 60.75 81.39 60.7 81.17 60.61C80.96 60.53 80.76 60.4 80.59 60.23C80.43 60.07 80.3 59.87 80.21 59.66C80.12 59.44 80.08 59.21 80.08 58.98C80.08 58.75 80.12 58.51 80.21 58.3C80.3 58.08 80.43 57.89 80.59 57.72C80.76 57.56 80.95 57.43 81.17 57.34C81.38 57.25 81.61 57.21 81.85 57.21C82.08 57.21 82.31 57.25 82.53 57.34C82.74 57.43 82.94 57.56 83.1 57.72C83.27 57.88 83.4 58.08 83.49 58.3C83.57 58.51 83.62 58.74 83.62 58.97C83.62 59.21 83.58 59.44 83.49 59.65C83.4 59.87 83.27 60.07 83.1 60.23C82.94 60.4 82.74 60.53 82.53 60.61C82.31 60.7 82.08 60.75 81.85 60.74Z" />
		<path  className="shp0" d="M80.59 71.63C79.88 70.93 79.88 69.79 80.58 69.09C81.29 68.38 82.43 68.38 83.13 69.09C83.83 69.79 83.84 70.93 83.13 71.63C82.43 72.34 81.29 72.34 80.59 71.63Z" />
		<path  className="shp0" d="M81.87 83.51C81.64 83.52 81.41 83.47 81.19 83.38C80.98 83.3 80.78 83.17 80.61 83C80.45 82.84 80.32 82.64 80.23 82.43C80.14 82.21 80.1 81.98 80.1 81.75C80.1 81.51 80.14 81.28 80.23 81.07C80.32 80.85 80.45 80.66 80.61 80.49C80.78 80.33 80.97 80.2 81.19 80.11C81.4 80.02 81.63 79.98 81.87 79.98C82.1 79.98 82.33 80.02 82.55 80.11C82.76 80.19 82.96 80.32 83.12 80.49C83.29 80.65 83.42 80.85 83.51 81.06C83.59 81.28 83.64 81.51 83.64 81.74C83.64 81.98 83.59 82.21 83.51 82.42C83.42 82.64 83.29 82.83 83.12 83C82.96 83.16 82.76 83.29 82.55 83.38C82.33 83.47 82.1 83.52 81.87 83.51Z" />
		<path  className="shp0" d="M81.88 94.97C81.65 94.97 81.42 94.93 81.2 94.84C80.98 94.75 80.79 94.62 80.62 94.46C80.46 94.29 80.33 94.1 80.24 93.88C80.15 93.67 80.11 93.43 80.11 93.2C80.11 92.97 80.15 92.74 80.24 92.52C80.33 92.31 80.46 92.11 80.62 91.95C80.79 91.78 80.98 91.65 81.2 91.56C81.41 91.48 81.64 91.43 81.88 91.43C82.11 91.43 82.34 91.47 82.56 91.56C82.77 91.65 82.97 91.78 83.13 91.94C83.3 92.11 83.43 92.3 83.52 92.52C83.6 92.74 83.65 92.97 83.65 93.2C83.65 93.43 83.6 93.66 83.52 93.88C83.43 94.09 83.3 94.29 83.13 94.45C82.97 94.62 82.77 94.75 82.56 94.84C82.34 94.93 82.11 94.97 81.88 94.97Z" />
		<path  className="shp0" d="M81.89 106.28C81.66 106.28 81.43 106.24 81.21 106.15C80.99 106.06 80.8 105.93 80.63 105.77C80.47 105.61 80.34 105.41 80.25 105.19C80.16 104.98 80.12 104.75 80.12 104.52C80.12 104.28 80.16 104.05 80.25 103.84C80.34 103.62 80.47 103.42 80.63 103.26C80.8 103.1 80.99 102.97 81.21 102.88C81.42 102.79 81.65 102.74 81.89 102.75C82.12 102.74 82.35 102.79 82.57 102.88C82.78 102.96 82.98 103.09 83.14 103.26C83.31 103.42 83.44 103.62 83.52 103.83C83.61 104.05 83.66 104.28 83.66 104.51C83.66 104.75 83.61 104.98 83.53 105.19C83.44 105.41 83.31 105.6 83.14 105.77C82.98 105.93 82.78 106.06 82.57 106.15C82.35 106.24 82.12 106.28 81.89 106.28Z" />
		<path  className="shp0" d="M81.9 117.74C81.67 117.74 81.44 117.7 81.22 117.61C81 117.52 80.81 117.39 80.64 117.23C80.48 117.06 80.35 116.87 80.26 116.65C80.17 116.43 80.13 116.2 80.13 115.97C80.13 115.74 80.17 115.51 80.26 115.29C80.35 115.08 80.48 114.88 80.64 114.72C80.81 114.55 81 114.42 81.22 114.33C81.43 114.24 81.66 114.2 81.9 114.2C82.13 114.2 82.36 114.24 82.58 114.33C82.79 114.42 82.99 114.55 83.15 114.71C83.32 114.88 83.45 115.07 83.53 115.29C83.62 115.5 83.67 115.73 83.67 115.97C83.67 116.2 83.62 116.43 83.54 116.65C83.45 116.86 83.32 117.06 83.15 117.22C82.99 117.39 82.79 117.52 82.58 117.61C82.36 117.69 82.13 117.74 81.9 117.74L81.9 117.74Z" />
		<path  className="shp0" d="M81.91 129.05C81.68 129.05 81.45 129.01 81.23 128.92C81.01 128.83 80.82 128.7 80.65 128.54C80.49 128.37 80.36 128.18 80.27 127.96C80.18 127.75 80.14 127.52 80.14 127.28C80.14 127.05 80.18 126.82 80.27 126.61C80.36 126.39 80.49 126.19 80.65 126.03C80.82 125.86 81.01 125.73 81.23 125.65C81.44 125.56 81.67 125.51 81.91 125.52C82.14 125.51 82.37 125.56 82.58 125.64C82.8 125.73 83 125.86 83.16 126.03C83.33 126.19 83.46 126.39 83.54 126.6C83.63 126.82 83.68 127.05 83.68 127.28C83.68 127.51 83.63 127.75 83.55 127.96C83.46 128.18 83.33 128.37 83.16 128.54C83 128.7 82.8 128.83 82.59 128.92C82.37 129.01 82.14 129.05 81.91 129.05Z" />
		<path  className="shp0" d="M73.81 3.97C73.58 3.97 73.35 3.93 73.13 3.84C72.92 3.75 72.72 3.62 72.56 3.46C72.39 3.29 72.26 3.1 72.17 2.88C72.08 2.67 72.04 2.44 72.04 2.2C72.04 1.97 72.08 1.74 72.17 1.52C72.26 1.31 72.39 1.11 72.55 0.95C72.72 0.78 72.91 0.65 73.13 0.57C73.34 0.48 73.58 0.43 73.81 0.43C74.04 0.43 74.27 0.48 74.49 0.56C74.7 0.65 74.9 0.78 75.06 0.95C75.23 1.11 75.36 1.31 75.45 1.52C75.54 1.74 75.58 1.97 75.58 2.2C75.58 2.43 75.54 2.66 75.45 2.88C75.36 3.1 75.23 3.29 75.07 3.46C74.9 3.62 74.71 3.75 74.49 3.84C74.28 3.93 74.04 3.97 73.81 3.97Z" />
		<path  className="shp0" d="M72.62 14.79C71.91 14.09 71.91 12.95 72.62 12.24C73.32 11.54 74.46 11.54 75.16 12.24C75.87 12.95 75.87 14.08 75.16 14.79C74.46 15.49 73.32 15.49 72.62 14.79Z" />
		<path  className="shp0" d="M73.9 26.67C73.67 26.67 73.44 26.63 73.22 26.54C73.01 26.45 72.81 26.32 72.65 26.16C72.48 25.99 72.35 25.8 72.26 25.58C72.17 25.37 72.13 25.14 72.13 24.9C72.13 24.67 72.17 24.44 72.26 24.22C72.35 24.01 72.48 23.81 72.64 23.65C72.81 23.48 73 23.35 73.22 23.26C73.44 23.18 73.67 23.13 73.9 23.13C74.13 23.13 74.36 23.17 74.58 23.26C74.79 23.35 74.99 23.48 75.15 23.64C75.32 23.81 75.45 24 75.54 24.22C75.63 24.44 75.67 24.67 75.67 24.9C75.67 25.13 75.63 25.36 75.54 25.58C75.45 25.79 75.32 25.99 75.16 26.15C74.99 26.32 74.8 26.45 74.58 26.54C74.37 26.63 74.13 26.67 73.9 26.67Z" />
		<path  className="shp0" d="M73.84 38.05C73.61 38.06 73.38 38.01 73.16 37.92C72.95 37.84 72.75 37.71 72.59 37.54C72.42 37.38 72.29 37.18 72.2 36.97C72.11 36.75 72.07 36.52 72.07 36.29C72.07 36.05 72.11 35.82 72.2 35.61C72.29 35.39 72.42 35.2 72.58 35.03C72.75 34.87 72.94 34.74 73.16 34.65C73.37 34.56 73.6 34.52 73.84 34.52C74.07 34.51 74.3 34.56 74.52 34.65C74.73 34.73 74.93 34.86 75.09 35.03C75.26 35.19 75.39 35.39 75.48 35.6C75.56 35.82 75.61 36.05 75.61 36.28C75.61 36.52 75.57 36.75 75.48 36.96C75.39 37.18 75.26 37.37 75.1 37.54C74.93 37.7 74.74 37.83 74.52 37.92C74.3 38.01 74.07 38.06 73.84 38.05Z" />
		<path  className="shp0" d="M73.92 49.44C73.69 49.44 73.46 49.4 73.24 49.31C73.03 49.22 72.83 49.09 72.67 48.93C72.5 48.76 72.37 48.57 72.28 48.35C72.19 48.13 72.15 47.9 72.15 47.67C72.15 47.44 72.19 47.21 72.28 46.99C72.37 46.78 72.5 46.58 72.66 46.42C72.83 46.25 73.02 46.12 73.24 46.03C73.45 45.94 73.69 45.9 73.92 45.9C74.15 45.9 74.38 45.94 74.6 46.03C74.81 46.12 75.01 46.25 75.17 46.41C75.34 46.58 75.47 46.77 75.56 46.99C75.65 47.2 75.69 47.44 75.69 47.67C75.69 47.9 75.65 48.13 75.56 48.35C75.47 48.56 75.34 48.76 75.18 48.92C75.01 49.09 74.82 49.22 74.6 49.31C74.38 49.39 74.15 49.44 73.92 49.44Z" />
		<path  className="shp0" d="M73.86 60.82C73.63 60.82 73.4 60.78 73.18 60.69C72.97 60.6 72.77 60.47 72.6 60.31C72.44 60.15 72.31 59.95 72.22 59.73C72.13 59.52 72.09 59.29 72.09 59.06C72.09 58.82 72.13 58.59 72.22 58.38C72.31 58.16 72.44 57.96 72.6 57.8C72.77 57.64 72.96 57.51 73.18 57.42C73.39 57.33 73.62 57.28 73.86 57.29C74.09 57.28 74.32 57.33 74.54 57.42C74.75 57.5 74.95 57.63 75.11 57.8C75.28 57.96 75.41 58.16 75.5 58.37C75.58 58.59 75.63 58.82 75.63 59.05C75.63 59.29 75.59 59.52 75.5 59.73C75.41 59.95 75.28 60.14 75.11 60.31C74.95 60.47 74.75 60.6 74.54 60.69C74.32 60.78 74.09 60.82 73.86 60.82Z" />
		<path  className="shp0" d="M72.6 71.71C71.89 71.01 71.89 69.87 72.59 69.17C73.3 68.46 74.44 68.46 75.14 69.16C75.84 69.87 75.85 71.01 75.14 71.71C74.44 72.41 73.3 72.42 72.6 71.71Z" />
		<path  className="shp0" d="M73.88 83.59C73.65 83.59 73.42 83.55 73.2 83.46C72.98 83.37 72.79 83.24 72.62 83.08C72.46 82.91 72.33 82.72 72.24 82.5C72.15 82.29 72.11 82.06 72.11 81.82C72.11 81.59 72.15 81.36 72.24 81.15C72.33 80.93 72.46 80.73 72.62 80.57C72.79 80.4 72.98 80.27 73.2 80.19C73.41 80.1 73.64 80.05 73.88 80.06C74.11 80.05 74.34 80.1 74.56 80.18C74.77 80.27 74.97 80.4 75.13 80.57C75.3 80.73 75.43 80.93 75.52 81.14C75.6 81.36 75.65 81.59 75.65 81.82C75.65 82.05 75.6 82.29 75.52 82.5C75.43 82.72 75.3 82.91 75.13 83.08C74.97 83.24 74.77 83.37 74.56 83.46C74.34 83.55 74.11 83.59 73.88 83.59Z" />
		<path  className="shp0" d="M73.89 94.9C73.66 94.91 73.43 94.86 73.21 94.77C72.99 94.69 72.8 94.56 72.63 94.39C72.47 94.23 72.34 94.03 72.25 93.82C72.16 93.6 72.12 93.37 72.12 93.14C72.12 92.91 72.16 92.67 72.25 92.46C72.34 92.24 72.47 92.05 72.63 91.88C72.8 91.72 72.99 91.59 73.21 91.5C73.42 91.41 73.65 91.37 73.89 91.37C74.12 91.37 74.35 91.41 74.57 91.5C74.78 91.59 74.98 91.72 75.14 91.88C75.31 92.04 75.44 92.24 75.52 92.46C75.61 92.67 75.66 92.9 75.66 93.14C75.66 93.37 75.61 93.6 75.53 93.81C75.44 94.03 75.31 94.23 75.14 94.39C74.98 94.56 74.78 94.69 74.57 94.77C74.35 94.86 74.12 94.91 73.89 94.9L73.89 94.9Z" />
		<path  className="shp0" d="M73.97 106.29C73.74 106.29 73.51 106.25 73.29 106.16C73.07 106.07 72.88 105.94 72.71 105.78C72.55 105.61 72.42 105.42 72.33 105.2C72.24 104.99 72.2 104.76 72.2 104.52C72.2 104.29 72.24 104.06 72.33 103.84C72.42 103.63 72.55 103.43 72.71 103.27C72.88 103.1 73.07 102.97 73.29 102.88C73.5 102.8 73.73 102.75 73.97 102.75C74.2 102.75 74.43 102.79 74.65 102.88C74.86 102.97 75.06 103.1 75.22 103.26C75.39 103.43 75.52 103.62 75.61 103.84C75.69 104.06 75.74 104.29 75.74 104.52C75.74 104.75 75.69 104.98 75.61 105.2C75.52 105.41 75.39 105.61 75.22 105.78C75.06 105.94 74.86 106.07 74.65 106.16C74.43 106.25 74.2 106.29 73.97 106.29Z" />
		<path  className="shp0" d="M73.91 117.67C73.68 117.68 73.45 117.63 73.23 117.54C73.01 117.46 72.82 117.33 72.65 117.16C72.49 117 72.36 116.8 72.27 116.59C72.18 116.37 72.14 116.14 72.14 115.91C72.14 115.67 72.18 115.44 72.27 115.23C72.36 115.01 72.49 114.82 72.65 114.65C72.82 114.49 73.01 114.36 73.23 114.27C73.44 114.18 73.67 114.14 73.91 114.14C74.14 114.14 74.37 114.18 74.58 114.27C74.8 114.35 75 114.48 75.16 114.65C75.33 114.81 75.46 115.01 75.54 115.22C75.63 115.44 75.68 115.67 75.67 115.9C75.68 116.14 75.63 116.37 75.55 116.58C75.46 116.8 75.33 116.99 75.16 117.16C75 117.32 74.8 117.45 74.59 117.54C74.37 117.63 74.14 117.68 73.91 117.67Z" />
		<path  className="shp0" d="M72.65 128.56C71.94 127.86 71.94 126.72 72.64 126.02C73.35 125.31 74.48 125.31 75.19 126.02C75.89 126.72 75.89 127.86 75.19 128.56C74.49 129.27 73.35 129.27 72.65 128.56Z" />
		<path  className="shp0" d="M65.89 3.98C65.66 3.98 65.43 3.94 65.21 3.85C65 3.76 64.8 3.63 64.64 3.47C64.47 3.3 64.34 3.11 64.25 2.89C64.17 2.67 64.12 2.44 64.12 2.21C64.12 1.98 64.16 1.75 64.25 1.53C64.34 1.32 64.47 1.12 64.63 0.96C64.8 0.79 64.99 0.66 65.21 0.57C65.43 0.48 65.66 0.44 65.89 0.44C66.12 0.44 66.35 0.48 66.57 0.57C66.78 0.66 66.98 0.79 67.14 0.95C67.31 1.12 67.44 1.31 67.53 1.53C67.62 1.74 67.66 1.98 67.66 2.21C67.66 2.44 67.62 2.67 67.53 2.89C67.44 3.1 67.31 3.3 67.15 3.46C66.98 3.63 66.79 3.76 66.57 3.85C66.36 3.93 66.12 3.98 65.89 3.98Z" />
		<path  className="shp0" d="M65.9 15.29C65.67 15.29 65.44 15.25 65.22 15.16C65.01 15.07 64.81 14.94 64.65 14.78C64.48 14.61 64.35 14.42 64.26 14.2C64.17 13.99 64.13 13.76 64.13 13.52C64.13 13.29 64.17 13.06 64.26 12.85C64.35 12.63 64.48 12.43 64.64 12.27C64.81 12.1 65 11.97 65.22 11.89C65.43 11.8 65.67 11.75 65.9 11.76C66.13 11.75 66.36 11.8 66.58 11.88C66.79 11.97 66.99 12.1 67.15 12.27C67.32 12.43 67.45 12.63 67.54 12.84C67.63 13.06 67.67 13.29 67.67 13.52C67.67 13.75 67.63 13.99 67.54 14.2C67.45 14.42 67.32 14.61 67.16 14.78C66.99 14.94 66.8 15.07 66.58 15.16C66.37 15.25 66.13 15.29 65.9 15.29Z" />
		<path  className="shp0" d="M65.84 26.68C65.61 26.68 65.38 26.63 65.16 26.55C64.95 26.46 64.75 26.33 64.59 26.16C64.42 26 64.29 25.8 64.2 25.59C64.11 25.37 64.07 25.14 64.07 24.91C64.07 24.68 64.11 24.45 64.2 24.23C64.29 24.01 64.42 23.82 64.58 23.65C64.75 23.49 64.94 23.36 65.16 23.27C65.37 23.18 65.6 23.14 65.84 23.14C66.07 23.14 66.3 23.18 66.52 23.27C66.73 23.36 66.93 23.49 67.09 23.65C67.26 23.82 67.39 24.01 67.48 24.23C67.56 24.44 67.61 24.67 67.61 24.91C67.61 25.14 67.57 25.37 67.48 25.59C67.39 25.8 67.26 26 67.1 26.16C66.93 26.33 66.74 26.46 66.52 26.55C66.3 26.63 66.07 26.68 65.84 26.68Z" />
		<path  className="shp0" d="M65.92 38.06C65.69 38.06 65.46 38.02 65.24 37.93C65.03 37.84 64.83 37.71 64.67 37.55C64.5 37.38 64.37 37.19 64.28 36.97C64.19 36.76 64.15 36.53 64.15 36.29C64.15 36.06 64.19 35.83 64.28 35.61C64.37 35.4 64.5 35.2 64.66 35.04C64.83 34.87 65.02 34.74 65.24 34.65C65.45 34.57 65.69 34.52 65.92 34.52C66.15 34.52 66.38 34.57 66.6 34.65C66.81 34.74 67.01 34.87 67.17 35.04C67.34 35.2 67.47 35.4 67.56 35.61C67.64 35.83 67.69 36.06 67.69 36.29C67.69 36.52 67.65 36.75 67.56 36.97C67.47 37.19 67.34 37.38 67.18 37.55C67.01 37.71 66.82 37.84 66.6 37.93C66.38 38.02 66.15 38.06 65.92 38.06Z" />
		<path  className="shp0" d="M64.66 48.95C63.95 48.25 63.95 47.11 64.66 46.4C65.36 45.7 66.5 45.7 67.2 46.4C67.91 47.11 67.91 48.24 67.2 48.95C66.5 49.65 65.36 49.65 64.66 48.95Z" />
		<path  className="shp0" d="M65.94 60.83C65.71 60.83 65.48 60.79 65.26 60.7C65.05 60.61 64.85 60.48 64.69 60.32C64.52 60.15 64.39 59.96 64.3 59.74C64.21 59.53 64.17 59.3 64.17 59.06C64.17 58.83 64.21 58.6 64.3 58.38C64.39 58.17 64.52 57.97 64.68 57.81C64.85 57.64 65.04 57.51 65.26 57.42C65.47 57.34 65.7 57.29 65.94 57.29C66.17 57.29 66.4 57.33 66.62 57.42C66.83 57.51 67.03 57.64 67.19 57.8C67.36 57.97 67.49 58.16 67.58 58.38C67.66 58.6 67.71 58.83 67.71 59.06C67.71 59.29 67.67 59.52 67.58 59.74C67.49 59.95 67.36 60.15 67.2 60.31C67.03 60.48 66.83 60.61 66.62 60.7C66.4 60.79 66.17 60.83 65.94 60.83Z" />
		<path  className="shp0" d="M65.95 72.14C65.72 72.14 65.49 72.1 65.27 72.01C65.06 71.92 64.86 71.8 64.69 71.63C64.53 71.47 64.4 71.27 64.31 71.06C64.22 70.84 64.18 70.61 64.18 70.38C64.18 70.14 64.22 69.91 64.31 69.7C64.4 69.48 64.53 69.29 64.69 69.12C64.86 68.96 65.05 68.83 65.27 68.74C65.48 68.65 65.71 68.6 65.95 68.61C66.18 68.6 66.41 68.65 66.63 68.74C66.84 68.82 67.04 68.95 67.2 69.12C67.37 69.28 67.5 69.48 67.59 69.69C67.67 69.91 67.72 70.14 67.72 70.37C67.72 70.61 67.67 70.84 67.59 71.05C67.5 71.27 67.37 71.46 67.2 71.63C67.04 71.79 66.84 71.92 66.63 72.01C66.41 72.1 66.18 72.14 65.95 72.14Z" />
		<path  className="shp0" d="M65.96 83.6C65.73 83.6 65.5 83.56 65.28 83.47C65.07 83.38 64.87 83.25 64.7 83.09C64.54 82.92 64.41 82.73 64.32 82.51C64.23 82.29 64.19 82.06 64.19 81.83C64.19 81.6 64.23 81.37 64.32 81.15C64.41 80.94 64.54 80.74 64.7 80.58C64.87 80.41 65.06 80.28 65.28 80.19C65.49 80.1 65.72 80.06 65.96 80.06C66.19 80.06 66.42 80.1 66.64 80.19C66.85 80.28 67.05 80.41 67.21 80.57C67.38 80.74 67.51 80.93 67.6 81.15C67.68 81.36 67.73 81.6 67.73 81.83C67.73 82.06 67.68 82.29 67.6 82.51C67.51 82.72 67.38 82.92 67.21 83.08C67.05 83.25 66.85 83.38 66.64 83.47C66.42 83.56 66.19 83.6 65.96 83.6Z" />
		<path  className="shp0" d="M65.97 94.91C65.74 94.91 65.51 94.87 65.29 94.78C65.07 94.69 64.88 94.56 64.71 94.4C64.55 94.23 64.42 94.04 64.33 93.82C64.24 93.61 64.2 93.38 64.2 93.14C64.2 92.91 64.24 92.68 64.33 92.47C64.42 92.25 64.55 92.05 64.71 91.89C64.88 91.72 65.07 91.59 65.29 91.51C65.5 91.42 65.73 91.37 65.97 91.38C66.2 91.37 66.43 91.42 66.65 91.5C66.86 91.59 67.06 91.72 67.22 91.89C67.39 92.05 67.52 92.25 67.61 92.46C67.69 92.68 67.74 92.91 67.74 93.14C67.74 93.37 67.69 93.61 67.61 93.82C67.52 94.04 67.39 94.23 67.22 94.4C67.06 94.56 66.86 94.69 66.65 94.78C66.43 94.87 66.2 94.91 65.97 94.91Z" />
		<path  className="shp0" d="M64.71 105.8C64 105.1 64 103.96 64.7 103.26C65.41 102.55 66.55 102.55 67.25 103.25C67.95 103.96 67.96 105.09 67.25 105.8C66.55 106.5 65.41 106.5 64.71 105.8Z" />
		<path  className="shp0" d="M65.99 117.68C65.76 117.68 65.53 117.64 65.31 117.55C65.09 117.46 64.9 117.33 64.73 117.17C64.57 117 64.44 116.81 64.35 116.59C64.26 116.38 64.22 116.15 64.22 115.91C64.22 115.68 64.26 115.45 64.35 115.23C64.44 115.02 64.57 114.82 64.73 114.66C64.9 114.49 65.09 114.36 65.31 114.27C65.52 114.19 65.75 114.14 65.99 114.14C66.22 114.14 66.45 114.19 66.67 114.27C66.88 114.36 67.08 114.49 67.24 114.66C67.41 114.82 67.54 115.02 67.62 115.23C67.71 115.45 67.76 115.68 67.76 115.91C67.76 116.14 67.71 116.37 67.63 116.59C67.54 116.81 67.41 117 67.24 117.17C67.08 117.33 66.88 117.46 66.67 117.55C66.45 117.64 66.22 117.68 65.99 117.68Z" />
		<path  className="shp0" d="M65.93 129.06C65.7 129.07 65.46 129.02 65.25 128.93C65.03 128.85 64.84 128.72 64.67 128.55C64.51 128.39 64.38 128.19 64.29 127.98C64.2 127.76 64.16 127.53 64.16 127.3C64.16 127.07 64.2 126.83 64.29 126.62C64.38 126.4 64.51 126.21 64.67 126.04C64.84 125.88 65.03 125.75 65.25 125.66C65.46 125.57 65.69 125.53 65.93 125.53C66.16 125.53 66.39 125.57 66.6 125.66C66.82 125.75 67.02 125.88 67.18 126.04C67.35 126.21 67.48 126.4 67.56 126.62C67.65 126.83 67.7 127.06 67.69 127.3C67.7 127.53 67.65 127.76 67.57 127.97C67.48 128.19 67.35 128.39 67.18 128.55C67.02 128.72 66.82 128.85 66.61 128.93C66.39 129.02 66.16 129.07 65.93 129.06Z" />
		<path  className="shp0" d="M57.9 3.91C57.67 3.92 57.44 3.87 57.22 3.78C57.01 3.7 56.81 3.57 56.65 3.4C56.48 3.24 56.35 3.04 56.26 2.83C56.17 2.61 56.13 2.38 56.13 2.15C56.13 1.91 56.17 1.68 56.26 1.47C56.35 1.25 56.48 1.06 56.64 0.89C56.81 0.73 57 0.6 57.22 0.51C57.43 0.42 57.67 0.38 57.9 0.38C58.13 0.38 58.36 0.42 58.58 0.51C58.79 0.6 58.99 0.73 59.15 0.89C59.32 1.05 59.45 1.25 59.54 1.47C59.63 1.68 59.67 1.91 59.67 2.14C59.67 2.38 59.63 2.61 59.54 2.82C59.45 3.04 59.32 3.23 59.16 3.4C58.99 3.56 58.8 3.69 58.58 3.78C58.37 3.87 58.13 3.92 57.9 3.91Z" />
		<path  className="shp0" d="M57.91 15.37C57.68 15.37 57.45 15.33 57.23 15.24C57.02 15.15 56.82 15.02 56.66 14.86C56.49 14.69 56.36 14.5 56.27 14.28C56.18 14.07 56.14 13.83 56.14 13.6C56.14 13.37 56.18 13.14 56.27 12.92C56.36 12.71 56.49 12.51 56.65 12.35C56.82 12.18 57.01 12.05 57.23 11.96C57.44 11.88 57.68 11.83 57.91 11.83C58.14 11.83 58.37 11.87 58.59 11.96C58.8 12.05 59 12.18 59.16 12.34C59.33 12.51 59.46 12.7 59.55 12.92C59.64 13.14 59.68 13.37 59.68 13.6C59.68 13.83 59.64 14.06 59.55 14.28C59.46 14.49 59.33 14.69 59.17 14.85C59 15.02 58.81 15.15 58.59 15.24C58.38 15.33 58.14 15.37 57.91 15.37Z" />
		<path  className="shp0" d="M57.92 26.68C57.69 26.68 57.46 26.64 57.24 26.55C57.03 26.46 56.83 26.33 56.67 26.17C56.5 26.01 56.37 25.81 56.28 25.59C56.19 25.38 56.15 25.15 56.15 24.92C56.15 24.68 56.19 24.45 56.28 24.24C56.37 24.02 56.5 23.83 56.66 23.66C56.83 23.5 57.02 23.37 57.24 23.28C57.45 23.19 57.69 23.14 57.92 23.15C58.15 23.14 58.38 23.19 58.6 23.28C58.81 23.36 59.01 23.49 59.17 23.66C59.34 23.82 59.47 24.02 59.56 24.23C59.64 24.45 59.69 24.68 59.69 24.91C59.69 25.15 59.65 25.38 59.56 25.59C59.47 25.81 59.34 26 59.18 26.17C59.01 26.33 58.82 26.46 58.6 26.55C58.38 26.64 58.15 26.68 57.92 26.68Z" />
		<path  className="shp0" d="M58 38.07C57.77 38.07 57.54 38.02 57.32 37.94C57.11 37.85 56.91 37.72 56.75 37.55C56.58 37.39 56.45 37.19 56.36 36.98C56.27 36.76 56.23 36.53 56.23 36.3C56.23 36.07 56.27 35.84 56.36 35.62C56.45 35.41 56.58 35.21 56.74 35.04C56.91 34.88 57.1 34.75 57.32 34.66C57.53 34.57 57.77 34.53 58 34.53C58.23 34.53 58.46 34.57 58.68 34.66C58.89 34.75 59.09 34.88 59.25 35.04C59.42 35.21 59.55 35.4 59.64 35.62C59.73 35.83 59.77 36.06 59.77 36.3C59.77 36.53 59.73 36.76 59.64 36.98C59.55 37.19 59.42 37.39 59.26 37.55C59.09 37.72 58.9 37.85 58.68 37.94C58.47 38.02 58.23 38.07 58 38.07Z" />
		<path  className="shp0" d="M57.94 49.45C57.71 49.45 57.48 49.41 57.26 49.32C57.05 49.23 56.85 49.1 56.69 48.94C56.52 48.77 56.39 48.58 56.3 48.36C56.21 48.15 56.17 47.92 56.17 47.68C56.17 47.45 56.21 47.22 56.3 47.01C56.39 46.79 56.52 46.59 56.68 46.43C56.85 46.26 57.04 46.13 57.26 46.05C57.47 45.96 57.7 45.91 57.94 45.92C58.17 45.91 58.4 45.96 58.62 46.04C58.83 46.13 59.03 46.26 59.19 46.43C59.36 46.59 59.49 46.79 59.58 47C59.66 47.22 59.71 47.45 59.71 47.68C59.71 47.91 59.67 48.15 59.58 48.36C59.49 48.58 59.36 48.77 59.2 48.94C59.03 49.1 58.83 49.23 58.62 49.32C58.4 49.41 58.17 49.45 57.94 49.45Z" />
		<path  className="shp0" d="M57.95 60.76C57.72 60.77 57.49 60.72 57.27 60.64C57.06 60.55 56.86 60.42 56.69 60.25C56.53 60.09 56.4 59.89 56.31 59.68C56.22 59.46 56.18 59.23 56.18 59C56.18 58.77 56.22 58.53 56.31 58.32C56.4 58.1 56.53 57.91 56.69 57.74C56.86 57.58 57.05 57.45 57.27 57.36C57.48 57.27 57.71 57.23 57.95 57.23C58.18 57.23 58.41 57.27 58.63 57.36C58.84 57.45 59.04 57.58 59.2 57.74C59.37 57.91 59.5 58.1 59.59 58.32C59.67 58.53 59.72 58.76 59.72 59C59.72 59.23 59.67 59.46 59.59 59.67C59.5 59.89 59.37 60.09 59.2 60.25C59.04 60.42 58.84 60.55 58.63 60.63C58.41 60.72 58.18 60.77 57.95 60.76Z" />
		<path  className="shp0" d="M57.96 72.22C57.73 72.22 57.5 72.18 57.28 72.09C57.07 72 56.87 71.87 56.7 71.71C56.54 71.54 56.41 71.35 56.32 71.13C56.23 70.92 56.19 70.69 56.19 70.45C56.19 70.22 56.23 69.99 56.32 69.77C56.41 69.56 56.54 69.36 56.7 69.2C56.87 69.03 57.06 68.9 57.28 68.81C57.49 68.73 57.72 68.68 57.96 68.68C58.19 68.68 58.42 68.73 58.64 68.81C58.85 68.9 59.05 69.03 59.21 69.2C59.38 69.36 59.51 69.56 59.6 69.77C59.68 69.99 59.73 70.22 59.73 70.45C59.73 70.68 59.68 70.91 59.6 71.13C59.51 71.35 59.38 71.54 59.21 71.71C59.05 71.87 58.85 72 58.64 72.09C58.42 72.18 58.19 72.22 57.96 72.22Z" />
		<path  className="shp0" d="M57.97 83.53C57.74 83.54 57.51 83.49 57.29 83.4C57.07 83.32 56.88 83.19 56.71 83.02C56.55 82.86 56.42 82.66 56.33 82.45C56.24 82.23 56.2 82 56.2 81.77C56.2 81.53 56.24 81.3 56.33 81.09C56.42 80.87 56.55 80.68 56.71 80.51C56.88 80.35 57.07 80.22 57.29 80.13C57.5 80.04 57.73 80 57.97 80C58.2 80 58.43 80.04 58.65 80.13C58.86 80.22 59.06 80.35 59.22 80.51C59.39 80.67 59.52 80.87 59.61 81.09C59.69 81.3 59.74 81.53 59.74 81.76C59.74 82 59.69 82.23 59.61 82.44C59.52 82.66 59.39 82.85 59.22 83.02C59.06 83.18 58.86 83.31 58.65 83.4C58.43 83.49 58.2 83.54 57.97 83.53Z" />
		<path  className="shp0" d="M57.98 94.99C57.75 94.99 57.52 94.95 57.3 94.86C57.08 94.77 56.89 94.64 56.72 94.48C56.56 94.31 56.43 94.12 56.34 93.9C56.25 93.69 56.21 93.46 56.21 93.22C56.21 92.99 56.25 92.76 56.34 92.54C56.43 92.33 56.56 92.13 56.72 91.97C56.89 91.8 57.08 91.67 57.3 91.58C57.51 91.5 57.74 91.45 57.98 91.45C58.21 91.45 58.44 91.49 58.66 91.58C58.87 91.67 59.07 91.8 59.23 91.96C59.4 92.13 59.53 92.32 59.62 92.54C59.7 92.76 59.75 92.99 59.75 93.22C59.75 93.45 59.7 93.68 59.62 93.9C59.53 94.11 59.4 94.31 59.23 94.48C59.07 94.64 58.87 94.77 58.66 94.86C58.44 94.95 58.21 94.99 57.98 94.99L57.98 94.99Z" />
		<path  className="shp0" d="M56.79 105.81C56.08 105.11 56.08 103.97 56.78 103.26C57.49 102.56 58.63 102.56 59.33 103.26C60.03 103.96 60.04 105.1 59.33 105.81C58.63 106.51 57.49 106.51 56.79 105.81Z" />
		<path  className="shp0" d="M58 117.76C57.77 117.76 57.54 117.72 57.32 117.63C57.1 117.54 56.91 117.41 56.74 117.25C56.58 117.08 56.45 116.89 56.36 116.67C56.27 116.45 56.23 116.22 56.23 115.99C56.23 115.76 56.27 115.53 56.36 115.31C56.45 115.1 56.58 114.9 56.74 114.74C56.91 114.57 57.1 114.44 57.32 114.35C57.53 114.26 57.76 114.22 58 114.22C58.23 114.22 58.46 114.26 58.68 114.35C58.89 114.44 59.09 114.57 59.25 114.73C59.42 114.9 59.55 115.09 59.63 115.31C59.72 115.52 59.77 115.76 59.77 115.99C59.77 116.22 59.72 116.45 59.64 116.67C59.55 116.88 59.42 117.08 59.25 117.24C59.09 117.41 58.89 117.54 58.68 117.63C58.46 117.72 58.23 117.76 58 117.76Z" />
		<path  className="shp0" d="M58.01 129.07C57.78 129.07 57.54 129.03 57.33 128.94C57.11 128.85 56.92 128.72 56.75 128.56C56.59 128.4 56.46 128.2 56.37 127.98C56.28 127.77 56.24 127.54 56.24 127.3C56.24 127.07 56.28 126.84 56.37 126.63C56.46 126.41 56.59 126.21 56.75 126.05C56.92 125.88 57.11 125.75 57.33 125.67C57.54 125.58 57.77 125.53 58.01 125.54C58.24 125.53 58.47 125.58 58.68 125.67C58.9 125.75 59.1 125.88 59.26 126.05C59.43 126.21 59.56 126.41 59.64 126.62C59.73 126.84 59.78 127.07 59.77 127.3C59.78 127.53 59.73 127.77 59.65 127.98C59.56 128.2 59.43 128.39 59.26 128.56C59.1 128.72 58.9 128.85 58.69 128.94C58.47 129.03 58.24 129.07 58.01 129.07Z" />
		<path  className="shp0" d="M49.98 3.92C49.75 3.92 49.52 3.88 49.3 3.79C49.09 3.7 48.89 3.57 48.73 3.41C48.56 3.24 48.43 3.05 48.34 2.83C48.26 2.62 48.21 2.39 48.21 2.15C48.21 1.92 48.25 1.69 48.34 1.47C48.43 1.26 48.56 1.06 48.72 0.9C48.89 0.73 49.08 0.6 49.3 0.52C49.52 0.43 49.75 0.38 49.98 0.38C50.21 0.38 50.44 0.43 50.66 0.51C50.87 0.6 51.07 0.73 51.23 0.9C51.4 1.06 51.53 1.26 51.62 1.47C51.71 1.69 51.75 1.92 51.75 2.15C51.75 2.38 51.71 2.61 51.62 2.83C51.53 3.05 51.4 3.24 51.24 3.41C51.07 3.57 50.88 3.7 50.66 3.79C50.45 3.88 50.21 3.92 49.98 3.92Z" />
		<path  className="shp0" d="M48.72 14.81C48.01 14.11 48.01 12.97 48.72 12.27C49.42 11.56 50.56 11.56 51.26 12.26C51.97 12.97 51.97 14.1 51.26 14.81C50.56 15.51 49.42 15.51 48.72 14.81Z" />
		<path  className="shp0" d="M50 26.69C49.77 26.69 49.54 26.65 49.32 26.56C49.11 26.47 48.91 26.34 48.75 26.18C48.58 26.01 48.45 25.82 48.36 25.6C48.27 25.39 48.23 25.16 48.23 24.92C48.23 24.69 48.27 24.46 48.36 24.24C48.45 24.03 48.58 23.83 48.74 23.67C48.91 23.5 49.1 23.37 49.32 23.28C49.53 23.2 49.77 23.15 50 23.15C50.23 23.15 50.46 23.19 50.68 23.28C50.89 23.37 51.09 23.5 51.25 23.67C51.42 23.83 51.55 24.03 51.64 24.24C51.73 24.46 51.77 24.69 51.77 24.92C51.77 25.15 51.73 25.38 51.64 25.6C51.55 25.81 51.42 26.01 51.26 26.18C51.09 26.34 50.9 26.47 50.68 26.56C50.47 26.65 50.23 26.69 50 26.69Z" />
		<path  className="shp0" d="M49.94 38.07C49.71 38.08 49.48 38.03 49.26 37.94C49.05 37.86 48.85 37.73 48.69 37.56C48.52 37.4 48.39 37.2 48.3 36.99C48.21 36.77 48.17 36.54 48.17 36.31C48.17 36.07 48.21 35.84 48.3 35.63C48.39 35.41 48.52 35.22 48.68 35.05C48.85 34.89 49.04 34.76 49.26 34.67C49.47 34.58 49.7 34.54 49.94 34.54C50.17 34.54 50.4 34.58 50.62 34.67C50.83 34.76 51.03 34.89 51.19 35.05C51.36 35.21 51.49 35.41 51.58 35.63C51.66 35.84 51.71 36.07 51.71 36.3C51.71 36.54 51.67 36.77 51.58 36.98C51.49 37.2 51.36 37.4 51.2 37.56C51.03 37.72 50.84 37.85 50.62 37.94C50.4 38.03 50.17 38.08 49.94 38.07Z" />
		<path  className="shp0" d="M50.02 49.46C49.79 49.46 49.56 49.42 49.34 49.33C49.13 49.24 48.93 49.11 48.77 48.95C48.6 48.78 48.47 48.59 48.38 48.37C48.29 48.16 48.25 47.92 48.25 47.69C48.25 47.46 48.29 47.23 48.38 47.01C48.47 46.8 48.6 46.6 48.76 46.44C48.93 46.27 49.12 46.14 49.34 46.05C49.55 45.96 49.79 45.92 50.02 45.92C50.25 45.92 50.48 45.96 50.7 46.05C50.91 46.14 51.11 46.27 51.27 46.43C51.44 46.6 51.57 46.79 51.66 47.01C51.74 47.22 51.79 47.46 51.79 47.69C51.79 47.92 51.75 48.15 51.66 48.37C51.57 48.58 51.44 48.78 51.28 48.94C51.11 49.11 50.92 49.24 50.7 49.33C50.48 49.42 50.25 49.46 50.02 49.46Z" />
		<path  className="shp0" d="M49.96 60.84C49.73 60.84 49.5 60.8 49.28 60.71C49.07 60.62 48.87 60.49 48.7 60.33C48.54 60.17 48.41 59.97 48.32 59.75C48.23 59.54 48.19 59.31 48.19 59.08C48.19 58.84 48.23 58.61 48.32 58.4C48.41 58.18 48.54 57.99 48.7 57.82C48.87 57.66 49.06 57.53 49.28 57.44C49.49 57.35 49.72 57.3 49.96 57.31C50.19 57.3 50.42 57.35 50.64 57.44C50.85 57.52 51.05 57.65 51.21 57.82C51.38 57.98 51.51 58.18 51.6 58.39C51.68 58.61 51.73 58.84 51.73 59.07C51.73 59.31 51.68 59.54 51.6 59.75C51.51 59.97 51.38 60.16 51.21 60.33C51.05 60.49 50.85 60.62 50.64 60.71C50.42 60.8 50.19 60.84 49.96 60.84Z" />
		<path  className="shp0" d="M48.77 71.66C48.06 70.96 48.06 69.82 48.76 69.12C49.47 68.41 50.61 68.41 51.31 69.11C52.01 69.82 52.02 70.96 51.31 71.66C50.61 72.36 49.47 72.37 48.77 71.66Z" />
		<path  className="shp0" d="M49.98 83.61C49.75 83.61 49.52 83.57 49.3 83.48C49.08 83.39 48.89 83.26 48.72 83.1C48.56 82.93 48.43 82.74 48.34 82.52C48.25 82.31 48.21 82.08 48.21 81.84C48.21 81.61 48.25 81.38 48.34 81.17C48.43 80.95 48.56 80.75 48.72 80.59C48.89 80.42 49.08 80.29 49.3 80.21C49.51 80.12 49.74 80.07 49.98 80.08C50.21 80.07 50.44 80.12 50.66 80.2C50.87 80.29 51.07 80.42 51.23 80.59C51.4 80.75 51.53 80.95 51.62 81.16C51.7 81.38 51.75 81.61 51.75 81.84C51.75 82.07 51.7 82.31 51.62 82.52C51.53 82.74 51.4 82.93 51.23 83.1C51.07 83.26 50.87 83.39 50.66 83.48C50.44 83.57 50.21 83.61 49.98 83.61Z" />
		<path  className="shp0" d="M49.99 94.92C49.76 94.93 49.53 94.88 49.31 94.8C49.09 94.71 48.9 94.58 48.73 94.41C48.57 94.25 48.44 94.05 48.35 93.84C48.26 93.62 48.22 93.39 48.22 93.16C48.22 92.93 48.26 92.69 48.35 92.48C48.44 92.26 48.57 92.07 48.73 91.9C48.9 91.74 49.09 91.61 49.31 91.52C49.52 91.43 49.75 91.39 49.99 91.39C50.22 91.39 50.45 91.43 50.67 91.52C50.88 91.61 51.08 91.74 51.24 91.9C51.41 92.07 51.54 92.26 51.62 92.48C51.71 92.69 51.76 92.92 51.76 93.16C51.76 93.39 51.71 93.62 51.63 93.83C51.54 94.05 51.41 94.25 51.24 94.41C51.08 94.58 50.88 94.71 50.67 94.79C50.45 94.88 50.22 94.93 49.99 94.92Z" />
		<path  className="shp0" d="M50.07 106.31C49.84 106.31 49.61 106.27 49.39 106.18C49.17 106.09 48.98 105.96 48.81 105.8C48.65 105.63 48.52 105.44 48.43 105.22C48.34 105.01 48.3 104.78 48.3 104.54C48.3 104.31 48.34 104.08 48.43 103.86C48.52 103.65 48.65 103.45 48.81 103.29C48.98 103.12 49.17 102.99 49.39 102.9C49.6 102.82 49.83 102.77 50.07 102.77C50.3 102.77 50.53 102.82 50.75 102.9C50.96 102.99 51.16 103.12 51.32 103.29C51.49 103.45 51.62 103.65 51.71 103.86C51.79 104.08 51.84 104.31 51.84 104.54C51.84 104.77 51.79 105 51.71 105.22C51.62 105.43 51.49 105.63 51.32 105.8C51.16 105.96 50.96 106.09 50.75 106.18C50.53 106.27 50.3 106.31 50.07 106.31Z" />
		<path  className="shp0" d="M50.01 117.69C49.78 117.7 49.54 117.65 49.33 117.56C49.11 117.48 48.92 117.35 48.75 117.18C48.59 117.02 48.46 116.82 48.37 116.61C48.28 116.39 48.24 116.16 48.24 115.93C48.24 115.69 48.28 115.46 48.37 115.25C48.46 115.03 48.59 114.84 48.75 114.67C48.92 114.51 49.11 114.38 49.33 114.29C49.54 114.2 49.77 114.16 50.01 114.16C50.24 114.16 50.47 114.2 50.68 114.29C50.9 114.38 51.1 114.51 51.26 114.67C51.43 114.83 51.56 115.03 51.64 115.25C51.73 115.46 51.78 115.69 51.77 115.92C51.78 116.16 51.73 116.39 51.65 116.6C51.56 116.82 51.43 117.01 51.26 117.18C51.1 117.34 50.9 117.47 50.69 117.56C50.47 117.65 50.24 117.7 50.01 117.69Z" />
		<path  className="shp0" d="M48.75 128.58C48.04 127.88 48.04 126.74 48.74 126.04C49.45 125.33 50.58 125.33 51.29 126.04C51.99 126.74 51.99 127.88 51.29 128.58C50.59 129.29 49.45 129.29 48.75 128.58Z" />
		<path  className="shp0" d="M41.99 4C41.76 4 41.53 3.96 41.31 3.87C41.1 3.78 40.9 3.65 40.74 3.49C40.57 3.32 40.44 3.13 40.35 2.91C40.26 2.69 40.22 2.46 40.22 2.23C40.22 2 40.26 1.77 40.35 1.55C40.44 1.34 40.57 1.14 40.73 0.98C40.9 0.81 41.09 0.68 41.31 0.59C41.53 0.5 41.76 0.46 41.99 0.46C42.22 0.46 42.45 0.5 42.67 0.59C42.88 0.68 43.08 0.81 43.24 0.97C43.41 1.14 43.54 1.33 43.63 1.55C43.72 1.76 43.76 2 43.76 2.23C43.76 2.46 43.72 2.69 43.63 2.91C43.54 3.12 43.41 3.32 43.25 3.48C43.08 3.65 42.89 3.78 42.67 3.87C42.46 3.96 42.22 4 41.99 4Z" />
		<path  className="shp0" d="M40.66 14.82C39.95 14.11 39.95 12.98 40.66 12.27C41.36 11.57 42.5 11.57 43.2 12.27C43.91 12.97 43.91 14.11 43.2 14.82C42.5 15.52 41.36 15.52 40.66 14.82Z" />
		<path  className="shp0" d="M42.01 26.77C41.78 26.77 41.55 26.72 41.33 26.64C41.12 26.55 40.92 26.42 40.76 26.25C40.59 26.09 40.46 25.89 40.37 25.68C40.28 25.46 40.24 25.23 40.24 25C40.24 24.77 40.28 24.54 40.37 24.32C40.46 24.11 40.59 23.91 40.75 23.74C40.92 23.58 41.11 23.45 41.33 23.36C41.54 23.27 41.78 23.23 42.01 23.23C42.24 23.23 42.47 23.27 42.69 23.36C42.9 23.45 43.1 23.58 43.26 23.74C43.43 23.91 43.56 24.1 43.65 24.32C43.74 24.53 43.78 24.76 43.78 25C43.76 25.22 43.7 25.44 43.6 25.65C43.5 25.85 43.37 26.04 43.21 26.2C43.05 26.36 42.87 26.49 42.66 26.59C42.46 26.68 42.24 26.75 42.01 26.77Z" />
		<path  className="shp0" d="M42.02 38.08C41.79 38.08 41.56 38.04 41.34 37.95C41.13 37.86 40.93 37.73 40.77 37.57C40.6 37.4 40.47 37.21 40.38 36.99C40.29 36.78 40.25 36.55 40.25 36.31C40.25 36.08 40.29 35.85 40.38 35.63C40.47 35.42 40.6 35.22 40.76 35.06C40.93 34.89 41.12 34.76 41.34 34.68C41.55 34.59 41.79 34.54 42.02 34.54C42.25 34.54 42.48 34.59 42.7 34.67C42.91 34.76 43.11 34.89 43.27 35.06C43.44 35.22 43.57 35.42 43.66 35.63C43.74 35.85 43.79 36.08 43.79 36.31C43.79 36.54 43.75 36.77 43.66 36.99C43.57 37.21 43.44 37.4 43.28 37.57C43.11 37.73 42.92 37.86 42.7 37.95C42.48 38.04 42.25 38.08 42.02 38.08Z" />
		<path  className="shp0" d="M41.96 49.46C41.73 49.47 41.5 49.42 41.28 49.34C41.07 49.25 40.87 49.12 40.7 48.95C40.54 48.79 40.41 48.59 40.32 48.38C40.23 48.16 40.19 47.93 40.19 47.7C40.19 47.47 40.23 47.23 40.32 47.02C40.41 46.8 40.54 46.61 40.7 46.44C40.87 46.28 41.06 46.15 41.28 46.06C41.49 45.97 41.72 45.93 41.96 45.93C42.19 45.93 42.42 45.97 42.64 46.06C42.85 46.15 43.05 46.28 43.21 46.44C43.38 46.61 43.51 46.8 43.6 47.02C43.68 47.23 43.73 47.46 43.73 47.7C43.73 47.93 43.68 48.16 43.6 48.37C43.51 48.59 43.38 48.79 43.21 48.95C43.05 49.12 42.85 49.25 42.64 49.33C42.42 49.42 42.19 49.47 41.96 49.46Z" />
		<path  className="shp0" d="M42.04 60.85C41.81 60.85 41.58 60.81 41.36 60.72C41.15 60.63 40.95 60.5 40.78 60.34C40.62 60.17 40.49 59.98 40.4 59.76C40.31 59.55 40.27 59.32 40.27 59.08C40.27 58.85 40.31 58.62 40.4 58.4C40.49 58.19 40.62 57.99 40.78 57.83C40.95 57.66 41.14 57.53 41.36 57.44C41.57 57.36 41.8 57.31 42.04 57.31C42.27 57.31 42.5 57.36 42.72 57.44C42.93 57.53 43.13 57.66 43.29 57.83C43.46 57.99 43.59 58.19 43.68 58.4C43.76 58.62 43.81 58.85 43.81 59.08C43.81 59.31 43.77 59.54 43.68 59.76C43.59 59.97 43.46 60.17 43.29 60.34C43.13 60.5 42.93 60.63 42.72 60.72C42.5 60.81 42.27 60.85 42.04 60.85Z" />
		<path  className="shp0" d="M42.05 72.16C41.82 72.17 41.59 72.12 41.37 72.03C41.16 71.95 40.96 71.82 40.79 71.65C40.63 71.49 40.5 71.29 40.41 71.08C40.32 70.86 40.28 70.63 40.28 70.4C40.28 70.16 40.32 69.93 40.41 69.72C40.5 69.5 40.63 69.31 40.79 69.14C40.96 68.98 41.15 68.85 41.37 68.76C41.58 68.67 41.81 68.63 42.05 68.63C42.28 68.62 42.51 68.67 42.73 68.76C42.94 68.84 43.14 68.97 43.3 69.14C43.47 69.3 43.6 69.5 43.69 69.71C43.77 69.93 43.82 70.16 43.82 70.39C43.82 70.63 43.77 70.86 43.69 71.07C43.6 71.29 43.47 71.48 43.3 71.65C43.14 71.81 42.94 71.94 42.73 72.03C42.51 72.12 42.28 72.16 42.05 72.16Z" />
		<path  className="shp0" d="M42.06 83.62C41.83 83.62 41.6 83.58 41.38 83.49C41.16 83.4 40.97 83.27 40.8 83.11C40.64 82.94 40.51 82.75 40.42 82.53C40.33 82.32 40.29 82.08 40.29 81.85C40.29 81.62 40.33 81.39 40.42 81.17C40.51 80.96 40.64 80.76 40.8 80.6C40.97 80.43 41.16 80.3 41.38 80.21C41.59 80.12 41.82 80.08 42.06 80.08C42.29 80.08 42.52 80.12 42.74 80.21C42.95 80.3 43.15 80.43 43.31 80.59C43.48 80.76 43.61 80.95 43.7 81.17C43.78 81.38 43.83 81.62 43.83 81.85C43.83 82.08 43.78 82.31 43.7 82.53C43.61 82.74 43.48 82.94 43.31 83.1C43.15 83.27 42.95 83.4 42.74 83.49C42.52 83.58 42.29 83.62 42.06 83.62L42.06 83.62Z" />
		<path  className="shp0" d="M42.07 94.93C41.84 94.93 41.61 94.89 41.39 94.8C41.17 94.71 40.98 94.58 40.81 94.42C40.65 94.26 40.52 94.06 40.43 93.84C40.34 93.63 40.3 93.4 40.3 93.17C40.3 92.93 40.34 92.7 40.43 92.49C40.52 92.27 40.65 92.07 40.81 91.91C40.98 91.75 41.17 91.61 41.39 91.53C41.6 91.44 41.83 91.39 42.07 91.4C42.3 91.39 42.53 91.44 42.75 91.53C42.96 91.61 43.16 91.74 43.32 91.91C43.49 92.07 43.62 92.27 43.71 92.48C43.79 92.7 43.84 92.93 43.84 93.16C43.84 93.4 43.79 93.63 43.71 93.84C43.62 94.06 43.49 94.25 43.32 94.42C43.16 94.58 42.96 94.71 42.75 94.8C42.53 94.89 42.3 94.93 42.07 94.93Z" />
		<path  className="shp0" d="M42.08 106.39C41.85 106.39 41.62 106.35 41.4 106.26C41.18 106.17 40.99 106.04 40.82 105.87C40.66 105.71 40.53 105.51 40.44 105.3C40.35 105.08 40.31 104.85 40.31 104.62C40.31 104.39 40.35 104.16 40.44 103.94C40.53 103.73 40.66 103.53 40.82 103.36C40.99 103.2 41.18 103.07 41.4 102.98C41.61 102.89 41.84 102.85 42.08 102.85C42.31 102.85 42.54 102.89 42.76 102.98C42.97 103.07 43.17 103.2 43.33 103.36C43.5 103.53 43.63 103.72 43.71 103.94C43.8 104.15 43.85 104.38 43.85 104.62C43.85 104.85 43.8 105.08 43.72 105.3C43.63 105.51 43.5 105.71 43.33 105.87C43.17 106.04 42.97 106.17 42.76 106.26C42.54 106.34 42.31 106.39 42.08 106.39Z" />
		<path  className="shp0" d="M42.09 117.7C41.86 117.7 41.63 117.66 41.41 117.57C41.19 117.48 41 117.35 40.83 117.19C40.67 117.02 40.54 116.83 40.45 116.61C40.36 116.4 40.32 116.17 40.32 115.93C40.32 115.7 40.36 115.47 40.45 115.25C40.54 115.04 40.67 114.84 40.83 114.68C41 114.51 41.19 114.38 41.41 114.3C41.62 114.21 41.85 114.16 42.09 114.16C42.32 114.16 42.55 114.21 42.77 114.29C42.98 114.38 43.18 114.51 43.34 114.68C43.51 114.84 43.64 115.04 43.72 115.25C43.81 115.47 43.86 115.7 43.86 115.93C43.86 116.16 43.81 116.39 43.73 116.61C43.64 116.83 43.51 117.02 43.34 117.19C43.18 117.35 42.98 117.48 42.77 117.57C42.55 117.66 42.32 117.7 42.09 117.7Z" />
		<path  className="shp0" d="M42.03 129.08C41.8 129.09 41.56 129.04 41.35 128.96C41.13 128.87 40.94 128.74 40.77 128.57C40.61 128.41 40.48 128.21 40.39 128C40.3 127.78 40.26 127.55 40.26 127.32C40.26 127.09 40.3 126.85 40.39 126.64C40.48 126.42 40.61 126.23 40.77 126.06C40.93 125.9 41.13 125.77 41.35 125.68C41.56 125.59 41.79 125.55 42.02 125.55C42.26 125.55 42.49 125.59 42.7 125.68C42.92 125.77 43.12 125.9 43.28 126.06C43.45 126.23 43.58 126.42 43.66 126.64C43.75 126.85 43.8 127.08 43.79 127.32C43.8 127.55 43.75 127.78 43.67 127.99C43.58 128.21 43.45 128.41 43.28 128.57C43.12 128.74 42.92 128.87 42.71 128.95C42.49 129.04 42.26 129.09 42.03 129.08Z" />
		<path  className="shp0" d="M34 3.93C33.77 3.94 33.54 3.89 33.32 3.8C33.11 3.72 32.91 3.59 32.75 3.42C32.58 3.26 32.45 3.06 32.36 2.85C32.27 2.63 32.23 2.4 32.23 2.17C32.23 1.93 32.27 1.7 32.36 1.49C32.45 1.27 32.58 1.08 32.74 0.91C32.91 0.75 33.1 0.62 33.32 0.53C33.53 0.44 33.77 0.4 34 0.4C34.23 0.4 34.46 0.44 34.68 0.53C34.89 0.62 35.09 0.75 35.25 0.91C35.42 1.07 35.55 1.27 35.64 1.49C35.73 1.7 35.77 1.93 35.77 2.16C35.77 2.4 35.73 2.63 35.64 2.84C35.55 3.06 35.42 3.26 35.26 3.42C35.09 3.58 34.9 3.71 34.68 3.8C34.47 3.89 34.23 3.94 34 3.93Z" />
		<path  className="shp0" d="M34.08 15.32C33.85 15.32 33.62 15.28 33.4 15.19C33.19 15.1 32.99 14.97 32.83 14.81C32.66 14.64 32.53 14.45 32.44 14.23C32.35 14.02 32.31 13.78 32.31 13.55C32.31 13.32 32.35 13.09 32.44 12.87C32.53 12.66 32.66 12.46 32.82 12.3C32.99 12.13 33.18 12 33.4 11.91C33.62 11.82 33.85 11.78 34.08 11.78C34.31 11.78 34.54 11.82 34.76 11.91C34.97 12 35.17 12.13 35.33 12.29C35.5 12.46 35.63 12.65 35.72 12.87C35.81 13.09 35.85 13.32 35.85 13.55C35.85 13.78 35.81 14.01 35.72 14.23C35.63 14.44 35.5 14.64 35.34 14.8C35.17 14.97 34.98 15.1 34.76 15.19C34.55 15.28 34.31 15.32 34.08 15.32Z" />
		<path  className="shp0" d="M34.02 26.7C33.79 26.71 33.56 26.66 33.34 26.57C33.13 26.49 32.93 26.36 32.77 26.19C32.6 26.03 32.47 25.83 32.38 25.62C32.29 25.4 32.25 25.17 32.25 24.94C32.25 24.7 32.29 24.47 32.38 24.26C32.47 24.04 32.6 23.85 32.76 23.68C32.93 23.52 33.12 23.39 33.34 23.3C33.55 23.21 33.79 23.16 34.02 23.17C34.25 23.16 34.48 23.21 34.7 23.3C34.91 23.38 35.11 23.51 35.27 23.68C35.44 23.84 35.57 24.04 35.66 24.25C35.74 24.47 35.79 24.7 35.79 24.93C35.79 25.17 35.75 25.4 35.66 25.61C35.57 25.83 35.44 26.02 35.28 26.19C35.11 26.35 34.92 26.48 34.7 26.57C34.48 26.66 34.25 26.7 34.02 26.7Z" />
		<path  className="shp0" d="M32.76 37.59C32.05 36.89 32.05 35.75 32.76 35.05C33.46 34.34 34.6 34.34 35.3 35.05C36.01 35.75 36.01 36.89 35.3 37.59C34.6 38.3 33.46 38.3 32.76 37.59Z" />
		<path  className="shp0" d="M32.77 48.91C32.06 48.2 32.06 47.07 32.76 46.36C33.47 45.66 34.61 45.66 35.31 46.36C36.01 47.06 36.02 48.2 35.31 48.9C34.61 49.61 33.47 49.61 32.77 48.91Z" />
		<path  className="shp0" d="M34.12 60.86C33.89 60.86 33.66 60.81 33.44 60.73C33.23 60.64 33.03 60.51 32.87 60.34C32.7 60.18 32.57 59.98 32.48 59.77C32.39 59.55 32.35 59.32 32.35 59.09C32.35 58.86 32.39 58.63 32.48 58.41C32.57 58.19 32.7 58 32.86 57.83C33.03 57.67 33.22 57.54 33.44 57.45C33.65 57.36 33.88 57.32 34.12 57.32C34.35 57.32 34.58 57.36 34.8 57.45C35.01 57.54 35.21 57.67 35.37 57.83C35.54 58 35.67 58.19 35.76 58.41C35.84 58.62 35.89 58.85 35.89 59.09C35.89 59.32 35.85 59.55 35.76 59.77C35.67 59.98 35.54 60.18 35.38 60.34C35.21 60.51 35.02 60.64 34.8 60.73C34.58 60.81 34.35 60.86 34.12 60.86Z" />
		<path  className="shp0" d="M34.06 72.24C33.83 72.24 33.6 72.2 33.38 72.11C33.16 72.02 32.97 71.89 32.8 71.73C32.64 71.56 32.51 71.37 32.42 71.15C32.33 70.94 32.29 70.71 32.29 70.47C32.29 70.24 32.33 70.01 32.42 69.79C32.51 69.58 32.64 69.38 32.8 69.22C32.97 69.05 33.16 68.92 33.38 68.84C33.59 68.75 33.82 68.7 34.06 68.7C34.29 68.7 34.52 68.75 34.74 68.83C34.95 68.92 35.15 69.05 35.31 69.22C35.48 69.38 35.61 69.58 35.7 69.79C35.78 70.01 35.83 70.24 35.83 70.47C35.83 70.7 35.78 70.93 35.7 71.15C35.61 71.37 35.48 71.56 35.31 71.73C35.15 71.89 34.95 72.02 34.74 72.11C34.52 72.2 34.29 72.24 34.06 72.24L34.06 72.24Z" />
		<path  className="shp0" d="M34.07 83.55C33.84 83.56 33.61 83.51 33.39 83.42C33.17 83.34 32.98 83.21 32.81 83.04C32.65 82.88 32.52 82.68 32.43 82.47C32.34 82.25 32.3 82.02 32.3 81.79C32.3 81.55 32.34 81.32 32.43 81.11C32.52 80.89 32.65 80.7 32.81 80.53C32.98 80.37 33.17 80.24 33.39 80.15C33.6 80.06 33.83 80.02 34.07 80.02C34.3 80.02 34.53 80.06 34.75 80.15C34.96 80.24 35.16 80.37 35.32 80.53C35.49 80.69 35.62 80.89 35.71 81.11C35.79 81.32 35.84 81.55 35.84 81.78C35.84 82.02 35.79 82.25 35.71 82.46C35.62 82.68 35.49 82.88 35.32 83.04C35.16 83.2 34.96 83.34 34.75 83.42C34.53 83.51 34.3 83.56 34.07 83.55Z" />
		<path  className="shp0" d="M34.08 95.01C33.85 95.01 33.62 94.97 33.4 94.88C33.18 94.79 32.99 94.66 32.82 94.5C32.66 94.33 32.53 94.14 32.44 93.92C32.35 93.71 32.31 93.48 32.31 93.24C32.31 93.01 32.35 92.78 32.44 92.56C32.53 92.35 32.66 92.15 32.82 91.99C32.99 91.82 33.18 91.69 33.4 91.6C33.61 91.52 33.84 91.47 34.08 91.47C34.31 91.47 34.54 91.52 34.76 91.6C34.97 91.69 35.17 91.82 35.33 91.99C35.5 92.15 35.63 92.35 35.71 92.56C35.8 92.78 35.85 93.01 35.85 93.24C35.85 93.47 35.8 93.7 35.72 93.92C35.63 94.13 35.5 94.33 35.33 94.5C35.17 94.66 34.97 94.79 34.76 94.88C34.54 94.97 34.31 95.01 34.08 95.01Z" />
		<path  className="shp0" d="M32.89 105.83C32.18 105.13 32.18 103.99 32.88 103.28C33.59 102.58 34.73 102.58 35.43 103.28C36.13 103.98 36.14 105.12 35.43 105.83C34.73 106.53 33.59 106.53 32.89 105.83Z" />
		<path  className="shp0" d="M34.17 117.71C33.94 117.71 33.71 117.67 33.49 117.58C33.27 117.49 33.08 117.36 32.91 117.2C32.75 117.03 32.62 116.84 32.53 116.62C32.44 116.4 32.4 116.17 32.4 115.94C32.4 115.71 32.44 115.48 32.53 115.26C32.62 115.05 32.75 114.85 32.91 114.69C33.08 114.52 33.27 114.39 33.49 114.3C33.7 114.21 33.93 114.17 34.17 114.17C34.4 114.17 34.63 114.21 34.85 114.3C35.06 114.39 35.26 114.52 35.42 114.68C35.59 114.85 35.72 115.04 35.81 115.26C35.89 115.47 35.94 115.71 35.94 115.94C35.94 116.17 35.89 116.4 35.81 116.62C35.72 116.83 35.59 117.03 35.42 117.19C35.26 117.36 35.06 117.49 34.85 117.58C34.63 117.66 34.4 117.71 34.17 117.71Z" />
		<path  className="shp0" d="M34.11 129.09C33.88 129.09 33.64 129.05 33.43 128.96C33.21 128.87 33.02 128.74 32.85 128.58C32.69 128.42 32.56 128.22 32.47 128C32.38 127.79 32.34 127.56 32.34 127.33C32.34 127.09 32.38 126.86 32.47 126.65C32.56 126.43 32.69 126.23 32.85 126.07C33.02 125.91 33.21 125.77 33.43 125.69C33.64 125.6 33.87 125.55 34.11 125.56C34.34 125.55 34.57 125.6 34.78 125.69C35 125.77 35.2 125.9 35.36 126.07C35.53 126.23 35.66 126.43 35.74 126.64C35.83 126.86 35.88 127.09 35.87 127.32C35.88 127.56 35.83 127.79 35.75 128C35.66 128.22 35.53 128.41 35.36 128.58C35.2 128.74 35 128.87 34.79 128.96C34.57 129.05 34.34 129.09 34.11 129.09Z" />
		<path  className="shp0" d="M26.08 3.94C25.85 3.94 25.62 3.9 25.4 3.81C25.19 3.72 24.99 3.59 24.83 3.43C24.66 3.26 24.53 3.07 24.44 2.85C24.35 2.64 24.31 2.41 24.31 2.17C24.31 1.94 24.35 1.71 24.44 1.5C24.53 1.28 24.66 1.08 24.82 0.92C24.99 0.75 25.18 0.62 25.4 0.54C25.62 0.45 25.85 0.4 26.08 0.4C26.31 0.4 26.54 0.45 26.76 0.53C26.97 0.62 27.17 0.75 27.33 0.92C27.5 1.08 27.63 1.28 27.72 1.49C27.81 1.71 27.85 1.94 27.85 2.17C27.85 2.4 27.81 2.63 27.72 2.85C27.63 3.07 27.5 3.26 27.34 3.43C27.17 3.59 26.98 3.72 26.76 3.81C26.55 3.9 26.31 3.94 26.08 3.94Z" />
		<path  className="shp0" d="M26.02 15.32C25.79 15.33 25.56 15.28 25.34 15.2C25.13 15.11 24.93 14.98 24.77 14.81C24.6 14.65 24.47 14.45 24.38 14.24C24.29 14.02 24.25 13.79 24.25 13.56C24.25 13.33 24.29 13.1 24.38 12.88C24.47 12.66 24.6 12.47 24.76 12.3C24.93 12.14 25.12 12.01 25.34 11.92C25.55 11.83 25.79 11.79 26.02 11.79C26.25 11.79 26.48 11.83 26.7 11.92C26.91 12.01 27.11 12.14 27.27 12.3C27.44 12.47 27.57 12.66 27.66 12.88C27.74 13.09 27.79 13.32 27.79 13.56C27.79 13.79 27.75 14.02 27.66 14.23C27.57 14.45 27.44 14.65 27.28 14.81C27.11 14.98 26.92 15.11 26.7 15.19C26.48 15.28 26.25 15.33 26.02 15.32Z" />
		<path  className="shp0" d="M26.1 26.71C25.87 26.71 25.64 26.67 25.42 26.58C25.21 26.49 25.01 26.36 24.85 26.2C24.68 26.03 24.55 25.84 24.46 25.62C24.37 25.41 24.33 25.18 24.33 24.94C24.33 24.71 24.37 24.48 24.46 24.26C24.55 24.05 24.68 23.85 24.84 23.69C25.01 23.52 25.2 23.39 25.42 23.3C25.63 23.22 25.87 23.17 26.1 23.17C26.33 23.17 26.56 23.22 26.78 23.3C26.99 23.39 27.19 23.52 27.35 23.69C27.52 23.85 27.65 24.05 27.74 24.26C27.83 24.48 27.87 24.71 27.87 24.94C27.87 25.17 27.83 25.4 27.74 25.62C27.65 25.83 27.52 26.03 27.36 26.2C27.19 26.36 27 26.49 26.78 26.58C26.56 26.67 26.33 26.71 26.1 26.71Z" />
		<path  className="shp0" d="M26.04 38.09C25.81 38.1 25.58 38.05 25.36 37.96C25.15 37.88 24.95 37.75 24.78 37.58C24.62 37.42 24.49 37.22 24.4 37.01C24.31 36.79 24.27 36.56 24.27 36.33C24.27 36.09 24.31 35.86 24.4 35.65C24.49 35.43 24.62 35.24 24.78 35.07C24.95 34.91 25.14 34.78 25.36 34.69C25.57 34.6 25.8 34.56 26.04 34.56C26.27 34.56 26.5 34.6 26.72 34.69C26.93 34.78 27.13 34.91 27.29 35.07C27.46 35.23 27.59 35.43 27.68 35.65C27.76 35.86 27.81 36.09 27.81 36.32C27.81 36.56 27.77 36.79 27.68 37C27.59 37.22 27.46 37.42 27.3 37.58C27.13 37.75 26.93 37.88 26.72 37.96C26.5 38.05 26.27 38.1 26.04 38.09Z" />
		<path  className="shp0" d="M26.12 49.48C25.89 49.48 25.66 49.44 25.44 49.35C25.23 49.26 25.03 49.13 24.87 48.97C24.7 48.8 24.57 48.61 24.48 48.39C24.39 48.18 24.35 47.94 24.35 47.71C24.35 47.48 24.39 47.25 24.48 47.03C24.57 46.82 24.7 46.62 24.86 46.46C25.03 46.29 25.22 46.16 25.44 46.07C25.65 45.98 25.88 45.94 26.12 45.94C26.35 45.94 26.58 45.98 26.8 46.07C27.01 46.16 27.21 46.29 27.37 46.45C27.54 46.62 27.67 46.81 27.76 47.03C27.84 47.25 27.89 47.48 27.89 47.71C27.89 47.94 27.85 48.17 27.76 48.39C27.67 48.6 27.54 48.8 27.38 48.96C27.21 49.13 27.02 49.26 26.8 49.35C26.58 49.44 26.35 49.48 26.12 49.48Z" />
		<path  className="shp0" d="M26.06 60.86C25.83 60.87 25.6 60.82 25.38 60.73C25.16 60.65 24.97 60.52 24.8 60.35C24.64 60.19 24.51 59.99 24.42 59.78C24.33 59.56 24.29 59.33 24.29 59.1C24.29 58.86 24.33 58.63 24.42 58.42C24.51 58.2 24.64 58.01 24.8 57.84C24.97 57.68 25.16 57.55 25.38 57.46C25.59 57.37 25.82 57.33 26.06 57.33C26.29 57.32 26.52 57.37 26.74 57.46C26.95 57.54 27.15 57.67 27.31 57.84C27.48 58 27.61 58.2 27.7 58.41C27.78 58.63 27.83 58.86 27.83 59.09C27.83 59.33 27.78 59.56 27.7 59.77C27.61 59.99 27.48 60.18 27.31 60.35C27.15 60.51 26.95 60.64 26.74 60.73C26.52 60.82 26.29 60.86 26.06 60.86Z" />
		<path  className="shp0" d="M26.14 72.25C25.91 72.25 25.68 72.21 25.46 72.12C25.25 72.03 25.05 71.9 24.88 71.74C24.72 71.57 24.59 71.38 24.5 71.16C24.41 70.94 24.37 70.71 24.37 70.48C24.37 70.25 24.41 70.02 24.5 69.8C24.59 69.59 24.72 69.39 24.88 69.23C25.05 69.06 25.24 68.93 25.46 68.84C25.67 68.75 25.9 68.71 26.14 68.71C26.37 68.71 26.6 68.75 26.82 68.84C27.03 68.93 27.23 69.06 27.39 69.22C27.56 69.39 27.69 69.58 27.78 69.8C27.86 70.01 27.91 70.24 27.91 70.48C27.91 70.71 27.86 70.94 27.78 71.16C27.69 71.37 27.56 71.57 27.39 71.73C27.23 71.9 27.03 72.03 26.82 72.12C26.6 72.2 26.37 72.25 26.14 72.25Z" />
		<path  className="shp0" d="M26.15 83.56C25.92 83.56 25.69 83.52 25.47 83.43C25.26 83.34 25.06 83.21 24.89 83.05C24.73 82.88 24.6 82.69 24.51 82.47C24.42 82.26 24.38 82.03 24.38 81.79C24.38 81.56 24.42 81.33 24.51 81.12C24.6 80.9 24.73 80.7 24.89 80.54C25.06 80.37 25.25 80.24 25.47 80.16C25.68 80.07 25.91 80.02 26.15 80.03C26.38 80.02 26.61 80.07 26.83 80.15C27.04 80.24 27.24 80.37 27.4 80.54C27.57 80.7 27.7 80.9 27.79 81.11C27.87 81.33 27.92 81.56 27.92 81.79C27.92 82.02 27.87 82.26 27.79 82.47C27.7 82.69 27.57 82.88 27.4 83.05C27.24 83.21 27.04 83.34 26.83 83.43C26.61 83.52 26.38 83.56 26.15 83.56Z" />
		<path  className="shp0" d="M26.16 95.02C25.93 95.02 25.7 94.97 25.48 94.89C25.26 94.8 25.07 94.67 24.9 94.5C24.74 94.34 24.61 94.14 24.52 93.93C24.43 93.71 24.39 93.48 24.39 93.25C24.39 93.02 24.43 92.79 24.52 92.57C24.61 92.35 24.74 92.16 24.9 91.99C25.07 91.83 25.26 91.7 25.48 91.61C25.69 91.52 25.92 91.48 26.16 91.48C26.39 91.48 26.62 91.52 26.84 91.61C27.05 91.7 27.25 91.83 27.41 91.99C27.58 92.16 27.71 92.35 27.8 92.57C27.88 92.78 27.93 93.01 27.93 93.25C27.93 93.48 27.88 93.71 27.8 93.93C27.71 94.14 27.58 94.34 27.41 94.5C27.25 94.67 27.05 94.8 26.84 94.89C26.62 94.97 26.39 95.02 26.16 95.02Z" />
		<path  className="shp0" d="M26.17 106.33C25.94 106.33 25.71 106.29 25.49 106.2C25.27 106.11 25.08 105.98 24.91 105.82C24.75 105.65 24.62 105.46 24.53 105.24C24.44 105.03 24.4 104.8 24.4 104.56C24.4 104.33 24.44 104.1 24.53 103.88C24.62 103.67 24.75 103.47 24.91 103.31C25.08 103.14 25.27 103.01 25.49 102.92C25.7 102.84 25.93 102.79 26.17 102.79C26.4 102.79 26.63 102.84 26.85 102.92C27.06 103.01 27.26 103.14 27.42 103.31C27.59 103.47 27.72 103.67 27.8 103.88C27.89 104.1 27.94 104.33 27.94 104.56C27.94 104.79 27.89 105.02 27.81 105.24C27.72 105.46 27.59 105.65 27.42 105.82C27.26 105.98 27.06 106.11 26.85 106.2C26.63 106.29 26.4 106.33 26.17 106.33Z" />
		<path  className="shp0" d="M26.11 117.71C25.88 117.72 25.64 117.67 25.43 117.58C25.21 117.5 25.02 117.37 24.85 117.2C24.69 117.04 24.56 116.84 24.47 116.63C24.38 116.41 24.34 116.18 24.34 115.95C24.34 115.71 24.38 115.48 24.47 115.27C24.56 115.05 24.69 114.86 24.85 114.69C25.02 114.53 25.21 114.4 25.43 114.31C25.64 114.22 25.87 114.18 26.11 114.18C26.34 114.18 26.57 114.22 26.78 114.31C27 114.4 27.2 114.53 27.36 114.69C27.53 114.85 27.66 115.05 27.74 115.27C27.83 115.48 27.88 115.71 27.87 115.94C27.88 116.18 27.83 116.41 27.75 116.62C27.66 116.84 27.53 117.04 27.36 117.2C27.2 117.37 27 117.5 26.79 117.58C26.57 117.67 26.34 117.72 26.11 117.71Z" />
		<path  className="shp0" d="M26.19 129.1C25.96 129.1 25.73 129.06 25.51 128.97C25.29 128.88 25.1 128.75 24.93 128.59C24.77 128.42 24.64 128.23 24.55 128.01C24.46 127.8 24.42 127.56 24.42 127.33C24.42 127.1 24.46 126.87 24.55 126.65C24.64 126.44 24.77 126.24 24.93 126.08C25.1 125.91 25.29 125.78 25.51 125.69C25.72 125.61 25.95 125.56 26.19 125.56C26.42 125.56 26.65 125.6 26.86 125.69C27.08 125.78 27.28 125.91 27.44 126.07C27.61 126.24 27.74 126.43 27.82 126.65C27.91 126.87 27.96 127.1 27.96 127.33C27.96 127.56 27.91 127.79 27.83 128.01C27.74 128.22 27.61 128.42 27.44 128.58C27.28 128.75 27.08 128.88 26.87 128.97C26.65 129.06 26.42 129.1 26.19 129.1Z" />
		<path  className="shp0" d="M18.09 4.02C17.86 4.02 17.63 3.98 17.41 3.89C17.2 3.8 17 3.67 16.84 3.51C16.67 3.34 16.54 3.15 16.45 2.93C16.36 2.72 16.32 2.48 16.32 2.25C16.32 2.02 16.36 1.79 16.45 1.57C16.54 1.36 16.67 1.16 16.83 1C17 0.83 17.19 0.7 17.41 0.61C17.62 0.52 17.86 0.48 18.09 0.48C18.32 0.48 18.55 0.52 18.77 0.61C18.98 0.7 19.18 0.83 19.34 0.99C19.51 1.16 19.64 1.35 19.73 1.57C19.82 1.79 19.86 2.02 19.86 2.25C19.86 2.48 19.82 2.71 19.73 2.93C19.64 3.14 19.51 3.34 19.35 3.5C19.18 3.67 18.99 3.8 18.77 3.89C18.56 3.98 18.32 4.02 18.09 4.02Z" />
		<path  className="shp0" d="M16.83 14.77C16.12 14.06 16.12 12.93 16.83 12.22C17.53 11.52 18.67 11.52 19.37 12.22C20.08 12.92 20.08 14.06 19.37 14.76C18.67 15.47 17.53 15.47 16.83 14.77Z" />
		<path  className="shp0" d="M18.11 26.79C17.88 26.79 17.65 26.75 17.43 26.66C17.22 26.57 17.02 26.44 16.86 26.28C16.69 26.11 16.56 25.92 16.47 25.7C16.38 25.48 16.34 25.25 16.34 25.02C16.34 24.79 16.38 24.56 16.47 24.34C16.56 24.13 16.69 23.93 16.85 23.77C17.02 23.6 17.21 23.47 17.43 23.38C17.64 23.29 17.88 23.25 18.11 23.25C18.34 23.25 18.57 23.29 18.79 23.38C19 23.47 19.2 23.6 19.36 23.76C19.53 23.93 19.66 24.12 19.75 24.34C19.83 24.55 19.88 24.78 19.88 25.02C19.88 25.25 19.84 25.48 19.75 25.7C19.66 25.91 19.53 26.11 19.37 26.27C19.2 26.44 19.01 26.57 18.79 26.66C18.57 26.74 18.34 26.79 18.11 26.79Z" />
		<path  className="shp0" d="M18.12 38.1C17.89 38.1 17.66 38.06 17.44 37.97C17.23 37.88 17.03 37.75 16.87 37.59C16.7 37.42 16.57 37.23 16.48 37.01C16.39 36.8 16.35 36.57 16.35 36.33C16.35 36.1 16.39 35.87 16.48 35.66C16.57 35.44 16.7 35.24 16.86 35.08C17.03 34.91 17.22 34.78 17.44 34.7C17.65 34.61 17.88 34.56 18.12 34.57C18.35 34.56 18.58 34.61 18.8 34.69C19.01 34.78 19.21 34.91 19.37 35.08C19.54 35.24 19.67 35.44 19.76 35.65C19.84 35.87 19.89 36.1 19.89 36.33C19.89 36.56 19.85 36.79 19.76 37.01C19.67 37.23 19.54 37.42 19.38 37.59C19.21 37.75 19.02 37.88 18.8 37.97C18.58 38.06 18.35 38.1 18.12 38.1Z" />
		<path  className="shp0" d="M18.13 49.41C17.9 49.42 17.67 49.37 17.45 49.28C17.24 49.2 17.04 49.07 16.87 48.9C16.71 48.74 16.58 48.54 16.49 48.33C16.4 48.11 16.36 47.88 16.36 47.65C16.36 47.42 16.4 47.18 16.49 46.97C16.58 46.75 16.71 46.56 16.87 46.39C17.04 46.23 17.23 46.1 17.45 46.01C17.66 45.92 17.89 45.88 18.13 45.88C18.36 45.88 18.59 45.92 18.81 46.01C19.02 46.1 19.22 46.23 19.38 46.39C19.55 46.55 19.68 46.75 19.77 46.97C19.85 47.18 19.9 47.41 19.9 47.65C19.9 47.88 19.86 48.11 19.77 48.32C19.68 48.54 19.55 48.74 19.38 48.9C19.22 49.07 19.02 49.2 18.81 49.28C18.59 49.37 18.36 49.42 18.13 49.41L18.13 49.41Z" />
		<path  className="shp0" d="M18.14 60.87C17.91 60.87 17.68 60.83 17.46 60.74C17.25 60.65 17.05 60.52 16.88 60.36C16.72 60.19 16.59 60 16.5 59.78C16.41 59.57 16.37 59.34 16.37 59.1C16.37 58.87 16.41 58.64 16.5 58.42C16.59 58.21 16.72 58.01 16.88 57.85C17.05 57.68 17.24 57.55 17.46 57.46C17.67 57.38 17.9 57.33 18.14 57.33C18.37 57.33 18.6 57.38 18.82 57.46C19.03 57.55 19.23 57.68 19.39 57.85C19.56 58.01 19.69 58.21 19.78 58.42C19.86 58.64 19.91 58.87 19.91 59.1C19.91 59.33 19.86 59.56 19.78 59.78C19.69 59.99 19.56 60.19 19.39 60.36C19.23 60.52 19.03 60.65 18.82 60.74C18.6 60.83 18.37 60.87 18.14 60.87L18.14 60.87Z" />
		<path  className="shp0" d="M16.88 71.76C16.17 71.06 16.17 69.92 16.87 69.21C17.58 68.51 18.72 68.51 19.42 69.21C20.12 69.92 20.13 71.05 19.42 71.76C18.72 72.46 17.58 72.46 16.88 71.76Z" />
		<path  className="shp0" d="M16.89 83.07C16.18 82.37 16.18 81.23 16.88 80.53C17.59 79.82 18.73 79.82 19.43 80.53C20.13 81.23 20.14 82.37 19.43 83.07C18.73 83.78 17.59 83.78 16.89 83.07Z" />
		<path  className="shp0" d="M18.17 94.95C17.94 94.95 17.71 94.91 17.49 94.82C17.27 94.73 17.08 94.6 16.91 94.44C16.75 94.28 16.62 94.08 16.53 93.86C16.44 93.65 16.4 93.42 16.4 93.19C16.4 92.95 16.44 92.72 16.53 92.51C16.62 92.29 16.75 92.1 16.91 91.93C17.08 91.77 17.27 91.64 17.49 91.55C17.7 91.46 17.93 91.41 18.17 91.42C18.4 91.41 18.63 91.46 18.85 91.55C19.06 91.63 19.26 91.76 19.42 91.93C19.59 92.09 19.72 92.29 19.8 92.5C19.89 92.72 19.94 92.95 19.94 93.18C19.94 93.42 19.89 93.65 19.81 93.86C19.72 94.08 19.59 94.27 19.42 94.44C19.26 94.6 19.06 94.73 18.85 94.82C18.63 94.91 18.4 94.95 18.17 94.95Z" />
		<path  className="shp0" d="M18.18 106.41C17.95 106.41 17.72 106.37 17.5 106.28C17.28 106.19 17.09 106.06 16.92 105.9C16.76 105.73 16.63 105.54 16.54 105.32C16.45 105.1 16.41 104.87 16.41 104.64C16.41 104.41 16.45 104.18 16.54 103.96C16.63 103.75 16.76 103.55 16.92 103.39C17.09 103.22 17.28 103.09 17.5 103C17.71 102.91 17.94 102.87 18.18 102.87C18.41 102.87 18.64 102.91 18.86 103C19.07 103.09 19.27 103.22 19.43 103.38C19.6 103.55 19.73 103.74 19.81 103.96C19.9 104.17 19.95 104.41 19.95 104.64C19.95 104.87 19.9 105.1 19.82 105.32C19.73 105.53 19.6 105.73 19.43 105.89C19.27 106.06 19.07 106.19 18.86 106.28C18.64 106.36 18.41 106.41 18.18 106.41Z" />
		<path  className="shp0" d="M18.19 117.72C17.96 117.72 17.73 117.68 17.51 117.59C17.29 117.5 17.1 117.37 16.93 117.21C16.77 117.04 16.64 116.85 16.55 116.63C16.46 116.42 16.42 116.19 16.42 115.95C16.42 115.72 16.46 115.49 16.55 115.28C16.64 115.06 16.77 114.86 16.93 114.7C17.1 114.53 17.29 114.4 17.51 114.32C17.72 114.23 17.95 114.18 18.19 114.19C18.42 114.18 18.65 114.23 18.86 114.31C19.08 114.4 19.28 114.53 19.44 114.7C19.61 114.86 19.74 115.06 19.82 115.27C19.91 115.49 19.96 115.72 19.95 115.95C19.96 116.18 19.91 116.42 19.83 116.63C19.74 116.85 19.61 117.04 19.44 117.21C19.28 117.37 19.08 117.5 18.87 117.59C18.65 117.68 18.42 117.72 18.19 117.72Z" />
		<path  className="shp0" d="M16.93 128.61C16.22 127.91 16.22 126.77 16.92 126.07C17.63 125.36 18.76 125.36 19.47 126.06C20.17 126.77 20.17 127.9 19.47 128.61C18.77 129.31 17.63 129.31 16.93 128.61Z" />
		<path  className="shp0" d="M10.1 3.95C9.87 3.96 9.64 3.91 9.42 3.82C9.21 3.74 9.01 3.61 8.85 3.44C8.68 3.28 8.55 3.08 8.46 2.87C8.37 2.65 8.33 2.42 8.33 2.19C8.33 1.96 8.37 1.72 8.46 1.51C8.55 1.29 8.68 1.1 8.84 0.93C9.01 0.77 9.2 0.64 9.42 0.55C9.63 0.46 9.87 0.42 10.1 0.42C10.33 0.42 10.56 0.46 10.78 0.55C10.99 0.64 11.19 0.77 11.35 0.93C11.52 1.09 11.65 1.29 11.74 1.51C11.83 1.72 11.87 1.95 11.87 2.18C11.87 2.42 11.83 2.65 11.74 2.86C11.65 3.08 11.52 3.28 11.36 3.44C11.19 3.61 11 3.74 10.78 3.82C10.56 3.91 10.33 3.96 10.1 3.95Z" />
		<path  className="shp0" d="M10.18 15.34C9.95 15.34 9.72 15.3 9.5 15.21C9.29 15.12 9.09 14.99 8.93 14.83C8.76 14.66 8.63 14.47 8.54 14.25C8.45 14.04 8.41 13.81 8.41 13.57C8.41 13.34 8.45 13.11 8.54 12.89C8.63 12.68 8.76 12.48 8.92 12.32C9.09 12.15 9.28 12.02 9.5 11.93C9.71 11.85 9.95 11.8 10.18 11.8C10.41 11.8 10.64 11.84 10.86 11.93C11.07 12.02 11.27 12.15 11.43 12.31C11.6 12.48 11.73 12.67 11.82 12.89C11.91 13.11 11.95 13.34 11.95 13.57C11.95 13.8 11.91 14.03 11.82 14.25C11.73 14.46 11.6 14.66 11.44 14.82C11.27 14.99 11.08 15.12 10.86 15.21C10.65 15.3 10.41 15.34 10.18 15.34Z" />
		<path  className="shp0" d="M10.12 26.72C9.89 26.73 9.66 26.68 9.44 26.59C9.23 26.51 9.03 26.38 8.87 26.21C8.7 26.05 8.57 25.85 8.48 25.64C8.39 25.42 8.35 25.19 8.35 24.96C8.35 24.72 8.39 24.49 8.48 24.28C8.57 24.06 8.7 23.87 8.86 23.7C9.03 23.54 9.22 23.41 9.44 23.32C9.65 23.23 9.88 23.19 10.12 23.19C10.35 23.19 10.58 23.23 10.8 23.32C11.01 23.4 11.21 23.53 11.37 23.7C11.54 23.86 11.67 24.06 11.76 24.27C11.84 24.49 11.89 24.72 11.89 24.95C11.89 25.19 11.85 25.42 11.76 25.63C11.67 25.85 11.54 26.04 11.38 26.21C11.21 26.37 11.02 26.5 10.8 26.59C10.58 26.68 10.35 26.73 10.12 26.72Z" />
		<path  className="shp0" d="M10.2 38.11C9.97 38.11 9.74 38.07 9.52 37.98C9.31 37.89 9.11 37.76 8.95 37.6C8.78 37.43 8.65 37.24 8.56 37.02C8.47 36.8 8.43 36.57 8.43 36.34C8.43 36.11 8.47 35.88 8.56 35.66C8.65 35.45 8.78 35.25 8.94 35.09C9.11 34.92 9.3 34.79 9.52 34.7C9.73 34.61 9.97 34.57 10.2 34.57C10.43 34.57 10.66 34.61 10.88 34.7C11.09 34.79 11.29 34.92 11.45 35.08C11.62 35.25 11.75 35.44 11.84 35.66C11.92 35.87 11.97 36.11 11.97 36.34C11.97 36.57 11.93 36.8 11.84 37.02C11.75 37.23 11.62 37.43 11.46 37.59C11.29 37.76 11.1 37.89 10.88 37.98C10.66 38.06 10.43 38.11 10.2 38.11Z" />
		<path  className="shp0" d="M10.14 49.49C9.91 49.49 9.68 49.45 9.46 49.36C9.25 49.27 9.05 49.14 8.88 48.98C8.72 48.82 8.59 48.62 8.5 48.4C8.41 48.19 8.37 47.96 8.37 47.73C8.37 47.49 8.41 47.26 8.5 47.05C8.59 46.83 8.72 46.63 8.88 46.47C9.05 46.31 9.24 46.18 9.46 46.09C9.67 46 9.9 45.95 10.14 45.96C10.37 45.95 10.6 46 10.82 46.09C11.03 46.17 11.23 46.3 11.39 46.47C11.56 46.63 11.69 46.83 11.78 47.04C11.86 47.26 11.91 47.49 11.91 47.72C11.91 47.96 11.87 48.19 11.78 48.4C11.69 48.62 11.56 48.81 11.39 48.98C11.23 49.14 11.03 49.27 10.82 49.36C10.6 49.45 10.37 49.49 10.14 49.49Z" />
		<path  className="shp0" d="M10.22 60.88C9.99 60.88 9.76 60.83 9.54 60.75C9.33 60.66 9.13 60.53 8.97 60.36C8.8 60.2 8.67 60 8.58 59.79C8.49 59.57 8.45 59.34 8.45 59.11C8.45 58.88 8.49 58.65 8.58 58.43C8.67 58.22 8.8 58.02 8.96 57.85C9.13 57.69 9.32 57.56 9.54 57.47C9.75 57.38 9.98 57.34 10.22 57.34C10.45 57.34 10.68 57.38 10.9 57.47C11.11 57.56 11.31 57.69 11.47 57.85C11.64 58.02 11.77 58.21 11.86 58.43C11.94 58.64 11.99 58.87 11.99 59.11C11.99 59.34 11.95 59.57 11.86 59.79C11.77 60 11.64 60.2 11.48 60.36C11.31 60.53 11.11 60.66 10.9 60.75C10.68 60.83 10.45 60.88 10.22 60.88Z" />
		<path  className="shp0" d="M10.16 72.26C9.93 72.26 9.7 72.22 9.48 72.13C9.26 72.04 9.07 71.91 8.9 71.75C8.74 71.58 8.61 71.39 8.52 71.17C8.43 70.96 8.39 70.73 8.39 70.49C8.39 70.26 8.43 70.03 8.52 69.82C8.61 69.6 8.74 69.4 8.9 69.24C9.07 69.07 9.26 68.94 9.48 68.86C9.69 68.77 9.92 68.72 10.16 68.73C10.39 68.72 10.62 68.77 10.84 68.85C11.05 68.94 11.25 69.07 11.41 69.24C11.58 69.4 11.71 69.6 11.8 69.81C11.88 70.03 11.93 70.26 11.93 70.49C11.93 70.72 11.88 70.96 11.8 71.17C11.71 71.39 11.58 71.58 11.41 71.75C11.25 71.91 11.05 72.04 10.84 72.13C10.62 72.22 10.39 72.26 10.16 72.26Z" />
		<path  className="shp0" d="M10.17 83.57C9.94 83.58 9.71 83.53 9.49 83.44C9.27 83.36 9.08 83.23 8.91 83.06C8.75 82.9 8.62 82.7 8.53 82.49C8.44 82.27 8.4 82.04 8.4 81.81C8.4 81.58 8.44 81.34 8.53 81.13C8.62 80.91 8.75 80.72 8.91 80.55C9.08 80.39 9.27 80.26 9.49 80.17C9.7 80.08 9.93 80.04 10.17 80.04C10.4 80.04 10.63 80.08 10.85 80.17C11.06 80.26 11.26 80.39 11.42 80.55C11.59 80.71 11.72 80.91 11.8 81.13C11.89 81.34 11.94 81.57 11.94 81.81C11.94 82.04 11.89 82.27 11.81 82.48C11.72 82.7 11.59 82.9 11.42 83.06C11.26 83.23 11.06 83.36 10.85 83.44C10.63 83.53 10.4 83.58 10.17 83.57Z" />
		<path  className="shp0" d="M10.18 95.03C9.95 95.03 9.72 94.99 9.5 94.9C9.28 94.81 9.09 94.68 8.92 94.52C8.76 94.35 8.63 94.16 8.54 93.94C8.45 93.73 8.41 93.5 8.41 93.26C8.41 93.03 8.45 92.8 8.54 92.58C8.63 92.37 8.76 92.17 8.92 92.01C9.09 91.84 9.28 91.71 9.5 91.62C9.71 91.54 9.94 91.49 10.18 91.49C10.41 91.49 10.64 91.54 10.86 91.62C11.07 91.71 11.27 91.84 11.43 92.01C11.6 92.17 11.73 92.37 11.81 92.58C11.9 92.8 11.95 93.03 11.95 93.26C11.95 93.49 11.9 93.72 11.82 93.94C11.73 94.16 11.6 94.35 11.43 94.52C11.27 94.68 11.07 94.81 10.86 94.9C10.64 94.99 10.41 95.03 10.18 95.03Z" />
		<path  className="shp0" d="M10.19 106.34C9.96 106.35 9.73 106.3 9.51 106.21C9.29 106.13 9.1 106 8.93 105.83C8.77 105.67 8.64 105.47 8.55 105.26C8.46 105.04 8.42 104.81 8.42 104.58C8.42 104.34 8.46 104.11 8.55 103.9C8.64 103.68 8.77 103.49 8.93 103.32C9.1 103.16 9.29 103.03 9.51 102.94C9.72 102.85 9.95 102.81 10.19 102.81C10.42 102.81 10.65 102.85 10.86 102.94C11.08 103.02 11.28 103.15 11.44 103.32C11.61 103.48 11.74 103.68 11.82 103.89C11.91 104.11 11.96 104.34 11.95 104.57C11.96 104.81 11.91 105.04 11.83 105.25C11.74 105.47 11.61 105.66 11.44 105.83C11.28 105.99 11.08 106.12 10.87 106.21C10.65 106.3 10.42 106.35 10.19 106.34Z" />
		<path  className="shp0" d="M8.93 117.23C8.22 116.53 8.22 115.39 8.92 114.69C9.63 113.98 10.76 113.98 11.47 114.69C12.17 115.39 12.17 116.53 11.47 117.23C10.77 117.94 9.63 117.94 8.93 117.23Z" />
		<path  className="shp0" d="M10.21 129.11C9.98 129.11 9.74 129.07 9.53 128.98C9.31 128.89 9.12 128.76 8.95 128.6C8.79 128.44 8.66 128.24 8.57 128.02C8.48 127.81 8.44 127.58 8.44 127.35C8.44 127.11 8.48 126.88 8.57 126.67C8.66 126.45 8.79 126.26 8.95 126.09C9.12 125.93 9.31 125.8 9.53 125.71C9.74 125.62 9.97 125.57 10.21 125.58C10.44 125.57 10.67 125.62 10.88 125.71C11.1 125.79 11.3 125.92 11.46 126.09C11.63 126.25 11.76 126.45 11.84 126.66C11.93 126.88 11.98 127.11 11.97 127.34C11.98 127.58 11.93 127.81 11.84 128.02C11.76 128.24 11.63 128.43 11.46 128.6C11.3 128.76 11.1 128.89 10.89 128.98C10.67 129.07 10.44 129.11 10.21 129.11Z" />
		<path  className="shp0" d="M2.18 3.96C1.95 3.96 1.72 3.92 1.5 3.83C1.29 3.74 1.09 3.61 0.93 3.45C0.76 3.28 0.63 3.09 0.54 2.87C0.45 2.66 0.41 2.43 0.41 2.19C0.41 1.96 0.45 1.73 0.54 1.52C0.63 1.3 0.76 1.1 0.92 0.94C1.09 0.77 1.28 0.64 1.5 0.56C1.71 0.47 1.95 0.42 2.18 0.43C2.41 0.42 2.64 0.47 2.86 0.55C3.07 0.64 3.27 0.77 3.43 0.94C3.6 1.1 3.73 1.3 3.82 1.51C3.91 1.73 3.95 1.96 3.95 2.19C3.95 2.42 3.91 2.66 3.82 2.87C3.73 3.09 3.6 3.28 3.44 3.45C3.27 3.61 3.08 3.74 2.86 3.83C2.65 3.92 2.41 3.96 2.18 3.96Z" />
		<path  className="shp0" d="M2.19 15.42C1.96 15.42 1.73 15.37 1.51 15.29C1.3 15.2 1.1 15.07 0.94 14.9C0.77 14.74 0.64 14.54 0.55 14.33C0.46 14.11 0.42 13.88 0.42 13.65C0.42 13.42 0.46 13.19 0.55 12.97C0.64 12.75 0.77 12.56 0.93 12.39C1.1 12.23 1.29 12.1 1.51 12.01C1.72 11.92 1.96 11.88 2.19 11.88C2.42 11.88 2.65 11.92 2.87 12.01C3.08 12.1 3.28 12.23 3.44 12.39C3.61 12.56 3.74 12.75 3.83 12.97C3.92 13.18 3.96 13.41 3.96 13.65C3.94 13.87 3.88 14.09 3.78 14.3C3.68 14.5 3.55 14.69 3.39 14.85C3.23 15.01 3.05 15.14 2.84 15.24C2.64 15.33 2.42 15.39 2.19 15.42Z" />
		<path  className="shp0" d="M2.2 26.73C1.97 26.73 1.74 26.69 1.52 26.6C1.31 26.51 1.11 26.38 0.95 26.22C0.78 26.05 0.65 25.86 0.56 25.64C0.47 25.43 0.43 25.2 0.43 24.96C0.43 24.73 0.47 24.5 0.56 24.28C0.65 24.07 0.78 23.87 0.94 23.71C1.11 23.54 1.3 23.41 1.52 23.32C1.73 23.24 1.97 23.19 2.2 23.19C2.43 23.19 2.66 23.24 2.88 23.32C3.09 23.41 3.29 23.54 3.45 23.71C3.62 23.87 3.75 24.07 3.84 24.28C3.92 24.5 3.97 24.73 3.97 24.96C3.97 25.19 3.93 25.42 3.84 25.64C3.75 25.86 3.62 26.05 3.46 26.22C3.29 26.38 3.1 26.51 2.88 26.6C2.66 26.69 2.43 26.73 2.2 26.73Z" />
		<path  className="shp0" d="M0.94 37.62C0.23 36.92 0.23 35.78 0.94 35.07C1.64 34.37 2.78 34.37 3.48 35.07C4.19 35.78 4.19 36.91 3.48 37.62C2.78 38.32 1.64 38.32 0.94 37.62Z" />
		<path  className="shp0" d="M0.95 48.93C0.24 48.23 0.24 47.09 0.95 46.39C1.65 45.68 2.79 45.68 3.49 46.39C4.2 47.09 4.2 48.23 3.49 48.93C2.79 49.64 1.65 49.64 0.95 48.93Z" />
		<path  className="shp0" d="M2.23 60.81C2 60.81 1.77 60.77 1.55 60.68C1.34 60.6 1.14 60.47 0.97 60.3C0.81 60.14 0.68 59.94 0.59 59.73C0.5 59.51 0.46 59.28 0.46 59.05C0.46 58.81 0.5 58.58 0.59 58.37C0.68 58.15 0.81 57.96 0.97 57.79C1.14 57.63 1.33 57.5 1.55 57.41C1.76 57.32 1.99 57.27 2.23 57.28C2.46 57.27 2.69 57.32 2.91 57.41C3.12 57.49 3.32 57.62 3.48 57.79C3.65 57.95 3.78 58.15 3.87 58.36C3.95 58.58 4 58.81 4 59.04C4 59.28 3.96 59.51 3.87 59.72C3.78 59.94 3.65 60.13 3.49 60.3C3.32 60.46 3.12 60.59 2.91 60.68C2.69 60.77 2.46 60.81 2.23 60.81Z" />
		<path  className="shp0" d="M2.24 72.27C2.01 72.27 1.78 72.23 1.56 72.14C1.35 72.05 1.15 71.92 0.98 71.76C0.82 71.59 0.69 71.4 0.6 71.18C0.51 70.96 0.47 70.73 0.47 70.5C0.47 70.27 0.51 70.04 0.6 69.82C0.69 69.61 0.82 69.41 0.98 69.25C1.15 69.08 1.34 68.95 1.56 68.86C1.77 68.77 2 68.73 2.24 68.73C2.47 68.73 2.7 68.77 2.92 68.86C3.13 68.95 3.33 69.08 3.49 69.24C3.66 69.41 3.79 69.6 3.88 69.82C3.96 70.03 4.01 70.27 4.01 70.5C4.01 70.73 3.96 70.96 3.88 71.18C3.79 71.39 3.66 71.59 3.49 71.75C3.33 71.92 3.13 72.05 2.92 72.14C2.7 72.23 2.47 72.27 2.24 72.27Z" />
		<path  className="shp0" d="M2.25 83.58C2.02 83.58 1.79 83.54 1.57 83.45C1.35 83.36 1.16 83.23 0.99 83.07C0.83 82.9 0.7 82.71 0.61 82.49C0.52 82.28 0.48 82.05 0.48 81.81C0.48 81.58 0.52 81.35 0.61 81.14C0.7 80.92 0.83 80.72 0.99 80.56C1.16 80.39 1.35 80.26 1.57 80.18C1.78 80.09 2.01 80.04 2.25 80.05C2.48 80.04 2.71 80.09 2.93 80.18C3.14 80.26 3.34 80.39 3.5 80.56C3.67 80.72 3.8 80.92 3.89 81.13C3.97 81.35 4.02 81.58 4.02 81.81C4.02 82.04 3.97 82.28 3.89 82.49C3.8 82.71 3.67 82.9 3.5 83.07C3.34 83.23 3.14 83.36 2.93 83.45C2.71 83.54 2.48 83.58 2.25 83.58Z" />
		<path  className="shp0" d="M0.99 94.47C0.28 93.77 0.28 92.63 0.98 91.93C1.69 91.22 2.83 91.22 3.53 91.92C4.23 92.63 4.23 93.77 3.53 94.47C2.83 95.17 1.69 95.17 0.99 94.47Z" />
		<path  className="shp0" d="M0.93 105.86C0.22 105.15 0.22 104.02 0.92 103.31C1.63 102.61 2.76 102.61 3.47 103.31C4.17 104.01 4.17 105.15 3.47 105.85C2.77 106.56 1.63 106.56 0.93 105.86Z" />
		<path  className="shp0" d="M2.21 117.73C1.98 117.74 1.74 117.69 1.53 117.61C1.31 117.52 1.12 117.39 0.95 117.22C0.79 117.06 0.66 116.86 0.57 116.65C0.48 116.43 0.44 116.2 0.44 115.97C0.44 115.74 0.48 115.5 0.57 115.29C0.66 115.07 0.79 114.88 0.95 114.71C1.12 114.55 1.31 114.42 1.53 114.33C1.74 114.24 1.97 114.2 2.21 114.2C2.44 114.2 2.67 114.24 2.88 114.33C3.1 114.42 3.3 114.55 3.46 114.71C3.63 114.88 3.76 115.07 3.84 115.29C3.93 115.5 3.98 115.73 3.97 115.97C3.98 116.2 3.93 116.43 3.85 116.64C3.76 116.86 3.63 117.06 3.46 117.22C3.3 117.39 3.1 117.52 2.89 117.6C2.67 117.69 2.44 117.74 2.21 117.73Z" />
		<path  className="shp0" d="M2.29 129.12C2.06 129.12 1.82 129.08 1.61 128.99C1.39 128.9 1.2 128.77 1.03 128.61C0.87 128.44 0.74 128.25 0.65 128.03C0.56 127.82 0.52 127.59 0.52 127.35C0.52 127.12 0.56 126.89 0.65 126.67C0.74 126.46 0.87 126.26 1.03 126.1C1.2 125.93 1.39 125.8 1.61 125.71C1.82 125.63 2.05 125.58 2.29 125.58C2.52 125.58 2.75 125.62 2.96 125.71C3.18 125.8 3.38 125.93 3.54 126.09C3.71 126.26 3.84 126.46 3.92 126.67C4.01 126.89 4.06 127.12 4.05 127.35C4.06 127.58 4.01 127.81 3.93 128.03C3.84 128.24 3.71 128.44 3.54 128.6C3.38 128.77 3.18 128.9 2.97 128.99C2.75 129.08 2.52 129.12 2.29 129.12Z" />
	</g>
	<g id="  169">
		<path id=" " className="shp0" d="M215.36 3.38C214.65 2.68 214.65 1.54 215.36 0.83C216.06 0.13 217.2 0.13 217.9 0.83C218.61 1.53 218.61 2.67 217.9 3.38C217.2 4.08 216.06 4.08 215.36 3.38Z" />
		<path id="  2" className="shp0" d="M215.44 14.76C214.73 14.06 214.73 12.92 215.44 12.22C216.14 11.51 217.28 11.51 217.98 12.22C218.69 12.92 218.69 14.06 217.98 14.76C217.28 15.47 216.14 15.47 215.44 14.76Z" />
		<path id="  3" className="shp0" d="M216.72 26.64C216.49 26.64 216.26 26.6 216.04 26.51C215.83 26.42 215.63 26.29 215.47 26.13C215.3 25.97 215.17 25.77 215.08 25.55C214.99 25.34 214.95 25.11 214.95 24.88C214.95 24.64 214.99 24.41 215.08 24.2C215.17 23.98 215.3 23.78 215.46 23.62C215.63 23.45 215.82 23.32 216.04 23.24C216.25 23.15 216.49 23.1 216.72 23.11C216.95 23.1 217.18 23.15 217.4 23.24C217.61 23.32 217.81 23.45 217.97 23.62C218.14 23.78 218.27 23.98 218.36 24.19C218.45 24.41 218.49 24.64 218.49 24.87C218.49 25.1 218.45 25.34 218.36 25.55C218.27 25.77 218.14 25.96 217.98 26.13C217.81 26.29 217.62 26.42 217.4 26.51C217.19 26.6 216.95 26.64 216.72 26.64Z" />
		<path id="  4" className="shp0" d="M216.66 38.03C216.43 38.03 216.2 37.98 215.98 37.9C215.77 37.81 215.57 37.68 215.41 37.51C215.24 37.35 215.11 37.15 215.02 36.94C214.93 36.72 214.89 36.49 214.89 36.26C214.89 36.03 214.93 35.8 215.02 35.58C215.11 35.36 215.24 35.17 215.4 35C215.57 34.84 215.76 34.71 215.98 34.62C216.19 34.53 216.42 34.49 216.66 34.49C216.89 34.49 217.12 34.53 217.34 34.62C217.55 34.71 217.75 34.84 217.91 35C218.08 35.17 218.21 35.36 218.3 35.58C218.38 35.79 218.43 36.02 218.43 36.26C218.43 36.49 218.39 36.72 218.3 36.94C218.21 37.15 218.08 37.35 217.92 37.51C217.75 37.68 217.56 37.81 217.34 37.9C217.12 37.98 216.89 38.03 216.66 38.03Z" />
		<path id="  5" className="shp0" d="M216.74 49.41C216.51 49.41 216.28 49.37 216.06 49.28C215.85 49.19 215.65 49.06 215.49 48.9C215.32 48.73 215.19 48.54 215.1 48.32C215.01 48.11 214.97 47.88 214.97 47.64C214.97 47.41 215.01 47.18 215.1 46.96C215.19 46.75 215.32 46.55 215.48 46.39C215.65 46.22 215.84 46.09 216.06 46.01C216.27 45.92 216.51 45.87 216.74 45.87C216.97 45.87 217.2 45.92 217.42 46C217.63 46.09 217.83 46.22 217.99 46.39C218.16 46.55 218.29 46.75 218.38 46.96C218.46 47.18 218.51 47.41 218.51 47.64C218.51 47.87 218.47 48.1 218.38 48.32C218.29 48.54 218.16 48.73 218 48.9C217.83 49.06 217.64 49.19 217.42 49.28C217.2 49.37 216.97 49.41 216.74 49.41Z" />
		<path id="  6" className="shp0" d="M216.75 60.72C216.52 60.73 216.29 60.68 216.07 60.59C215.86 60.51 215.66 60.38 215.5 60.21C215.33 60.05 215.2 59.85 215.11 59.64C215.02 59.42 214.98 59.19 214.98 58.96C214.98 58.72 215.02 58.49 215.11 58.28C215.2 58.06 215.33 57.87 215.49 57.7C215.66 57.54 215.85 57.41 216.07 57.32C216.28 57.23 216.51 57.19 216.75 57.19C216.98 57.19 217.21 57.23 217.43 57.32C217.64 57.41 217.84 57.54 218 57.7C218.17 57.86 218.3 58.06 218.39 58.28C218.47 58.49 218.52 58.72 218.52 58.95C218.52 59.19 218.48 59.42 218.39 59.63C218.3 59.85 218.17 60.05 218.01 60.21C217.84 60.37 217.65 60.51 217.43 60.59C217.21 60.68 216.98 60.73 216.75 60.72Z" />
		<path id="  7" className="shp0" d="M215.49 71.61C214.78 70.91 214.78 69.77 215.48 69.07C216.19 68.36 217.33 68.36 218.03 69.07C218.73 69.77 218.74 70.91 218.03 71.61C217.33 72.32 216.19 72.32 215.49 71.61Z" />
		<path id="  8" className="shp0" d="M215.5 83.07C214.79 82.37 214.79 81.23 215.49 80.52C216.2 79.82 217.34 79.82 218.04 80.52C218.74 81.22 218.75 82.36 218.04 83.07C217.34 83.77 216.2 83.77 215.5 83.07Z" />
		<path id="  9" className="shp0" d="M216.78 94.95C216.55 94.95 216.32 94.91 216.1 94.82C215.89 94.73 215.69 94.6 215.52 94.44C215.36 94.27 215.23 94.08 215.14 93.86C215.05 93.65 215.01 93.41 215.01 93.18C215.01 92.95 215.05 92.72 215.14 92.5C215.23 92.29 215.36 92.09 215.52 91.93C215.69 91.76 215.88 91.63 216.1 91.54C216.31 91.45 216.54 91.41 216.78 91.41C217.01 91.41 217.24 91.45 217.46 91.54C217.67 91.63 217.87 91.76 218.03 91.92C218.2 92.09 218.33 92.28 218.42 92.5C218.5 92.71 218.55 92.95 218.55 93.18C218.55 93.41 218.5 93.64 218.42 93.86C218.33 94.07 218.2 94.27 218.03 94.43C217.87 94.6 217.67 94.73 217.46 94.82C217.24 94.91 217.01 94.95 216.78 94.95Z" />
		<path id="  10" className="shp0" d="M216.79 106.26C216.56 106.26 216.33 106.22 216.11 106.13C215.89 106.04 215.7 105.91 215.53 105.75C215.37 105.59 215.24 105.39 215.15 105.17C215.06 104.96 215.02 104.73 215.02 104.5C215.02 104.26 215.06 104.03 215.15 103.82C215.24 103.6 215.37 103.4 215.53 103.24C215.7 103.08 215.89 102.94 216.11 102.86C216.32 102.77 216.55 102.72 216.79 102.73C217.02 102.72 217.25 102.77 217.47 102.86C217.68 102.94 217.88 103.07 218.04 103.24C218.21 103.4 218.34 103.6 218.43 103.81C218.51 104.03 218.56 104.26 218.56 104.49C218.56 104.72 218.51 104.96 218.43 105.17C218.34 105.39 218.21 105.58 218.04 105.75C217.88 105.91 217.68 106.04 217.47 106.13C217.25 106.22 217.02 106.26 216.79 106.26Z" />
		<path id="  11" className="shp0" d="M216.8 117.72C216.57 117.72 216.34 117.68 216.12 117.59C215.9 117.5 215.71 117.37 215.54 117.2C215.38 117.04 215.25 116.84 215.16 116.63C215.07 116.41 215.03 116.18 215.03 115.95C215.03 115.72 215.07 115.49 215.16 115.27C215.25 115.06 215.38 114.86 215.54 114.69C215.71 114.53 215.9 114.4 216.12 114.31C216.33 114.22 216.56 114.18 216.8 114.18C217.03 114.18 217.26 114.22 217.48 114.31C217.69 114.4 217.89 114.53 218.05 114.69C218.22 114.86 218.35 115.05 218.43 115.27C218.52 115.48 218.57 115.71 218.57 115.95C218.54 116.17 218.48 116.39 218.39 116.6C218.29 116.8 218.16 116.99 218 117.15C217.84 117.31 217.65 117.44 217.45 117.54C217.24 117.63 217.02 117.7 216.8 117.72Z" />
		<path id="  12" className="shp0" d="M215.54 128.47C214.83 127.76 214.83 126.62 215.53 125.92C216.24 125.22 217.37 125.21 218.08 125.92C218.78 126.62 218.78 127.76 218.08 128.46C217.38 129.17 216.24 129.17 215.54 128.47Z" />
		<path id="  13" className="shp0" d="M207.44 3.39C206.73 2.68 206.73 1.54 207.44 0.84C208.14 0.14 209.28 0.13 209.98 0.84C210.69 1.54 210.69 2.68 209.98 3.38C209.28 4.09 208.14 4.09 207.44 3.39Z" />
		<path id="  14" className="shp0" d="M208.72 15.26C208.49 15.27 208.26 15.22 208.04 15.13C207.83 15.05 207.63 14.92 207.47 14.75C207.3 14.59 207.17 14.39 207.08 14.18C206.99 13.96 206.95 13.73 206.95 13.5C206.95 13.26 206.99 13.03 207.08 12.82C207.17 12.6 207.3 12.41 207.46 12.24C207.63 12.08 207.82 11.95 208.04 11.86C208.25 11.77 208.49 11.73 208.72 11.73C208.95 11.73 209.18 11.77 209.4 11.86C209.61 11.95 209.81 12.08 209.97 12.24C210.14 12.4 210.27 12.6 210.36 12.82C210.45 13.03 210.49 13.26 210.49 13.49C210.49 13.73 210.45 13.96 210.36 14.17C210.27 14.39 210.14 14.59 209.98 14.75C209.81 14.91 209.62 15.04 209.4 15.13C209.19 15.22 208.95 15.27 208.72 15.26Z" />
		<path id="  15" className="shp0" d="M207.46 26.15C206.75 25.45 206.75 24.31 207.46 23.61C208.16 22.9 209.3 22.9 210 23.61C210.71 24.31 210.71 25.45 210 26.15C209.3 26.86 208.16 26.86 207.46 26.15Z" />
		<path id="  16" className="shp0" d="M208.74 38.03C208.51 38.03 208.28 37.99 208.06 37.9C207.85 37.82 207.65 37.69 207.49 37.52C207.32 37.36 207.19 37.16 207.1 36.95C207.01 36.73 206.97 36.5 206.97 36.27C206.97 36.03 207.01 35.8 207.1 35.59C207.19 35.37 207.32 35.18 207.48 35.01C207.65 34.85 207.84 34.72 208.06 34.63C208.27 34.54 208.51 34.49 208.74 34.5C208.97 34.49 209.2 34.54 209.42 34.63C209.63 34.71 209.83 34.84 209.99 35.01C210.16 35.17 210.29 35.37 210.38 35.58C210.46 35.8 210.51 36.03 210.51 36.26C210.51 36.5 210.47 36.73 210.38 36.94C210.29 37.16 210.16 37.35 210 37.52C209.83 37.68 209.64 37.81 209.42 37.9C209.2 37.99 208.97 38.03 208.74 38.03Z" />
		<path id="  17" className="shp0" d="M208.82 49.42C208.59 49.42 208.36 49.38 208.14 49.29C207.93 49.2 207.73 49.07 207.57 48.91C207.4 48.74 207.27 48.55 207.18 48.33C207.09 48.11 207.05 47.88 207.05 47.65C207.05 47.42 207.09 47.19 207.18 46.97C207.27 46.76 207.4 46.56 207.56 46.4C207.73 46.23 207.92 46.1 208.14 46.01C208.35 45.92 208.59 45.88 208.82 45.88C209.05 45.88 209.28 45.92 209.5 46.01C209.71 46.1 209.91 46.23 210.07 46.39C210.24 46.56 210.37 46.75 210.46 46.97C210.55 47.18 210.59 47.41 210.59 47.65C210.59 47.88 210.55 48.11 210.46 48.33C210.37 48.54 210.24 48.74 210.08 48.9C209.91 49.07 209.72 49.2 209.5 49.29C209.28 49.37 209.05 49.42 208.82 49.42Z" />
		<path id="  18" className="shp0" d="M208.76 60.8C208.53 60.8 208.3 60.76 208.08 60.67C207.87 60.58 207.67 60.45 207.5 60.29C207.34 60.13 207.21 59.93 207.12 59.71C207.03 59.5 206.99 59.27 206.99 59.04C206.99 58.8 207.03 58.57 207.12 58.36C207.21 58.14 207.34 57.94 207.5 57.78C207.67 57.61 207.86 57.48 208.08 57.4C208.29 57.31 208.52 57.26 208.76 57.27C208.99 57.26 209.22 57.31 209.44 57.4C209.65 57.48 209.85 57.61 210.01 57.78C210.18 57.94 210.31 58.14 210.4 58.35C210.48 58.57 210.53 58.8 210.53 59.03C210.53 59.26 210.49 59.5 210.4 59.71C210.31 59.93 210.18 60.12 210.02 60.29C209.85 60.45 209.65 60.58 209.44 60.67C209.22 60.76 208.99 60.8 208.76 60.8Z" />
		<path id="  19" className="shp0" d="M208.77 72.12C208.54 72.12 208.31 72.07 208.09 71.99C207.88 71.9 207.68 71.77 207.51 71.6C207.35 71.44 207.22 71.24 207.13 71.03C207.04 70.81 207 70.58 207 70.35C207 70.12 207.04 69.89 207.13 69.67C207.22 69.45 207.35 69.26 207.51 69.09C207.68 68.93 207.87 68.8 208.09 68.71C208.3 68.62 208.53 68.58 208.77 68.58C209 68.58 209.23 68.62 209.45 68.71C209.66 68.8 209.86 68.93 210.02 69.09C210.19 69.26 210.32 69.45 210.41 69.67C210.49 69.88 210.54 70.11 210.54 70.35C210.54 70.58 210.49 70.81 210.41 71.03C210.32 71.24 210.19 71.44 210.02 71.6C209.86 71.77 209.66 71.9 209.45 71.98C209.23 72.07 209 72.12 208.77 72.12Z" />
		<path id="  20" className="shp0" d="M208.78 83.57C208.55 83.57 208.32 83.53 208.1 83.44C207.88 83.35 207.69 83.22 207.52 83.06C207.36 82.89 207.23 82.7 207.14 82.48C207.05 82.27 207.01 82.04 207.01 81.8C207.01 81.57 207.05 81.34 207.14 81.12C207.23 80.91 207.36 80.71 207.52 80.55C207.69 80.38 207.88 80.25 208.1 80.17C208.31 80.08 208.54 80.03 208.78 80.03C209.01 80.03 209.24 80.08 209.46 80.16C209.67 80.25 209.87 80.38 210.03 80.55C210.2 80.71 210.33 80.91 210.42 81.12C210.5 81.34 210.55 81.57 210.55 81.8C210.55 82.03 210.5 82.26 210.42 82.48C210.33 82.7 210.2 82.89 210.03 83.06C209.87 83.22 209.67 83.35 209.46 83.44C209.24 83.53 209.01 83.57 208.78 83.57Z" />
		<path id="  21" className="shp0" d="M208.79 94.88C208.56 94.89 208.33 94.84 208.11 94.75C207.89 94.67 207.7 94.54 207.53 94.37C207.37 94.21 207.24 94.01 207.15 93.8C207.06 93.58 207.02 93.35 207.02 93.12C207.02 92.88 207.06 92.65 207.15 92.44C207.24 92.22 207.37 92.03 207.53 91.86C207.7 91.7 207.89 91.57 208.11 91.48C208.32 91.39 208.55 91.35 208.79 91.35C209.02 91.35 209.25 91.39 209.47 91.48C209.68 91.57 209.88 91.7 210.04 91.86C210.21 92.02 210.34 92.22 210.43 92.44C210.51 92.65 210.56 92.88 210.56 93.11C210.56 93.35 210.51 93.58 210.43 93.79C210.34 94.01 210.21 94.21 210.04 94.37C209.88 94.53 209.68 94.67 209.47 94.75C209.25 94.84 209.02 94.89 208.79 94.88Z" />
		<path id="  22" className="shp0" d="M208.8 106.34C208.57 106.34 208.34 106.3 208.12 106.21C207.9 106.12 207.71 105.99 207.54 105.83C207.38 105.66 207.25 105.47 207.16 105.25C207.07 105.04 207.03 104.81 207.03 104.57C207.03 104.34 207.07 104.11 207.16 103.89C207.25 103.68 207.38 103.48 207.54 103.32C207.71 103.15 207.9 103.02 208.12 102.93C208.33 102.85 208.56 102.8 208.8 102.8C209.03 102.8 209.26 102.85 209.48 102.93C209.69 103.02 209.89 103.15 210.05 103.32C210.22 103.48 210.35 103.68 210.43 103.89C210.52 104.11 210.57 104.34 210.57 104.57C210.57 104.8 210.52 105.03 210.44 105.25C210.35 105.46 210.22 105.66 210.05 105.83C209.89 105.99 209.69 106.12 209.48 106.21C209.26 106.3 209.03 106.34 208.8 106.34Z" />
		<path id="  23" className="shp0" d="M208.81 117.65C208.58 117.66 208.35 117.61 208.13 117.52C207.91 117.44 207.72 117.31 207.55 117.14C207.39 116.98 207.26 116.78 207.17 116.57C207.08 116.35 207.04 116.12 207.04 115.89C207.04 115.65 207.08 115.42 207.17 115.21C207.26 114.99 207.39 114.8 207.55 114.63C207.72 114.47 207.91 114.34 208.13 114.25C208.34 114.16 208.57 114.12 208.81 114.12C209.04 114.11 209.27 114.16 209.49 114.25C209.7 114.33 209.9 114.46 210.06 114.63C210.23 114.79 210.36 114.99 210.44 115.2C210.53 115.42 210.58 115.65 210.58 115.88C210.58 116.12 210.53 116.35 210.45 116.56C210.36 116.78 210.23 116.97 210.06 117.14C209.9 117.3 209.7 117.43 209.49 117.52C209.27 117.61 209.04 117.65 208.81 117.65Z" />
		<path id="  24" className="shp0" d="M208.89 129.04C208.66 129.04 208.43 129 208.21 128.91C207.99 128.82 207.8 128.69 207.63 128.53C207.47 128.36 207.34 128.17 207.25 127.95C207.16 127.73 207.12 127.5 207.12 127.27C207.12 127.04 207.16 126.81 207.25 126.59C207.34 126.38 207.47 126.18 207.63 126.02C207.8 125.85 207.99 125.72 208.21 125.63C208.42 125.54 208.65 125.5 208.89 125.5C209.12 125.5 209.35 125.54 209.57 125.63C209.78 125.72 209.98 125.85 210.14 126.01C210.31 126.18 210.44 126.37 210.53 126.59C210.61 126.8 210.66 127.04 210.66 127.27C210.66 127.5 210.61 127.73 210.53 127.95C210.44 128.16 210.31 128.36 210.14 128.52C209.98 128.69 209.78 128.82 209.57 128.91C209.35 128.99 209.12 129.04 208.89 129.04L208.89 129.04Z" />
		<path id="  25" className="shp0" d="M200.72 3.89C200.49 3.89 200.26 3.84 200.04 3.76C199.83 3.67 199.63 3.54 199.47 3.37C199.3 3.21 199.17 3.01 199.08 2.8C198.99 2.58 198.95 2.35 198.95 2.12C198.95 1.89 198.99 1.66 199.08 1.44C199.17 1.23 199.3 1.03 199.46 0.86C199.63 0.7 199.82 0.57 200.04 0.48C200.25 0.39 200.49 0.35 200.72 0.35C200.95 0.35 201.18 0.39 201.4 0.48C201.61 0.57 201.81 0.7 201.97 0.86C202.14 1.03 202.27 1.22 202.36 1.44C202.45 1.65 202.49 1.88 202.49 2.12C202.49 2.35 202.45 2.58 202.36 2.8C202.27 3.01 202.14 3.21 201.98 3.37C201.81 3.54 201.62 3.67 201.4 3.76C201.19 3.84 200.95 3.89 200.72 3.89Z" />
		<path id="  26" className="shp0" d="M200.8 15.27C200.57 15.27 200.34 15.23 200.12 15.14C199.91 15.05 199.71 14.92 199.55 14.76C199.38 14.59 199.25 14.4 199.16 14.18C199.07 13.97 199.03 13.74 199.03 13.5C199.03 13.27 199.07 13.04 199.16 12.82C199.25 12.61 199.38 12.41 199.54 12.25C199.71 12.08 199.9 11.95 200.12 11.87C200.34 11.78 200.57 11.73 200.8 11.73C201.03 11.73 201.26 11.78 201.48 11.86C201.69 11.95 201.89 12.08 202.05 12.25C202.22 12.41 202.35 12.61 202.44 12.82C202.53 13.04 202.57 13.27 202.57 13.5C202.57 13.73 202.53 13.96 202.44 14.18C202.35 14.4 202.22 14.59 202.06 14.76C201.89 14.92 201.7 15.05 201.48 15.14C201.27 15.23 201.03 15.27 200.8 15.27Z" />
		<path id="  27" className="shp0" d="M200.74 26.65C200.51 26.66 200.28 26.61 200.06 26.53C199.85 26.44 199.65 26.31 199.49 26.14C199.32 25.98 199.19 25.78 199.1 25.57C199.01 25.35 198.97 25.12 198.97 24.89C198.97 24.66 199.01 24.43 199.1 24.21C199.19 23.99 199.32 23.8 199.48 23.63C199.65 23.47 199.84 23.34 200.06 23.25C200.27 23.16 200.51 23.12 200.74 23.12C200.97 23.12 201.2 23.16 201.42 23.25C201.63 23.34 201.83 23.47 201.99 23.63C202.16 23.8 202.29 23.99 202.38 24.21C202.46 24.42 202.51 24.65 202.51 24.89C202.51 25.12 202.47 25.35 202.38 25.57C202.29 25.78 202.16 25.98 202 26.14C201.83 26.31 201.64 26.44 201.42 26.52C201.2 26.61 200.97 26.66 200.74 26.65Z" />
		<path id="  28" className="shp0" d="M199.48 37.55C198.77 36.84 198.77 35.7 199.48 35C200.18 34.3 201.32 34.29 202.02 35C202.73 35.7 202.73 36.84 202.02 37.54C201.32 38.25 200.18 38.25 199.48 37.55Z" />
		<path id="  29" className="shp0" d="M199.56 48.93C198.85 48.23 198.85 47.09 199.56 46.38C200.26 45.68 201.4 45.68 202.1 46.38C202.81 47.09 202.81 48.22 202.1 48.93C201.4 49.63 200.26 49.63 199.56 48.93Z" />
		<path id="  30" className="shp0" d="M200.84 60.81C200.61 60.81 200.38 60.77 200.16 60.68C199.95 60.59 199.75 60.46 199.59 60.3C199.42 60.13 199.29 59.94 199.2 59.72C199.11 59.51 199.07 59.27 199.07 59.04C199.07 58.81 199.11 58.58 199.2 58.36C199.29 58.15 199.42 57.95 199.58 57.79C199.75 57.62 199.94 57.49 200.16 57.4C200.37 57.31 200.6 57.27 200.84 57.27C201.07 57.27 201.3 57.31 201.52 57.4C201.73 57.49 201.93 57.62 202.09 57.78C202.26 57.95 202.39 58.14 202.48 58.36C202.56 58.58 202.61 58.81 202.61 59.04C202.61 59.27 202.57 59.5 202.48 59.72C202.39 59.93 202.26 60.13 202.1 60.29C201.93 60.46 201.74 60.59 201.52 60.68C201.3 60.77 201.07 60.81 200.84 60.81Z" />
		<path id="  31" className="shp0" d="M200.78 72.19C200.55 72.2 200.32 72.15 200.1 72.06C199.88 71.98 199.69 71.85 199.52 71.68C199.36 71.52 199.23 71.32 199.14 71.11C199.05 70.89 199.01 70.66 199.01 70.43C199.01 70.19 199.05 69.96 199.14 69.75C199.23 69.53 199.36 69.34 199.52 69.17C199.69 69.01 199.88 68.88 200.1 68.79C200.31 68.7 200.54 68.66 200.78 68.66C201.01 68.65 201.24 68.7 201.46 68.79C201.67 68.87 201.87 69 202.03 69.17C202.2 69.33 202.33 69.53 202.42 69.74C202.5 69.96 202.55 70.19 202.55 70.42C202.55 70.66 202.5 70.89 202.42 71.1C202.33 71.32 202.2 71.51 202.03 71.68C201.87 71.84 201.67 71.97 201.46 72.06C201.24 72.15 201.01 72.19 200.78 72.19Z" />
		<path id="  32" className="shp0" d="M200.86 83.58C200.63 83.58 200.4 83.54 200.18 83.45C199.97 83.36 199.77 83.23 199.6 83.07C199.44 82.9 199.31 82.71 199.22 82.49C199.13 82.27 199.09 82.04 199.09 81.81C199.09 81.58 199.13 81.35 199.22 81.13C199.31 80.92 199.44 80.72 199.6 80.56C199.77 80.39 199.96 80.26 200.18 80.17C200.39 80.08 200.62 80.04 200.86 80.04C201.09 80.04 201.32 80.08 201.54 80.17C201.75 80.26 201.95 80.39 202.11 80.55C202.28 80.72 202.41 80.91 202.5 81.13C202.58 81.34 202.63 81.57 202.63 81.81C202.63 82.04 202.58 82.27 202.5 82.49C202.41 82.7 202.28 82.9 202.11 83.06C201.95 83.23 201.75 83.36 201.54 83.45C201.32 83.53 201.09 83.58 200.86 83.58Z" />
		<path id="  33" className="shp0" d="M200.87 94.89C200.64 94.89 200.41 94.85 200.19 94.76C199.98 94.67 199.78 94.54 199.61 94.38C199.45 94.21 199.32 94.02 199.23 93.8C199.14 93.59 199.1 93.36 199.1 93.12C199.1 92.89 199.14 92.66 199.23 92.45C199.32 92.23 199.45 92.03 199.61 91.87C199.78 91.7 199.97 91.57 200.19 91.49C200.4 91.4 200.63 91.35 200.87 91.36C201.1 91.35 201.33 91.4 201.55 91.48C201.76 91.57 201.96 91.7 202.12 91.87C202.29 92.03 202.42 92.23 202.51 92.44C202.59 92.66 202.64 92.89 202.64 93.12C202.64 93.35 202.59 93.59 202.51 93.8C202.42 94.02 202.29 94.21 202.12 94.38C201.96 94.54 201.76 94.67 201.55 94.76C201.33 94.85 201.1 94.89 200.87 94.89Z" />
		<path id="  34" className="shp0" d="M199.61 105.78C198.9 105.08 198.9 103.94 199.6 103.24C200.31 102.53 201.45 102.53 202.15 103.23C202.85 103.94 202.86 105.07 202.15 105.78C201.45 106.48 200.31 106.48 199.61 105.78Z" />
		<path id="  35" className="shp0" d="M200.89 117.66C200.66 117.66 200.43 117.62 200.21 117.53C199.99 117.44 199.8 117.31 199.63 117.15C199.47 116.98 199.34 116.79 199.25 116.57C199.16 116.36 199.12 116.13 199.12 115.89C199.12 115.66 199.16 115.43 199.25 115.21C199.34 115 199.47 114.8 199.63 114.64C199.8 114.47 199.99 114.34 200.21 114.25C200.42 114.17 200.65 114.12 200.89 114.12C201.12 114.12 201.35 114.17 201.57 114.25C201.78 114.34 201.98 114.47 202.14 114.64C202.31 114.8 202.44 115 202.53 115.21C202.61 115.43 202.66 115.66 202.66 115.89C202.66 116.12 202.61 116.35 202.53 116.57C202.44 116.79 202.31 116.98 202.14 117.15C201.98 117.31 201.78 117.44 201.57 117.53C201.35 117.62 201.12 117.66 200.89 117.66Z" />
		<path id="  36" className="shp0" d="M200.83 129.04C200.6 129.05 200.36 129 200.15 128.91C199.93 128.83 199.74 128.7 199.57 128.53C199.41 128.37 199.28 128.17 199.19 127.96C199.1 127.74 199.06 127.51 199.06 127.28C199.06 127.04 199.1 126.81 199.19 126.6C199.28 126.38 199.41 126.19 199.57 126.02C199.74 125.86 199.93 125.73 200.15 125.64C200.36 125.55 200.59 125.51 200.83 125.51C201.06 125.51 201.29 125.55 201.5 125.64C201.72 125.73 201.92 125.86 202.08 126.02C202.25 126.18 202.38 126.38 202.46 126.6C202.55 126.81 202.6 127.04 202.59 127.27C202.6 127.51 202.55 127.74 202.47 127.95C202.38 128.17 202.25 128.37 202.08 128.53C201.92 128.7 201.72 128.83 201.51 128.91C201.29 129 201.06 129.05 200.83 129.04Z" />
		<path id="  37" className="shp0" d="M192.8 3.89C192.57 3.9 192.34 3.85 192.12 3.76C191.91 3.68 191.71 3.55 191.55 3.38C191.38 3.22 191.25 3.02 191.16 2.81C191.07 2.59 191.03 2.36 191.03 2.13C191.03 1.89 191.07 1.66 191.16 1.45C191.25 1.23 191.38 1.04 191.54 0.87C191.71 0.71 191.9 0.58 192.12 0.49C192.34 0.4 192.57 0.36 192.8 0.36C193.03 0.35 193.26 0.4 193.48 0.49C193.69 0.57 193.89 0.7 194.05 0.87C194.22 1.03 194.35 1.23 194.44 1.44C194.53 1.66 194.57 1.89 194.57 2.12C194.57 2.36 194.53 2.59 194.44 2.8C194.35 3.02 194.22 3.21 194.06 3.38C193.89 3.54 193.7 3.67 193.48 3.76C193.27 3.85 193.03 3.89 192.8 3.89Z" />
		<path id="  38" className="shp0" d="M191.54 14.78C190.83 14.08 190.83 12.94 191.54 12.24C192.24 11.53 193.38 11.53 194.08 12.24C194.79 12.94 194.79 14.08 194.08 14.78C193.38 15.49 192.24 15.49 191.54 14.78Z" />
		<path id="  39" className="shp0" d="M192.82 26.66C192.59 26.66 192.36 26.62 192.14 26.53C191.93 26.44 191.73 26.31 191.57 26.15C191.4 25.99 191.27 25.79 191.18 25.57C191.09 25.36 191.05 25.13 191.05 24.9C191.05 24.66 191.09 24.43 191.18 24.22C191.27 24 191.4 23.8 191.56 23.64C191.73 23.48 191.92 23.34 192.14 23.26C192.35 23.17 192.59 23.12 192.82 23.13C193.05 23.12 193.28 23.17 193.5 23.26C193.71 23.34 193.91 23.47 194.07 23.64C194.24 23.8 194.37 24 194.46 24.21C194.55 24.43 194.59 24.66 194.59 24.89C194.59 25.13 194.55 25.36 194.46 25.57C194.37 25.79 194.24 25.98 194.08 26.15C193.91 26.31 193.72 26.44 193.5 26.53C193.28 26.62 193.05 26.66 192.82 26.66Z" />
		<path id="  40" className="shp0" d="M192.76 38.05C192.53 38.05 192.3 38 192.08 37.92C191.87 37.83 191.67 37.7 191.5 37.53C191.34 37.37 191.21 37.17 191.12 36.96C191.03 36.74 190.99 36.51 190.99 36.28C190.99 36.05 191.03 35.82 191.12 35.6C191.21 35.39 191.34 35.19 191.5 35.02C191.67 34.86 191.86 34.73 192.08 34.64C192.29 34.55 192.52 34.51 192.76 34.51C192.99 34.51 193.22 34.55 193.44 34.64C193.65 34.73 193.85 34.86 194.01 35.02C194.18 35.19 194.31 35.38 194.4 35.6C194.48 35.81 194.53 36.04 194.53 36.28C194.53 36.51 194.48 36.74 194.4 36.96C194.31 37.17 194.18 37.37 194.01 37.53C193.85 37.7 193.65 37.83 193.44 37.92C193.22 38 192.99 38.05 192.76 38.05Z" />
		<path id="  41" className="shp0" d="M192.84 49.43C192.61 49.43 192.38 49.39 192.16 49.3C191.95 49.21 191.75 49.08 191.59 48.92C191.42 48.75 191.29 48.56 191.2 48.34C191.11 48.13 191.07 47.9 191.07 47.66C191.07 47.43 191.11 47.2 191.2 46.99C191.29 46.77 191.42 46.57 191.58 46.41C191.75 46.24 191.94 46.11 192.16 46.03C192.37 45.94 192.6 45.89 192.84 45.9C193.07 45.89 193.3 45.94 193.52 46.02C193.73 46.11 193.93 46.24 194.09 46.41C194.26 46.57 194.39 46.77 194.48 46.98C194.56 47.2 194.61 47.43 194.61 47.66C194.61 47.89 194.57 48.12 194.48 48.34C194.39 48.56 194.26 48.75 194.1 48.92C193.93 49.08 193.74 49.21 193.52 49.3C193.3 49.39 193.07 49.43 192.84 49.43Z" />
		<path id="  42" className="shp0" d="M192.85 60.74C192.62 60.75 192.39 60.7 192.17 60.61C191.96 60.53 191.76 60.4 191.59 60.23C191.43 60.07 191.3 59.87 191.21 59.66C191.12 59.44 191.08 59.21 191.08 58.98C191.08 58.75 191.12 58.51 191.21 58.3C191.3 58.08 191.43 57.89 191.59 57.72C191.76 57.56 191.95 57.43 192.17 57.34C192.38 57.25 192.61 57.21 192.85 57.21C193.08 57.21 193.31 57.25 193.53 57.34C193.74 57.43 193.94 57.56 194.1 57.72C194.27 57.88 194.4 58.08 194.49 58.3C194.57 58.51 194.62 58.74 194.62 58.97C194.62 59.21 194.58 59.44 194.49 59.65C194.4 59.87 194.27 60.07 194.1 60.23C193.94 60.4 193.74 60.53 193.53 60.61C193.31 60.7 193.08 60.75 192.85 60.74Z" />
		<path id="  43" className="shp0" d="M191.59 71.63C190.88 70.93 190.88 69.79 191.58 69.09C192.29 68.38 193.43 68.38 194.13 69.09C194.83 69.79 194.84 70.93 194.13 71.63C193.43 72.34 192.29 72.34 191.59 71.63Z" />
		<path id="  44" className="shp0" d="M192.87 83.51C192.64 83.52 192.41 83.47 192.19 83.38C191.98 83.3 191.78 83.17 191.61 83C191.45 82.84 191.32 82.64 191.23 82.43C191.14 82.21 191.1 81.98 191.1 81.75C191.1 81.51 191.14 81.28 191.23 81.07C191.32 80.85 191.45 80.66 191.61 80.49C191.78 80.33 191.97 80.2 192.19 80.11C192.4 80.02 192.63 79.98 192.87 79.98C193.1 79.98 193.33 80.02 193.55 80.11C193.76 80.19 193.96 80.32 194.12 80.49C194.29 80.65 194.42 80.85 194.51 81.06C194.59 81.28 194.64 81.51 194.64 81.74C194.64 81.98 194.59 82.21 194.51 82.42C194.42 82.64 194.29 82.83 194.12 83C193.96 83.16 193.76 83.29 193.55 83.38C193.33 83.47 193.1 83.52 192.87 83.51Z" />
		<path id="  45" className="shp0" d="M192.88 94.97C192.65 94.97 192.42 94.93 192.2 94.84C191.98 94.75 191.79 94.62 191.62 94.46C191.46 94.29 191.33 94.1 191.24 93.88C191.15 93.67 191.11 93.43 191.11 93.2C191.11 92.97 191.15 92.74 191.24 92.52C191.33 92.31 191.46 92.11 191.62 91.95C191.79 91.78 191.98 91.65 192.2 91.56C192.41 91.48 192.64 91.43 192.88 91.43C193.11 91.43 193.34 91.47 193.56 91.56C193.77 91.65 193.97 91.78 194.13 91.94C194.3 92.11 194.43 92.3 194.52 92.52C194.6 92.74 194.65 92.97 194.65 93.2C194.65 93.43 194.6 93.66 194.52 93.88C194.43 94.09 194.3 94.29 194.13 94.45C193.97 94.62 193.77 94.75 193.56 94.84C193.34 94.93 193.11 94.97 192.88 94.97Z" />
		<path id="  46" className="shp0" d="M192.89 106.28C192.66 106.28 192.43 106.24 192.21 106.15C191.99 106.06 191.8 105.93 191.63 105.77C191.47 105.61 191.34 105.41 191.25 105.19C191.16 104.98 191.12 104.75 191.12 104.52C191.12 104.28 191.16 104.05 191.25 103.84C191.34 103.62 191.47 103.42 191.63 103.26C191.8 103.1 191.99 102.97 192.21 102.88C192.42 102.79 192.65 102.74 192.89 102.75C193.12 102.74 193.35 102.79 193.57 102.88C193.78 102.96 193.98 103.09 194.14 103.26C194.31 103.42 194.44 103.62 194.52 103.83C194.61 104.05 194.66 104.28 194.66 104.51C194.66 104.75 194.61 104.98 194.53 105.19C194.44 105.41 194.31 105.6 194.14 105.77C193.98 105.93 193.78 106.06 193.57 106.15C193.35 106.24 193.12 106.28 192.89 106.28Z" />
		<path id="  47" className="shp0" d="M192.9 117.74C192.67 117.74 192.44 117.7 192.22 117.61C192 117.52 191.81 117.39 191.64 117.23C191.48 117.06 191.35 116.87 191.26 116.65C191.17 116.43 191.13 116.2 191.13 115.97C191.13 115.74 191.17 115.51 191.26 115.29C191.35 115.08 191.48 114.88 191.64 114.72C191.81 114.55 192 114.42 192.22 114.33C192.43 114.24 192.66 114.2 192.9 114.2C193.13 114.2 193.36 114.24 193.58 114.33C193.79 114.42 193.99 114.55 194.15 114.71C194.32 114.88 194.45 115.07 194.53 115.29C194.62 115.5 194.67 115.73 194.67 115.97C194.67 116.2 194.62 116.43 194.54 116.65C194.45 116.86 194.32 117.06 194.15 117.22C193.99 117.39 193.79 117.52 193.58 117.61C193.36 117.69 193.13 117.74 192.9 117.74L192.9 117.74Z" />
		<path id="  48" className="shp0" d="M192.91 129.05C192.68 129.05 192.45 129.01 192.23 128.92C192.01 128.83 191.82 128.7 191.65 128.54C191.49 128.37 191.36 128.18 191.27 127.96C191.18 127.75 191.14 127.52 191.14 127.28C191.14 127.05 191.18 126.82 191.27 126.61C191.36 126.39 191.49 126.19 191.65 126.03C191.82 125.86 192.01 125.73 192.23 125.65C192.44 125.56 192.67 125.51 192.91 125.52C193.14 125.51 193.37 125.56 193.58 125.64C193.8 125.73 194 125.86 194.16 126.03C194.33 126.19 194.46 126.39 194.54 126.6C194.63 126.82 194.68 127.05 194.68 127.28C194.68 127.51 194.63 127.75 194.55 127.96C194.46 128.18 194.33 128.37 194.16 128.54C194 128.7 193.8 128.83 193.59 128.92C193.37 129.01 193.14 129.05 192.91 129.05Z" />
		<path id="  49" className="shp0" d="M184.81 3.97C184.58 3.97 184.35 3.93 184.13 3.84C183.92 3.75 183.72 3.62 183.56 3.46C183.39 3.29 183.26 3.1 183.17 2.88C183.08 2.67 183.04 2.44 183.04 2.2C183.04 1.97 183.08 1.74 183.17 1.52C183.26 1.31 183.39 1.11 183.55 0.95C183.72 0.78 183.91 0.65 184.13 0.57C184.34 0.48 184.58 0.43 184.81 0.43C185.04 0.43 185.27 0.48 185.49 0.56C185.7 0.65 185.9 0.78 186.06 0.95C186.23 1.11 186.36 1.31 186.45 1.52C186.54 1.74 186.58 1.97 186.58 2.2C186.58 2.43 186.54 2.66 186.45 2.88C186.36 3.1 186.23 3.29 186.07 3.46C185.9 3.62 185.71 3.75 185.49 3.84C185.28 3.93 185.04 3.97 184.81 3.97Z" />
		<path id="  50" className="shp0" d="M183.62 14.79C182.91 14.09 182.91 12.95 183.62 12.24C184.32 11.54 185.46 11.54 186.16 12.24C186.87 12.95 186.87 14.08 186.16 14.79C185.46 15.49 184.32 15.49 183.62 14.79Z" />
		<path id="  51" className="shp0" d="M184.9 26.67C184.67 26.67 184.44 26.63 184.22 26.54C184.01 26.45 183.81 26.32 183.65 26.16C183.48 25.99 183.35 25.8 183.26 25.58C183.17 25.37 183.13 25.14 183.13 24.9C183.13 24.67 183.17 24.44 183.26 24.22C183.35 24.01 183.48 23.81 183.64 23.65C183.81 23.48 184 23.35 184.22 23.26C184.44 23.18 184.67 23.13 184.9 23.13C185.13 23.13 185.36 23.17 185.58 23.26C185.79 23.35 185.99 23.48 186.15 23.64C186.32 23.81 186.45 24 186.54 24.22C186.63 24.44 186.67 24.67 186.67 24.9C186.67 25.13 186.63 25.36 186.54 25.58C186.45 25.79 186.32 25.99 186.16 26.15C185.99 26.32 185.8 26.45 185.58 26.54C185.37 26.63 185.13 26.67 184.9 26.67Z" />
		<path id="  52" className="shp0" d="M184.84 38.05C184.61 38.06 184.38 38.01 184.16 37.92C183.95 37.84 183.75 37.71 183.59 37.54C183.42 37.38 183.29 37.18 183.2 36.97C183.11 36.75 183.07 36.52 183.07 36.29C183.07 36.05 183.11 35.82 183.2 35.61C183.29 35.39 183.42 35.2 183.58 35.03C183.75 34.87 183.94 34.74 184.16 34.65C184.37 34.56 184.6 34.52 184.84 34.52C185.07 34.51 185.3 34.56 185.52 34.65C185.73 34.73 185.93 34.86 186.09 35.03C186.26 35.19 186.39 35.39 186.48 35.6C186.56 35.82 186.61 36.05 186.61 36.28C186.61 36.52 186.57 36.75 186.48 36.96C186.39 37.18 186.26 37.37 186.1 37.54C185.93 37.7 185.74 37.83 185.52 37.92C185.3 38.01 185.07 38.06 184.84 38.05Z" />
		<path id="  53" className="shp0" d="M184.92 49.44C184.69 49.44 184.46 49.4 184.24 49.31C184.03 49.22 183.83 49.09 183.67 48.93C183.5 48.76 183.37 48.57 183.28 48.35C183.19 48.13 183.15 47.9 183.15 47.67C183.15 47.44 183.19 47.21 183.28 46.99C183.37 46.78 183.5 46.58 183.66 46.42C183.83 46.25 184.02 46.12 184.24 46.03C184.45 45.94 184.69 45.9 184.92 45.9C185.15 45.9 185.38 45.94 185.6 46.03C185.81 46.12 186.01 46.25 186.17 46.41C186.34 46.58 186.47 46.77 186.56 46.99C186.65 47.2 186.69 47.44 186.69 47.67C186.69 47.9 186.65 48.13 186.56 48.35C186.47 48.56 186.34 48.76 186.18 48.92C186.01 49.09 185.82 49.22 185.6 49.31C185.38 49.39 185.15 49.44 184.92 49.44Z" />
		<path id="  54" className="shp0" d="M184.86 60.82C184.63 60.82 184.4 60.78 184.18 60.69C183.97 60.6 183.77 60.47 183.6 60.31C183.44 60.15 183.31 59.95 183.22 59.73C183.13 59.52 183.09 59.29 183.09 59.06C183.09 58.82 183.13 58.59 183.22 58.38C183.31 58.16 183.44 57.96 183.6 57.8C183.77 57.64 183.96 57.51 184.18 57.42C184.39 57.33 184.62 57.28 184.86 57.29C185.09 57.28 185.32 57.33 185.54 57.42C185.75 57.5 185.95 57.63 186.11 57.8C186.28 57.96 186.41 58.16 186.5 58.37C186.58 58.59 186.63 58.82 186.63 59.05C186.63 59.29 186.59 59.52 186.5 59.73C186.41 59.95 186.28 60.14 186.11 60.31C185.95 60.47 185.75 60.6 185.54 60.69C185.32 60.78 185.09 60.82 184.86 60.82Z" />
		<path id="  55" className="shp0" d="M183.6 71.71C182.89 71.01 182.89 69.87 183.59 69.17C184.3 68.46 185.44 68.46 186.14 69.16C186.84 69.87 186.85 71.01 186.14 71.71C185.44 72.41 184.3 72.42 183.6 71.71Z" />
		<path id="  56" className="shp0" d="M184.88 83.59C184.65 83.59 184.42 83.55 184.2 83.46C183.98 83.37 183.79 83.24 183.62 83.08C183.46 82.91 183.33 82.72 183.24 82.5C183.15 82.29 183.11 82.06 183.11 81.82C183.11 81.59 183.15 81.36 183.24 81.15C183.33 80.93 183.46 80.73 183.62 80.57C183.79 80.4 183.98 80.27 184.2 80.19C184.41 80.1 184.64 80.05 184.88 80.06C185.11 80.05 185.34 80.1 185.56 80.18C185.77 80.27 185.97 80.4 186.13 80.57C186.3 80.73 186.43 80.93 186.52 81.14C186.6 81.36 186.65 81.59 186.65 81.82C186.65 82.05 186.6 82.29 186.52 82.5C186.43 82.72 186.3 82.91 186.13 83.08C185.97 83.24 185.77 83.37 185.56 83.46C185.34 83.55 185.11 83.59 184.88 83.59Z" />
		<path id="  57" className="shp0" d="M184.89 94.9C184.66 94.91 184.43 94.86 184.21 94.77C183.99 94.69 183.8 94.56 183.63 94.39C183.47 94.23 183.34 94.03 183.25 93.82C183.16 93.6 183.12 93.37 183.12 93.14C183.12 92.91 183.16 92.67 183.25 92.46C183.34 92.24 183.47 92.05 183.63 91.88C183.8 91.72 183.99 91.59 184.21 91.5C184.42 91.41 184.65 91.37 184.89 91.37C185.12 91.37 185.35 91.41 185.57 91.5C185.78 91.59 185.98 91.72 186.14 91.88C186.31 92.04 186.44 92.24 186.52 92.46C186.61 92.67 186.66 92.9 186.66 93.14C186.66 93.37 186.61 93.6 186.53 93.81C186.44 94.03 186.31 94.23 186.14 94.39C185.98 94.56 185.78 94.69 185.57 94.77C185.35 94.86 185.12 94.91 184.89 94.9L184.89 94.9Z" />
		<path id="  58" className="shp0" d="M184.97 106.29C184.74 106.29 184.51 106.25 184.29 106.16C184.07 106.07 183.88 105.94 183.71 105.78C183.55 105.61 183.42 105.42 183.33 105.2C183.24 104.99 183.2 104.76 183.2 104.52C183.2 104.29 183.24 104.06 183.33 103.84C183.42 103.63 183.55 103.43 183.71 103.27C183.88 103.1 184.07 102.97 184.29 102.88C184.5 102.8 184.73 102.75 184.97 102.75C185.2 102.75 185.43 102.79 185.65 102.88C185.86 102.97 186.06 103.1 186.22 103.26C186.39 103.43 186.52 103.62 186.61 103.84C186.69 104.06 186.74 104.29 186.74 104.52C186.74 104.75 186.69 104.98 186.61 105.2C186.52 105.41 186.39 105.61 186.22 105.78C186.06 105.94 185.86 106.07 185.65 106.16C185.43 106.25 185.2 106.29 184.97 106.29Z" />
		<path id="  59" className="shp0" d="M184.91 117.67C184.68 117.68 184.45 117.63 184.23 117.54C184.01 117.46 183.82 117.33 183.65 117.16C183.49 117 183.36 116.8 183.27 116.59C183.18 116.37 183.14 116.14 183.14 115.91C183.14 115.67 183.18 115.44 183.27 115.23C183.36 115.01 183.49 114.82 183.65 114.65C183.82 114.49 184.01 114.36 184.23 114.27C184.44 114.18 184.67 114.14 184.91 114.14C185.14 114.14 185.37 114.18 185.58 114.27C185.8 114.35 186 114.48 186.16 114.65C186.33 114.81 186.46 115.01 186.54 115.22C186.63 115.44 186.68 115.67 186.67 115.9C186.68 116.14 186.63 116.37 186.55 116.58C186.46 116.8 186.33 116.99 186.16 117.16C186 117.32 185.8 117.45 185.59 117.54C185.37 117.63 185.14 117.68 184.91 117.67Z" />
		<path id="  60" className="shp0" d="M183.65 128.56C182.94 127.86 182.94 126.72 183.64 126.02C184.35 125.31 185.48 125.31 186.19 126.02C186.89 126.72 186.89 127.86 186.19 128.56C185.49 129.27 184.35 129.27 183.65 128.56Z" />
		<path id="  61" className="shp0" d="M176.89 3.98C176.66 3.98 176.43 3.94 176.21 3.85C176 3.76 175.8 3.63 175.64 3.47C175.47 3.3 175.34 3.11 175.25 2.89C175.17 2.67 175.12 2.44 175.12 2.21C175.12 1.98 175.16 1.75 175.25 1.53C175.34 1.32 175.47 1.12 175.63 0.96C175.8 0.79 175.99 0.66 176.21 0.57C176.43 0.48 176.66 0.44 176.89 0.44C177.12 0.44 177.35 0.48 177.57 0.57C177.78 0.66 177.98 0.79 178.14 0.95C178.31 1.12 178.44 1.31 178.53 1.53C178.62 1.74 178.66 1.98 178.66 2.21C178.66 2.44 178.62 2.67 178.53 2.89C178.44 3.1 178.31 3.3 178.15 3.46C177.98 3.63 177.79 3.76 177.57 3.85C177.36 3.93 177.12 3.98 176.89 3.98Z" />
		<path id="  62" className="shp0" d="M176.9 15.29C176.67 15.29 176.44 15.25 176.22 15.16C176.01 15.07 175.81 14.94 175.65 14.78C175.48 14.61 175.35 14.42 175.26 14.2C175.17 13.99 175.13 13.76 175.13 13.52C175.13 13.29 175.17 13.06 175.26 12.85C175.35 12.63 175.48 12.43 175.64 12.27C175.81 12.1 176 11.97 176.22 11.89C176.43 11.8 176.67 11.75 176.9 11.76C177.13 11.75 177.36 11.8 177.58 11.88C177.79 11.97 177.99 12.1 178.15 12.27C178.32 12.43 178.45 12.63 178.54 12.84C178.63 13.06 178.67 13.29 178.67 13.52C178.67 13.75 178.63 13.99 178.54 14.2C178.45 14.42 178.32 14.61 178.16 14.78C177.99 14.94 177.8 15.07 177.58 15.16C177.37 15.25 177.13 15.29 176.9 15.29Z" />
		<path id="  63" className="shp0" d="M176.84 26.68C176.61 26.68 176.38 26.63 176.16 26.55C175.95 26.46 175.75 26.33 175.59 26.16C175.42 26 175.29 25.8 175.2 25.59C175.11 25.37 175.07 25.14 175.07 24.91C175.07 24.68 175.11 24.45 175.2 24.23C175.29 24.01 175.42 23.82 175.58 23.65C175.75 23.49 175.94 23.36 176.16 23.27C176.37 23.18 176.6 23.14 176.84 23.14C177.07 23.14 177.3 23.18 177.52 23.27C177.73 23.36 177.93 23.49 178.09 23.65C178.26 23.82 178.39 24.01 178.48 24.23C178.56 24.44 178.61 24.67 178.61 24.91C178.61 25.14 178.57 25.37 178.48 25.59C178.39 25.8 178.26 26 178.1 26.16C177.93 26.33 177.74 26.46 177.52 26.55C177.3 26.63 177.07 26.68 176.84 26.68Z" />
		<path id="  64" className="shp0" d="M176.92 38.06C176.69 38.06 176.46 38.02 176.24 37.93C176.03 37.84 175.83 37.71 175.67 37.55C175.5 37.38 175.37 37.19 175.28 36.97C175.19 36.76 175.15 36.53 175.15 36.29C175.15 36.06 175.19 35.83 175.28 35.61C175.37 35.4 175.5 35.2 175.66 35.04C175.83 34.87 176.02 34.74 176.24 34.65C176.45 34.57 176.69 34.52 176.92 34.52C177.15 34.52 177.38 34.57 177.6 34.65C177.81 34.74 178.01 34.87 178.17 35.04C178.34 35.2 178.47 35.4 178.56 35.61C178.64 35.83 178.69 36.06 178.69 36.29C178.69 36.52 178.65 36.75 178.56 36.97C178.47 37.19 178.34 37.38 178.18 37.55C178.01 37.71 177.82 37.84 177.6 37.93C177.38 38.02 177.15 38.06 176.92 38.06Z" />
		<path id="  65" className="shp0" d="M175.66 48.95C174.95 48.25 174.95 47.11 175.66 46.4C176.36 45.7 177.5 45.7 178.2 46.4C178.91 47.11 178.91 48.24 178.2 48.95C177.5 49.65 176.36 49.65 175.66 48.95Z" />
		<path id="  66" className="shp0" d="M176.94 60.83C176.71 60.83 176.48 60.79 176.26 60.7C176.05 60.61 175.85 60.48 175.69 60.32C175.52 60.15 175.39 59.96 175.3 59.74C175.21 59.53 175.17 59.3 175.17 59.06C175.17 58.83 175.21 58.6 175.3 58.38C175.39 58.17 175.52 57.97 175.68 57.81C175.85 57.64 176.04 57.51 176.26 57.42C176.47 57.34 176.7 57.29 176.94 57.29C177.17 57.29 177.4 57.33 177.62 57.42C177.83 57.51 178.03 57.64 178.19 57.8C178.36 57.97 178.49 58.16 178.58 58.38C178.66 58.6 178.71 58.83 178.71 59.06C178.71 59.29 178.67 59.52 178.58 59.74C178.49 59.95 178.36 60.15 178.2 60.31C178.03 60.48 177.83 60.61 177.62 60.7C177.4 60.79 177.17 60.83 176.94 60.83Z" />
		<path id="  67" className="shp0" d="M176.95 72.14C176.72 72.14 176.49 72.1 176.27 72.01C176.06 71.92 175.86 71.8 175.69 71.63C175.53 71.47 175.4 71.27 175.31 71.06C175.22 70.84 175.18 70.61 175.18 70.38C175.18 70.14 175.22 69.91 175.31 69.7C175.4 69.48 175.53 69.29 175.69 69.12C175.86 68.96 176.05 68.83 176.27 68.74C176.48 68.65 176.71 68.6 176.95 68.61C177.18 68.6 177.41 68.65 177.63 68.74C177.84 68.82 178.04 68.95 178.2 69.12C178.37 69.28 178.5 69.48 178.59 69.69C178.67 69.91 178.72 70.14 178.72 70.37C178.72 70.61 178.67 70.84 178.59 71.05C178.5 71.27 178.37 71.46 178.2 71.63C178.04 71.79 177.84 71.92 177.63 72.01C177.41 72.1 177.18 72.14 176.95 72.14Z" />
		<path id="  68" className="shp0" d="M176.96 83.6C176.73 83.6 176.5 83.56 176.28 83.47C176.07 83.38 175.87 83.25 175.7 83.09C175.54 82.92 175.41 82.73 175.32 82.51C175.23 82.29 175.19 82.06 175.19 81.83C175.19 81.6 175.23 81.37 175.32 81.15C175.41 80.94 175.54 80.74 175.7 80.58C175.87 80.41 176.06 80.28 176.28 80.19C176.49 80.1 176.72 80.06 176.96 80.06C177.19 80.06 177.42 80.1 177.64 80.19C177.85 80.28 178.05 80.41 178.21 80.57C178.38 80.74 178.51 80.93 178.6 81.15C178.68 81.36 178.73 81.6 178.73 81.83C178.73 82.06 178.68 82.29 178.6 82.51C178.51 82.72 178.38 82.92 178.21 83.08C178.05 83.25 177.85 83.38 177.64 83.47C177.42 83.56 177.19 83.6 176.96 83.6Z" />
		<path id="  69" className="shp0" d="M176.97 94.91C176.74 94.91 176.51 94.87 176.29 94.78C176.07 94.69 175.88 94.56 175.71 94.4C175.55 94.23 175.42 94.04 175.33 93.82C175.24 93.61 175.2 93.38 175.2 93.14C175.2 92.91 175.24 92.68 175.33 92.47C175.42 92.25 175.55 92.05 175.71 91.89C175.88 91.72 176.07 91.59 176.29 91.51C176.5 91.42 176.73 91.37 176.97 91.38C177.2 91.37 177.43 91.42 177.65 91.5C177.86 91.59 178.06 91.72 178.22 91.89C178.39 92.05 178.52 92.25 178.61 92.46C178.69 92.68 178.74 92.91 178.74 93.14C178.74 93.37 178.69 93.61 178.61 93.82C178.52 94.04 178.39 94.23 178.22 94.4C178.06 94.56 177.86 94.69 177.65 94.78C177.43 94.87 177.2 94.91 176.97 94.91Z" />
		<path id="  70" className="shp0" d="M175.71 105.8C175 105.1 175 103.96 175.7 103.26C176.41 102.55 177.55 102.55 178.25 103.25C178.95 103.96 178.96 105.09 178.25 105.8C177.55 106.5 176.41 106.5 175.71 105.8Z" />
		<path id="  71" className="shp0" d="M176.99 117.68C176.76 117.68 176.53 117.64 176.31 117.55C176.09 117.46 175.9 117.33 175.73 117.17C175.57 117 175.44 116.81 175.35 116.59C175.26 116.38 175.22 116.15 175.22 115.91C175.22 115.68 175.26 115.45 175.35 115.23C175.44 115.02 175.57 114.82 175.73 114.66C175.9 114.49 176.09 114.36 176.31 114.27C176.52 114.19 176.75 114.14 176.99 114.14C177.22 114.14 177.45 114.19 177.67 114.27C177.88 114.36 178.08 114.49 178.24 114.66C178.41 114.82 178.54 115.02 178.62 115.23C178.71 115.45 178.76 115.68 178.76 115.91C178.76 116.14 178.71 116.37 178.63 116.59C178.54 116.81 178.41 117 178.24 117.17C178.08 117.33 177.88 117.46 177.67 117.55C177.45 117.64 177.22 117.68 176.99 117.68Z" />
		<path id="  72" className="shp0" d="M176.93 129.06C176.7 129.07 176.46 129.02 176.25 128.93C176.03 128.85 175.84 128.72 175.67 128.55C175.51 128.39 175.38 128.19 175.29 127.98C175.2 127.76 175.16 127.53 175.16 127.3C175.16 127.07 175.2 126.83 175.29 126.62C175.38 126.4 175.51 126.21 175.67 126.04C175.84 125.88 176.03 125.75 176.25 125.66C176.46 125.57 176.69 125.53 176.93 125.53C177.16 125.53 177.39 125.57 177.6 125.66C177.82 125.75 178.02 125.88 178.18 126.04C178.35 126.21 178.48 126.4 178.56 126.62C178.65 126.83 178.7 127.06 178.69 127.3C178.7 127.53 178.65 127.76 178.57 127.97C178.48 128.19 178.35 128.39 178.18 128.55C178.02 128.72 177.82 128.85 177.61 128.93C177.39 129.02 177.16 129.07 176.93 129.06Z" />
		<path id="  73" className="shp0" d="M168.9 3.91C168.67 3.92 168.44 3.87 168.22 3.78C168.01 3.7 167.81 3.57 167.65 3.4C167.48 3.24 167.35 3.04 167.26 2.83C167.17 2.61 167.13 2.38 167.13 2.15C167.13 1.91 167.17 1.68 167.26 1.47C167.35 1.25 167.48 1.06 167.64 0.89C167.81 0.73 168 0.6 168.22 0.51C168.43 0.42 168.67 0.38 168.9 0.38C169.13 0.38 169.36 0.42 169.58 0.51C169.79 0.6 169.99 0.73 170.15 0.89C170.32 1.05 170.45 1.25 170.54 1.47C170.63 1.68 170.67 1.91 170.67 2.14C170.67 2.38 170.63 2.61 170.54 2.82C170.45 3.04 170.32 3.23 170.16 3.4C169.99 3.56 169.8 3.69 169.58 3.78C169.37 3.87 169.13 3.92 168.9 3.91Z" />
		<path id="  74" className="shp0" d="M168.91 15.37C168.68 15.37 168.45 15.33 168.23 15.24C168.02 15.15 167.82 15.02 167.66 14.86C167.49 14.69 167.36 14.5 167.27 14.28C167.18 14.07 167.14 13.83 167.14 13.6C167.14 13.37 167.18 13.14 167.27 12.92C167.36 12.71 167.49 12.51 167.65 12.35C167.82 12.18 168.01 12.05 168.23 11.96C168.44 11.88 168.68 11.83 168.91 11.83C169.14 11.83 169.37 11.87 169.59 11.96C169.8 12.05 170 12.18 170.16 12.34C170.33 12.51 170.46 12.7 170.55 12.92C170.64 13.14 170.68 13.37 170.68 13.6C170.68 13.83 170.64 14.06 170.55 14.28C170.46 14.49 170.33 14.69 170.17 14.85C170 15.02 169.81 15.15 169.59 15.24C169.38 15.33 169.14 15.37 168.91 15.37Z" />
		<path id="  75" className="shp0" d="M168.92 26.68C168.69 26.68 168.46 26.64 168.24 26.55C168.03 26.46 167.83 26.33 167.67 26.17C167.5 26.01 167.37 25.81 167.28 25.59C167.19 25.38 167.15 25.15 167.15 24.92C167.15 24.68 167.19 24.45 167.28 24.24C167.37 24.02 167.5 23.83 167.66 23.66C167.83 23.5 168.02 23.37 168.24 23.28C168.45 23.19 168.69 23.14 168.92 23.15C169.15 23.14 169.38 23.19 169.6 23.28C169.81 23.36 170.01 23.49 170.17 23.66C170.34 23.82 170.47 24.02 170.56 24.23C170.64 24.45 170.69 24.68 170.69 24.91C170.69 25.15 170.65 25.38 170.56 25.59C170.47 25.81 170.34 26 170.18 26.17C170.01 26.33 169.82 26.46 169.6 26.55C169.38 26.64 169.15 26.68 168.92 26.68Z" />
		<path id="  76" className="shp0" d="M169 38.07C168.77 38.07 168.54 38.02 168.32 37.94C168.11 37.85 167.91 37.72 167.75 37.55C167.58 37.39 167.45 37.19 167.36 36.98C167.27 36.76 167.23 36.53 167.23 36.3C167.23 36.07 167.27 35.84 167.36 35.62C167.45 35.41 167.58 35.21 167.74 35.04C167.91 34.88 168.1 34.75 168.32 34.66C168.53 34.57 168.77 34.53 169 34.53C169.23 34.53 169.46 34.57 169.68 34.66C169.89 34.75 170.09 34.88 170.25 35.04C170.42 35.21 170.55 35.4 170.64 35.62C170.73 35.83 170.77 36.06 170.77 36.3C170.77 36.53 170.73 36.76 170.64 36.98C170.55 37.19 170.42 37.39 170.26 37.55C170.09 37.72 169.9 37.85 169.68 37.94C169.47 38.02 169.23 38.07 169 38.07Z" />
		<path id="  77" className="shp0" d="M168.94 49.45C168.71 49.45 168.48 49.41 168.26 49.32C168.05 49.23 167.85 49.1 167.69 48.94C167.52 48.77 167.39 48.58 167.3 48.36C167.21 48.15 167.17 47.92 167.17 47.68C167.17 47.45 167.21 47.22 167.3 47.01C167.39 46.79 167.52 46.59 167.68 46.43C167.85 46.26 168.04 46.13 168.26 46.05C168.47 45.96 168.7 45.91 168.94 45.92C169.17 45.91 169.4 45.96 169.62 46.04C169.83 46.13 170.03 46.26 170.19 46.43C170.36 46.59 170.49 46.79 170.58 47C170.66 47.22 170.71 47.45 170.71 47.68C170.71 47.91 170.67 48.15 170.58 48.36C170.49 48.58 170.36 48.77 170.2 48.94C170.03 49.1 169.83 49.23 169.62 49.32C169.4 49.41 169.17 49.45 168.94 49.45Z" />
		<path id="  78" className="shp0" d="M168.95 60.76C168.72 60.77 168.49 60.72 168.27 60.64C168.06 60.55 167.86 60.42 167.69 60.25C167.53 60.09 167.4 59.89 167.31 59.68C167.22 59.46 167.18 59.23 167.18 59C167.18 58.77 167.22 58.53 167.31 58.32C167.4 58.1 167.53 57.91 167.69 57.74C167.86 57.58 168.05 57.45 168.27 57.36C168.48 57.27 168.71 57.23 168.95 57.23C169.18 57.23 169.41 57.27 169.63 57.36C169.84 57.45 170.04 57.58 170.2 57.74C170.37 57.91 170.5 58.1 170.59 58.32C170.67 58.53 170.72 58.76 170.72 59C170.72 59.23 170.67 59.46 170.59 59.67C170.5 59.89 170.37 60.09 170.2 60.25C170.04 60.42 169.84 60.55 169.63 60.63C169.41 60.72 169.18 60.77 168.95 60.76Z" />
		<path id="  79" className="shp0" d="M168.96 72.22C168.73 72.22 168.5 72.18 168.28 72.09C168.07 72 167.87 71.87 167.7 71.71C167.54 71.54 167.41 71.35 167.32 71.13C167.23 70.92 167.19 70.69 167.19 70.45C167.19 70.22 167.23 69.99 167.32 69.77C167.41 69.56 167.54 69.36 167.7 69.2C167.87 69.03 168.06 68.9 168.28 68.81C168.49 68.73 168.72 68.68 168.96 68.68C169.19 68.68 169.42 68.73 169.64 68.81C169.85 68.9 170.05 69.03 170.21 69.2C170.38 69.36 170.51 69.56 170.6 69.77C170.68 69.99 170.73 70.22 170.73 70.45C170.73 70.68 170.68 70.91 170.6 71.13C170.51 71.35 170.38 71.54 170.21 71.71C170.05 71.87 169.85 72 169.64 72.09C169.42 72.18 169.19 72.22 168.96 72.22Z" />
		<path id="  80" className="shp0" d="M168.97 83.53C168.74 83.54 168.51 83.49 168.29 83.4C168.07 83.32 167.88 83.19 167.71 83.02C167.55 82.86 167.42 82.66 167.33 82.45C167.24 82.23 167.2 82 167.2 81.77C167.2 81.53 167.24 81.3 167.33 81.09C167.42 80.87 167.55 80.68 167.71 80.51C167.88 80.35 168.07 80.22 168.29 80.13C168.5 80.04 168.73 80 168.97 80C169.2 80 169.43 80.04 169.65 80.13C169.86 80.22 170.06 80.35 170.22 80.51C170.39 80.67 170.52 80.87 170.61 81.09C170.69 81.3 170.74 81.53 170.74 81.76C170.74 82 170.69 82.23 170.61 82.44C170.52 82.66 170.39 82.85 170.22 83.02C170.06 83.18 169.86 83.31 169.65 83.4C169.43 83.49 169.2 83.54 168.97 83.53Z" />
		<path id="  81" className="shp0" d="M168.98 94.99C168.75 94.99 168.52 94.95 168.3 94.86C168.08 94.77 167.89 94.64 167.72 94.48C167.56 94.31 167.43 94.12 167.34 93.9C167.25 93.69 167.21 93.46 167.21 93.22C167.21 92.99 167.25 92.76 167.34 92.54C167.43 92.33 167.56 92.13 167.72 91.97C167.89 91.8 168.08 91.67 168.3 91.58C168.51 91.5 168.74 91.45 168.98 91.45C169.21 91.45 169.44 91.49 169.66 91.58C169.87 91.67 170.07 91.8 170.23 91.96C170.4 92.13 170.53 92.32 170.62 92.54C170.7 92.76 170.75 92.99 170.75 93.22C170.75 93.45 170.7 93.68 170.62 93.9C170.53 94.11 170.4 94.31 170.23 94.48C170.07 94.64 169.87 94.77 169.66 94.86C169.44 94.95 169.21 94.99 168.98 94.99L168.98 94.99Z" />
		<path id="  82" className="shp0" d="M167.79 105.81C167.08 105.11 167.08 103.97 167.78 103.26C168.49 102.56 169.63 102.56 170.33 103.26C171.03 103.96 171.04 105.1 170.33 105.81C169.63 106.51 168.49 106.51 167.79 105.81Z" />
		<path id="  83" className="shp0" d="M169 117.76C168.77 117.76 168.54 117.72 168.32 117.63C168.1 117.54 167.91 117.41 167.74 117.25C167.58 117.08 167.45 116.89 167.36 116.67C167.27 116.45 167.23 116.22 167.23 115.99C167.23 115.76 167.27 115.53 167.36 115.31C167.45 115.1 167.58 114.9 167.74 114.74C167.91 114.57 168.1 114.44 168.32 114.35C168.53 114.26 168.76 114.22 169 114.22C169.23 114.22 169.46 114.26 169.68 114.35C169.89 114.44 170.09 114.57 170.25 114.73C170.42 114.9 170.55 115.09 170.63 115.31C170.72 115.52 170.77 115.76 170.77 115.99C170.77 116.22 170.72 116.45 170.64 116.67C170.55 116.88 170.42 117.08 170.25 117.24C170.09 117.41 169.89 117.54 169.68 117.63C169.46 117.72 169.23 117.76 169 117.76Z" />
		<path id="  84" className="shp0" d="M169.01 129.07C168.78 129.07 168.54 129.03 168.33 128.94C168.11 128.85 167.92 128.72 167.75 128.56C167.59 128.4 167.46 128.2 167.37 127.98C167.28 127.77 167.24 127.54 167.24 127.3C167.24 127.07 167.28 126.84 167.37 126.63C167.46 126.41 167.59 126.21 167.75 126.05C167.92 125.88 168.11 125.75 168.33 125.67C168.54 125.58 168.77 125.53 169.01 125.54C169.24 125.53 169.47 125.58 169.68 125.67C169.9 125.75 170.1 125.88 170.26 126.05C170.43 126.21 170.56 126.41 170.64 126.62C170.73 126.84 170.78 127.07 170.77 127.3C170.78 127.53 170.73 127.77 170.65 127.98C170.56 128.2 170.43 128.39 170.26 128.56C170.1 128.72 169.9 128.85 169.69 128.94C169.47 129.03 169.24 129.07 169.01 129.07Z" />
		<path id="  85" className="shp0" d="M160.98 3.92C160.75 3.92 160.52 3.88 160.3 3.79C160.09 3.7 159.89 3.57 159.73 3.41C159.56 3.24 159.43 3.05 159.34 2.83C159.26 2.62 159.21 2.39 159.21 2.15C159.21 1.92 159.25 1.69 159.34 1.47C159.43 1.26 159.56 1.06 159.72 0.9C159.89 0.73 160.08 0.6 160.3 0.52C160.52 0.43 160.75 0.38 160.98 0.38C161.21 0.38 161.44 0.43 161.66 0.51C161.87 0.6 162.07 0.73 162.23 0.9C162.4 1.06 162.53 1.26 162.62 1.47C162.71 1.69 162.75 1.92 162.75 2.15C162.75 2.38 162.71 2.61 162.62 2.83C162.53 3.05 162.4 3.24 162.24 3.41C162.07 3.57 161.88 3.7 161.66 3.79C161.45 3.88 161.21 3.92 160.98 3.92Z" />
		<path id="  86" className="shp0" d="M159.72 14.81C159.01 14.11 159.01 12.97 159.72 12.27C160.42 11.56 161.56 11.56 162.26 12.26C162.97 12.97 162.97 14.1 162.26 14.81C161.56 15.51 160.42 15.51 159.72 14.81Z" />
		<path id="  87" className="shp0" d="M161 26.69C160.77 26.69 160.54 26.65 160.32 26.56C160.11 26.47 159.91 26.34 159.75 26.18C159.58 26.01 159.45 25.82 159.36 25.6C159.27 25.39 159.23 25.16 159.23 24.92C159.23 24.69 159.27 24.46 159.36 24.24C159.45 24.03 159.58 23.83 159.74 23.67C159.91 23.5 160.1 23.37 160.32 23.28C160.53 23.2 160.77 23.15 161 23.15C161.23 23.15 161.46 23.19 161.68 23.28C161.89 23.37 162.09 23.5 162.25 23.67C162.42 23.83 162.55 24.03 162.64 24.24C162.73 24.46 162.77 24.69 162.77 24.92C162.77 25.15 162.73 25.38 162.64 25.6C162.55 25.81 162.42 26.01 162.26 26.18C162.09 26.34 161.9 26.47 161.68 26.56C161.47 26.65 161.23 26.69 161 26.69Z" />
		<path id="  88" className="shp0" d="M160.94 38.07C160.71 38.08 160.48 38.03 160.26 37.94C160.05 37.86 159.85 37.73 159.69 37.56C159.52 37.4 159.39 37.2 159.3 36.99C159.21 36.77 159.17 36.54 159.17 36.31C159.17 36.07 159.21 35.84 159.3 35.63C159.39 35.41 159.52 35.22 159.68 35.05C159.85 34.89 160.04 34.76 160.26 34.67C160.47 34.58 160.7 34.54 160.94 34.54C161.17 34.54 161.4 34.58 161.62 34.67C161.83 34.76 162.03 34.89 162.19 35.05C162.36 35.21 162.49 35.41 162.58 35.63C162.66 35.84 162.71 36.07 162.71 36.3C162.71 36.54 162.67 36.77 162.58 36.98C162.49 37.2 162.36 37.4 162.2 37.56C162.03 37.72 161.84 37.85 161.62 37.94C161.4 38.03 161.17 38.08 160.94 38.07Z" />
		<path id="  89" className="shp0" d="M161.02 49.46C160.79 49.46 160.56 49.42 160.34 49.33C160.13 49.24 159.93 49.11 159.77 48.95C159.6 48.78 159.47 48.59 159.38 48.37C159.29 48.16 159.25 47.92 159.25 47.69C159.25 47.46 159.29 47.23 159.38 47.01C159.47 46.8 159.6 46.6 159.76 46.44C159.93 46.27 160.12 46.14 160.34 46.05C160.55 45.96 160.79 45.92 161.02 45.92C161.25 45.92 161.48 45.96 161.7 46.05C161.91 46.14 162.11 46.27 162.27 46.43C162.44 46.6 162.57 46.79 162.66 47.01C162.74 47.22 162.79 47.46 162.79 47.69C162.79 47.92 162.75 48.15 162.66 48.37C162.57 48.58 162.44 48.78 162.28 48.94C162.11 49.11 161.92 49.24 161.7 49.33C161.48 49.42 161.25 49.46 161.02 49.46Z" />
		<path id="  90" className="shp0" d="M160.96 60.84C160.73 60.84 160.5 60.8 160.28 60.71C160.07 60.62 159.87 60.49 159.7 60.33C159.54 60.17 159.41 59.97 159.32 59.75C159.23 59.54 159.19 59.31 159.19 59.08C159.19 58.84 159.23 58.61 159.32 58.4C159.41 58.18 159.54 57.99 159.7 57.82C159.87 57.66 160.06 57.53 160.28 57.44C160.49 57.35 160.72 57.3 160.96 57.31C161.19 57.3 161.42 57.35 161.64 57.44C161.85 57.52 162.05 57.65 162.21 57.82C162.38 57.98 162.51 58.18 162.6 58.39C162.68 58.61 162.73 58.84 162.73 59.07C162.73 59.31 162.68 59.54 162.6 59.75C162.51 59.97 162.38 60.16 162.21 60.33C162.05 60.49 161.85 60.62 161.64 60.71C161.42 60.8 161.19 60.84 160.96 60.84Z" />
		<path id="  91" className="shp0" d="M159.77 71.66C159.06 70.96 159.06 69.82 159.76 69.12C160.47 68.41 161.61 68.41 162.31 69.11C163.01 69.82 163.02 70.96 162.31 71.66C161.61 72.36 160.47 72.37 159.77 71.66Z" />
		<path id="  92" className="shp0" d="M160.98 83.61C160.75 83.61 160.52 83.57 160.3 83.48C160.08 83.39 159.89 83.26 159.72 83.1C159.56 82.93 159.43 82.74 159.34 82.52C159.25 82.31 159.21 82.08 159.21 81.84C159.21 81.61 159.25 81.38 159.34 81.17C159.43 80.95 159.56 80.75 159.72 80.59C159.89 80.42 160.08 80.29 160.3 80.21C160.51 80.12 160.74 80.07 160.98 80.08C161.21 80.07 161.44 80.12 161.66 80.2C161.87 80.29 162.07 80.42 162.23 80.59C162.4 80.75 162.53 80.95 162.62 81.16C162.7 81.38 162.75 81.61 162.75 81.84C162.75 82.07 162.7 82.31 162.62 82.52C162.53 82.74 162.4 82.93 162.23 83.1C162.07 83.26 161.87 83.39 161.66 83.48C161.44 83.57 161.21 83.61 160.98 83.61Z" />
		<path id="  93" className="shp0" d="M160.99 94.92C160.76 94.93 160.53 94.88 160.31 94.8C160.09 94.71 159.9 94.58 159.73 94.41C159.57 94.25 159.44 94.05 159.35 93.84C159.26 93.62 159.22 93.39 159.22 93.16C159.22 92.93 159.26 92.69 159.35 92.48C159.44 92.26 159.57 92.07 159.73 91.9C159.9 91.74 160.09 91.61 160.31 91.52C160.52 91.43 160.75 91.39 160.99 91.39C161.22 91.39 161.45 91.43 161.67 91.52C161.88 91.61 162.08 91.74 162.24 91.9C162.41 92.07 162.54 92.26 162.62 92.48C162.71 92.69 162.76 92.92 162.76 93.16C162.76 93.39 162.71 93.62 162.63 93.83C162.54 94.05 162.41 94.25 162.24 94.41C162.08 94.58 161.88 94.71 161.67 94.79C161.45 94.88 161.22 94.93 160.99 94.92Z" />
		<path id="  94" className="shp0" d="M161.07 106.31C160.84 106.31 160.61 106.27 160.39 106.18C160.17 106.09 159.98 105.96 159.81 105.8C159.65 105.63 159.52 105.44 159.43 105.22C159.34 105.01 159.3 104.78 159.3 104.54C159.3 104.31 159.34 104.08 159.43 103.86C159.52 103.65 159.65 103.45 159.81 103.29C159.98 103.12 160.17 102.99 160.39 102.9C160.6 102.82 160.83 102.77 161.07 102.77C161.3 102.77 161.53 102.82 161.75 102.9C161.96 102.99 162.16 103.12 162.32 103.29C162.49 103.45 162.62 103.65 162.71 103.86C162.79 104.08 162.84 104.31 162.84 104.54C162.84 104.77 162.79 105 162.71 105.22C162.62 105.43 162.49 105.63 162.32 105.8C162.16 105.96 161.96 106.09 161.75 106.18C161.53 106.27 161.3 106.31 161.07 106.31Z" />
		<path id="  95" className="shp0" d="M161.01 117.69C160.78 117.7 160.54 117.65 160.33 117.56C160.11 117.48 159.92 117.35 159.75 117.18C159.59 117.02 159.46 116.82 159.37 116.61C159.28 116.39 159.24 116.16 159.24 115.93C159.24 115.69 159.28 115.46 159.37 115.25C159.46 115.03 159.59 114.84 159.75 114.67C159.92 114.51 160.11 114.38 160.33 114.29C160.54 114.2 160.77 114.16 161.01 114.16C161.24 114.16 161.47 114.2 161.68 114.29C161.9 114.38 162.1 114.51 162.26 114.67C162.43 114.83 162.56 115.03 162.64 115.25C162.73 115.46 162.78 115.69 162.77 115.92C162.78 116.16 162.73 116.39 162.65 116.6C162.56 116.82 162.43 117.01 162.26 117.18C162.1 117.34 161.9 117.47 161.69 117.56C161.47 117.65 161.24 117.7 161.01 117.69Z" />
		<path id="  96" className="shp0" d="M159.75 128.58C159.04 127.88 159.04 126.74 159.74 126.04C160.45 125.33 161.58 125.33 162.29 126.04C162.99 126.74 162.99 127.88 162.29 128.58C161.59 129.29 160.45 129.29 159.75 128.58Z" />
		<path id="  97" className="shp0" d="M152.99 4C152.76 4 152.53 3.96 152.31 3.87C152.1 3.78 151.9 3.65 151.74 3.49C151.57 3.32 151.44 3.13 151.35 2.91C151.26 2.69 151.22 2.46 151.22 2.23C151.22 2 151.26 1.77 151.35 1.55C151.44 1.34 151.57 1.14 151.73 0.98C151.9 0.81 152.09 0.68 152.31 0.59C152.53 0.5 152.76 0.46 152.99 0.46C153.22 0.46 153.45 0.5 153.67 0.59C153.88 0.68 154.08 0.81 154.24 0.97C154.41 1.14 154.54 1.33 154.63 1.55C154.72 1.76 154.76 2 154.76 2.23C154.76 2.46 154.72 2.69 154.63 2.91C154.54 3.12 154.41 3.32 154.25 3.48C154.08 3.65 153.89 3.78 153.67 3.87C153.46 3.96 153.22 4 152.99 4Z" />
		<path id="  98" className="shp0" d="M151.66 14.82C150.95 14.11 150.95 12.98 151.66 12.27C152.36 11.57 153.5 11.57 154.2 12.27C154.91 12.97 154.91 14.11 154.2 14.82C153.5 15.52 152.36 15.52 151.66 14.82Z" />
		<path id="  99" className="shp0" d="M153.01 26.77C152.78 26.77 152.55 26.72 152.33 26.64C152.12 26.55 151.92 26.42 151.76 26.25C151.59 26.09 151.46 25.89 151.37 25.68C151.28 25.46 151.24 25.23 151.24 25C151.24 24.77 151.28 24.54 151.37 24.32C151.46 24.11 151.59 23.91 151.75 23.74C151.92 23.58 152.11 23.45 152.33 23.36C152.54 23.27 152.78 23.23 153.01 23.23C153.24 23.23 153.47 23.27 153.69 23.36C153.9 23.45 154.1 23.58 154.26 23.74C154.43 23.91 154.56 24.1 154.65 24.32C154.74 24.53 154.78 24.76 154.78 25C154.76 25.22 154.7 25.44 154.6 25.65C154.5 25.85 154.37 26.04 154.21 26.2C154.05 26.36 153.87 26.49 153.66 26.59C153.46 26.68 153.24 26.75 153.01 26.77Z" />
		<path id="  100" className="shp0" d="M153.02 38.08C152.79 38.08 152.56 38.04 152.34 37.95C152.13 37.86 151.93 37.73 151.77 37.57C151.6 37.4 151.47 37.21 151.38 36.99C151.29 36.78 151.25 36.55 151.25 36.31C151.25 36.08 151.29 35.85 151.38 35.63C151.47 35.42 151.6 35.22 151.76 35.06C151.93 34.89 152.12 34.76 152.34 34.68C152.55 34.59 152.79 34.54 153.02 34.54C153.25 34.54 153.48 34.59 153.7 34.67C153.91 34.76 154.11 34.89 154.27 35.06C154.44 35.22 154.57 35.42 154.66 35.63C154.74 35.85 154.79 36.08 154.79 36.31C154.79 36.54 154.75 36.77 154.66 36.99C154.57 37.21 154.44 37.4 154.28 37.57C154.11 37.73 153.92 37.86 153.7 37.95C153.48 38.04 153.25 38.08 153.02 38.08Z" />
		<path id="  101" className="shp0" d="M152.96 49.46C152.73 49.47 152.5 49.42 152.28 49.34C152.07 49.25 151.87 49.12 151.7 48.95C151.54 48.79 151.41 48.59 151.32 48.38C151.23 48.16 151.19 47.93 151.19 47.7C151.19 47.47 151.23 47.23 151.32 47.02C151.41 46.8 151.54 46.61 151.7 46.44C151.87 46.28 152.06 46.15 152.28 46.06C152.49 45.97 152.72 45.93 152.96 45.93C153.19 45.93 153.42 45.97 153.64 46.06C153.85 46.15 154.05 46.28 154.21 46.44C154.38 46.61 154.51 46.8 154.6 47.02C154.68 47.23 154.73 47.46 154.73 47.7C154.73 47.93 154.68 48.16 154.6 48.37C154.51 48.59 154.38 48.79 154.21 48.95C154.05 49.12 153.85 49.25 153.64 49.33C153.42 49.42 153.19 49.47 152.96 49.46Z" />
		<path id="  102" className="shp0" d="M153.04 60.85C152.81 60.85 152.58 60.81 152.36 60.72C152.15 60.63 151.95 60.5 151.78 60.34C151.62 60.17 151.49 59.98 151.4 59.76C151.31 59.55 151.27 59.32 151.27 59.08C151.27 58.85 151.31 58.62 151.4 58.4C151.49 58.19 151.62 57.99 151.78 57.83C151.95 57.66 152.14 57.53 152.36 57.44C152.57 57.36 152.8 57.31 153.04 57.31C153.27 57.31 153.5 57.36 153.72 57.44C153.93 57.53 154.13 57.66 154.29 57.83C154.46 57.99 154.59 58.19 154.68 58.4C154.76 58.62 154.81 58.85 154.81 59.08C154.81 59.31 154.77 59.54 154.68 59.76C154.59 59.97 154.46 60.17 154.29 60.34C154.13 60.5 153.93 60.63 153.72 60.72C153.5 60.81 153.27 60.85 153.04 60.85Z" />
		<path id="  103" className="shp0" d="M153.05 72.16C152.82 72.17 152.59 72.12 152.37 72.03C152.16 71.95 151.96 71.82 151.79 71.65C151.63 71.49 151.5 71.29 151.41 71.08C151.32 70.86 151.28 70.63 151.28 70.4C151.28 70.16 151.32 69.93 151.41 69.72C151.5 69.5 151.63 69.31 151.79 69.14C151.96 68.98 152.15 68.85 152.37 68.76C152.58 68.67 152.81 68.63 153.05 68.63C153.28 68.62 153.51 68.67 153.73 68.76C153.94 68.84 154.14 68.97 154.3 69.14C154.47 69.3 154.6 69.5 154.69 69.71C154.77 69.93 154.82 70.16 154.82 70.39C154.82 70.63 154.77 70.86 154.69 71.07C154.6 71.29 154.47 71.48 154.3 71.65C154.14 71.81 153.94 71.94 153.73 72.03C153.51 72.12 153.28 72.16 153.05 72.16Z" />
		<path id="  104" className="shp0" d="M153.06 83.62C152.83 83.62 152.6 83.58 152.38 83.49C152.16 83.4 151.97 83.27 151.8 83.11C151.64 82.94 151.51 82.75 151.42 82.53C151.33 82.32 151.29 82.08 151.29 81.85C151.29 81.62 151.33 81.39 151.42 81.17C151.51 80.96 151.64 80.76 151.8 80.6C151.97 80.43 152.16 80.3 152.38 80.21C152.59 80.12 152.82 80.08 153.06 80.08C153.29 80.08 153.52 80.12 153.74 80.21C153.95 80.3 154.15 80.43 154.31 80.59C154.48 80.76 154.61 80.95 154.7 81.17C154.78 81.38 154.83 81.62 154.83 81.85C154.83 82.08 154.78 82.31 154.7 82.53C154.61 82.74 154.48 82.94 154.31 83.1C154.15 83.27 153.95 83.4 153.74 83.49C153.52 83.58 153.29 83.62 153.06 83.62L153.06 83.62Z" />
		<path id="  105" className="shp0" d="M153.07 94.93C152.84 94.93 152.61 94.89 152.39 94.8C152.17 94.71 151.98 94.58 151.81 94.42C151.65 94.26 151.52 94.06 151.43 93.84C151.34 93.63 151.3 93.4 151.3 93.17C151.3 92.93 151.34 92.7 151.43 92.49C151.52 92.27 151.65 92.07 151.81 91.91C151.98 91.75 152.17 91.61 152.39 91.53C152.6 91.44 152.83 91.39 153.07 91.4C153.3 91.39 153.53 91.44 153.75 91.53C153.96 91.61 154.16 91.74 154.32 91.91C154.49 92.07 154.62 92.27 154.71 92.48C154.79 92.7 154.84 92.93 154.84 93.16C154.84 93.4 154.79 93.63 154.71 93.84C154.62 94.06 154.49 94.25 154.32 94.42C154.16 94.58 153.96 94.71 153.75 94.8C153.53 94.89 153.3 94.93 153.07 94.93Z" />
		<path id="  106" className="shp0" d="M153.08 106.39C152.85 106.39 152.62 106.35 152.4 106.26C152.18 106.17 151.99 106.04 151.82 105.87C151.66 105.71 151.53 105.51 151.44 105.3C151.35 105.08 151.31 104.85 151.31 104.62C151.31 104.39 151.35 104.16 151.44 103.94C151.53 103.73 151.66 103.53 151.82 103.36C151.99 103.2 152.18 103.07 152.4 102.98C152.61 102.89 152.84 102.85 153.08 102.85C153.31 102.85 153.54 102.89 153.76 102.98C153.97 103.07 154.17 103.2 154.33 103.36C154.5 103.53 154.63 103.72 154.71 103.94C154.8 104.15 154.85 104.38 154.85 104.62C154.85 104.85 154.8 105.08 154.72 105.3C154.63 105.51 154.5 105.71 154.33 105.87C154.17 106.04 153.97 106.17 153.76 106.26C153.54 106.34 153.31 106.39 153.08 106.39Z" />
		<path id="  107" className="shp0" d="M153.09 117.7C152.86 117.7 152.63 117.66 152.41 117.57C152.19 117.48 152 117.35 151.83 117.19C151.67 117.02 151.54 116.83 151.45 116.61C151.36 116.4 151.32 116.17 151.32 115.93C151.32 115.7 151.36 115.47 151.45 115.25C151.54 115.04 151.67 114.84 151.83 114.68C152 114.51 152.19 114.38 152.41 114.3C152.62 114.21 152.85 114.16 153.09 114.16C153.32 114.16 153.55 114.21 153.77 114.29C153.98 114.38 154.18 114.51 154.34 114.68C154.51 114.84 154.64 115.04 154.72 115.25C154.81 115.47 154.86 115.7 154.86 115.93C154.86 116.16 154.81 116.39 154.73 116.61C154.64 116.83 154.51 117.02 154.34 117.19C154.18 117.35 153.98 117.48 153.77 117.57C153.55 117.66 153.32 117.7 153.09 117.7Z" />
		<path id="  108" className="shp0" d="M153.03 129.08C152.8 129.09 152.56 129.04 152.35 128.96C152.13 128.87 151.94 128.74 151.77 128.57C151.61 128.41 151.48 128.21 151.39 128C151.3 127.78 151.26 127.55 151.26 127.32C151.26 127.09 151.3 126.85 151.39 126.64C151.48 126.42 151.61 126.23 151.77 126.06C151.93 125.9 152.13 125.77 152.35 125.68C152.56 125.59 152.79 125.55 153.02 125.55C153.26 125.55 153.49 125.59 153.7 125.68C153.92 125.77 154.12 125.9 154.28 126.06C154.45 126.23 154.58 126.42 154.66 126.64C154.75 126.85 154.8 127.08 154.79 127.32C154.8 127.55 154.75 127.78 154.67 127.99C154.58 128.21 154.45 128.41 154.28 128.57C154.12 128.74 153.92 128.87 153.71 128.95C153.49 129.04 153.26 129.09 153.03 129.08Z" />
		<path id="  109" className="shp0" d="M145 3.93C144.77 3.94 144.54 3.89 144.32 3.8C144.11 3.72 143.91 3.59 143.75 3.42C143.58 3.26 143.45 3.06 143.36 2.85C143.27 2.63 143.23 2.4 143.23 2.17C143.23 1.93 143.27 1.7 143.36 1.49C143.45 1.27 143.58 1.08 143.74 0.91C143.91 0.75 144.1 0.62 144.32 0.53C144.53 0.44 144.77 0.4 145 0.4C145.23 0.4 145.46 0.44 145.68 0.53C145.89 0.62 146.09 0.75 146.25 0.91C146.42 1.07 146.55 1.27 146.64 1.49C146.73 1.7 146.77 1.93 146.77 2.16C146.77 2.4 146.73 2.63 146.64 2.84C146.55 3.06 146.42 3.26 146.26 3.42C146.09 3.58 145.9 3.71 145.68 3.8C145.47 3.89 145.23 3.94 145 3.93Z" />
		<path id="  110" className="shp0" d="M145.08 15.32C144.85 15.32 144.62 15.28 144.4 15.19C144.19 15.1 143.99 14.97 143.83 14.81C143.66 14.64 143.53 14.45 143.44 14.23C143.35 14.02 143.31 13.78 143.31 13.55C143.31 13.32 143.35 13.09 143.44 12.87C143.53 12.66 143.66 12.46 143.82 12.3C143.99 12.13 144.18 12 144.4 11.91C144.62 11.82 144.85 11.78 145.08 11.78C145.31 11.78 145.54 11.82 145.76 11.91C145.97 12 146.17 12.13 146.33 12.29C146.5 12.46 146.63 12.65 146.72 12.87C146.81 13.09 146.85 13.32 146.85 13.55C146.85 13.78 146.81 14.01 146.72 14.23C146.63 14.44 146.5 14.64 146.34 14.8C146.17 14.97 145.98 15.1 145.76 15.19C145.55 15.28 145.31 15.32 145.08 15.32Z" />
		<path id="  111" className="shp0" d="M145.02 26.7C144.79 26.71 144.56 26.66 144.34 26.57C144.13 26.49 143.93 26.36 143.77 26.19C143.6 26.03 143.47 25.83 143.38 25.62C143.29 25.4 143.25 25.17 143.25 24.94C143.25 24.7 143.29 24.47 143.38 24.26C143.47 24.04 143.6 23.85 143.76 23.68C143.93 23.52 144.12 23.39 144.34 23.3C144.55 23.21 144.79 23.16 145.02 23.17C145.25 23.16 145.48 23.21 145.7 23.3C145.91 23.38 146.11 23.51 146.27 23.68C146.44 23.84 146.57 24.04 146.66 24.25C146.74 24.47 146.79 24.7 146.79 24.93C146.79 25.17 146.75 25.4 146.66 25.61C146.57 25.83 146.44 26.02 146.28 26.19C146.11 26.35 145.92 26.48 145.7 26.57C145.48 26.66 145.25 26.7 145.02 26.7Z" />
		<path id="  112" className="shp0" d="M143.76 37.59C143.05 36.89 143.05 35.75 143.76 35.05C144.46 34.34 145.6 34.34 146.3 35.05C147.01 35.75 147.01 36.89 146.3 37.59C145.6 38.3 144.46 38.3 143.76 37.59Z" />
		<path id="  113" className="shp0" d="M143.77 48.91C143.06 48.2 143.06 47.07 143.76 46.36C144.47 45.66 145.61 45.66 146.31 46.36C147.01 47.06 147.02 48.2 146.31 48.9C145.61 49.61 144.47 49.61 143.77 48.91Z" />
		<path id="  114" className="shp0" d="M145.12 60.86C144.89 60.86 144.66 60.81 144.44 60.73C144.23 60.64 144.03 60.51 143.87 60.34C143.7 60.18 143.57 59.98 143.48 59.77C143.39 59.55 143.35 59.32 143.35 59.09C143.35 58.86 143.39 58.63 143.48 58.41C143.57 58.19 143.7 58 143.86 57.83C144.03 57.67 144.22 57.54 144.44 57.45C144.65 57.36 144.88 57.32 145.12 57.32C145.35 57.32 145.58 57.36 145.8 57.45C146.01 57.54 146.21 57.67 146.37 57.83C146.54 58 146.67 58.19 146.76 58.41C146.84 58.62 146.89 58.85 146.89 59.09C146.89 59.32 146.85 59.55 146.76 59.77C146.67 59.98 146.54 60.18 146.38 60.34C146.21 60.51 146.02 60.64 145.8 60.73C145.58 60.81 145.35 60.86 145.12 60.86Z" />
		<path id="  115" className="shp0" d="M145.06 72.24C144.83 72.24 144.6 72.2 144.38 72.11C144.16 72.02 143.97 71.89 143.8 71.73C143.64 71.56 143.51 71.37 143.42 71.15C143.33 70.94 143.29 70.71 143.29 70.47C143.29 70.24 143.33 70.01 143.42 69.79C143.51 69.58 143.64 69.38 143.8 69.22C143.97 69.05 144.16 68.92 144.38 68.84C144.59 68.75 144.82 68.7 145.06 68.7C145.29 68.7 145.52 68.75 145.74 68.83C145.95 68.92 146.15 69.05 146.31 69.22C146.48 69.38 146.61 69.58 146.7 69.79C146.78 70.01 146.83 70.24 146.83 70.47C146.83 70.7 146.78 70.93 146.7 71.15C146.61 71.37 146.48 71.56 146.31 71.73C146.15 71.89 145.95 72.02 145.74 72.11C145.52 72.2 145.29 72.24 145.06 72.24L145.06 72.24Z" />
		<path id="  116" className="shp0" d="M145.07 83.55C144.84 83.56 144.61 83.51 144.39 83.42C144.17 83.34 143.98 83.21 143.81 83.04C143.65 82.88 143.52 82.68 143.43 82.47C143.34 82.25 143.3 82.02 143.3 81.79C143.3 81.55 143.34 81.32 143.43 81.11C143.52 80.89 143.65 80.7 143.81 80.53C143.98 80.37 144.17 80.24 144.39 80.15C144.6 80.06 144.83 80.02 145.07 80.02C145.3 80.02 145.53 80.06 145.75 80.15C145.96 80.24 146.16 80.37 146.32 80.53C146.49 80.69 146.62 80.89 146.71 81.11C146.79 81.32 146.84 81.55 146.84 81.78C146.84 82.02 146.79 82.25 146.71 82.46C146.62 82.68 146.49 82.88 146.32 83.04C146.16 83.2 145.96 83.34 145.75 83.42C145.53 83.51 145.3 83.56 145.07 83.55Z" />
		<path id="  117" className="shp0" d="M145.08 95.01C144.85 95.01 144.62 94.97 144.4 94.88C144.18 94.79 143.99 94.66 143.82 94.5C143.66 94.33 143.53 94.14 143.44 93.92C143.35 93.71 143.31 93.48 143.31 93.24C143.31 93.01 143.35 92.78 143.44 92.56C143.53 92.35 143.66 92.15 143.82 91.99C143.99 91.82 144.18 91.69 144.4 91.6C144.61 91.52 144.84 91.47 145.08 91.47C145.31 91.47 145.54 91.52 145.76 91.6C145.97 91.69 146.17 91.82 146.33 91.99C146.5 92.15 146.63 92.35 146.71 92.56C146.8 92.78 146.85 93.01 146.85 93.24C146.85 93.47 146.8 93.7 146.72 93.92C146.63 94.13 146.5 94.33 146.33 94.5C146.17 94.66 145.97 94.79 145.76 94.88C145.54 94.97 145.31 95.01 145.08 95.01Z" />
		<path id="  118" className="shp0" d="M143.89 105.83C143.18 105.13 143.18 103.99 143.88 103.28C144.59 102.58 145.73 102.58 146.43 103.28C147.13 103.98 147.14 105.12 146.43 105.83C145.73 106.53 144.59 106.53 143.89 105.83Z" />
		<path id="  119" className="shp0" d="M145.17 117.71C144.94 117.71 144.71 117.67 144.49 117.58C144.27 117.49 144.08 117.36 143.91 117.2C143.75 117.03 143.62 116.84 143.53 116.62C143.44 116.4 143.4 116.17 143.4 115.94C143.4 115.71 143.44 115.48 143.53 115.26C143.62 115.05 143.75 114.85 143.91 114.69C144.08 114.52 144.27 114.39 144.49 114.3C144.7 114.21 144.93 114.17 145.17 114.17C145.4 114.17 145.63 114.21 145.85 114.3C146.06 114.39 146.26 114.52 146.42 114.68C146.59 114.85 146.72 115.04 146.81 115.26C146.89 115.47 146.94 115.71 146.94 115.94C146.94 116.17 146.89 116.4 146.81 116.62C146.72 116.83 146.59 117.03 146.42 117.19C146.26 117.36 146.06 117.49 145.85 117.58C145.63 117.66 145.4 117.71 145.17 117.71Z" />
		<path id="  120" className="shp0" d="M145.11 129.09C144.88 129.09 144.64 129.05 144.43 128.96C144.21 128.87 144.02 128.74 143.85 128.58C143.69 128.42 143.56 128.22 143.47 128C143.38 127.79 143.34 127.56 143.34 127.33C143.34 127.09 143.38 126.86 143.47 126.65C143.56 126.43 143.69 126.23 143.85 126.07C144.02 125.91 144.21 125.77 144.43 125.69C144.64 125.6 144.87 125.55 145.11 125.56C145.34 125.55 145.57 125.6 145.78 125.69C146 125.77 146.2 125.9 146.36 126.07C146.53 126.23 146.66 126.43 146.74 126.64C146.83 126.86 146.88 127.09 146.87 127.32C146.88 127.56 146.83 127.79 146.75 128C146.66 128.22 146.53 128.41 146.36 128.58C146.2 128.74 146 128.87 145.79 128.96C145.57 129.05 145.34 129.09 145.11 129.09Z" />
		<path id="  121" className="shp0" d="M137.08 3.94C136.85 3.94 136.62 3.9 136.4 3.81C136.19 3.72 135.99 3.59 135.83 3.43C135.66 3.26 135.53 3.07 135.44 2.85C135.35 2.64 135.31 2.41 135.31 2.17C135.31 1.94 135.35 1.71 135.44 1.5C135.53 1.28 135.66 1.08 135.82 0.92C135.99 0.75 136.18 0.62 136.4 0.54C136.62 0.45 136.85 0.4 137.08 0.4C137.31 0.4 137.54 0.45 137.76 0.53C137.97 0.62 138.17 0.75 138.33 0.92C138.5 1.08 138.63 1.28 138.72 1.49C138.81 1.71 138.85 1.94 138.85 2.17C138.85 2.4 138.81 2.63 138.72 2.85C138.63 3.07 138.5 3.26 138.34 3.43C138.17 3.59 137.98 3.72 137.76 3.81C137.55 3.9 137.31 3.94 137.08 3.94Z" />
		<path id="  122" className="shp0" d="M137.02 15.32C136.79 15.33 136.56 15.28 136.34 15.2C136.13 15.11 135.93 14.98 135.77 14.81C135.6 14.65 135.47 14.45 135.38 14.24C135.29 14.02 135.25 13.79 135.25 13.56C135.25 13.33 135.29 13.1 135.38 12.88C135.47 12.66 135.6 12.47 135.76 12.3C135.93 12.14 136.12 12.01 136.34 11.92C136.55 11.83 136.79 11.79 137.02 11.79C137.25 11.79 137.48 11.83 137.7 11.92C137.91 12.01 138.11 12.14 138.27 12.3C138.44 12.47 138.57 12.66 138.66 12.88C138.74 13.09 138.79 13.32 138.79 13.56C138.79 13.79 138.75 14.02 138.66 14.23C138.57 14.45 138.44 14.65 138.28 14.81C138.11 14.98 137.92 15.11 137.7 15.19C137.48 15.28 137.25 15.33 137.02 15.32Z" />
		<path id="  123" className="shp0" d="M137.1 26.71C136.87 26.71 136.64 26.67 136.42 26.58C136.21 26.49 136.01 26.36 135.85 26.2C135.68 26.03 135.55 25.84 135.46 25.62C135.37 25.41 135.33 25.18 135.33 24.94C135.33 24.71 135.37 24.48 135.46 24.26C135.55 24.05 135.68 23.85 135.84 23.69C136.01 23.52 136.2 23.39 136.42 23.3C136.63 23.22 136.87 23.17 137.1 23.17C137.33 23.17 137.56 23.22 137.78 23.3C137.99 23.39 138.19 23.52 138.35 23.69C138.52 23.85 138.65 24.05 138.74 24.26C138.83 24.48 138.87 24.71 138.87 24.94C138.87 25.17 138.83 25.4 138.74 25.62C138.65 25.83 138.52 26.03 138.36 26.2C138.19 26.36 138 26.49 137.78 26.58C137.56 26.67 137.33 26.71 137.1 26.71Z" />
		<path id="  124" className="shp0" d="M137.04 38.09C136.81 38.1 136.58 38.05 136.36 37.96C136.15 37.88 135.95 37.75 135.78 37.58C135.62 37.42 135.49 37.22 135.4 37.01C135.31 36.79 135.27 36.56 135.27 36.33C135.27 36.09 135.31 35.86 135.4 35.65C135.49 35.43 135.62 35.24 135.78 35.07C135.95 34.91 136.14 34.78 136.36 34.69C136.57 34.6 136.8 34.56 137.04 34.56C137.27 34.56 137.5 34.6 137.72 34.69C137.93 34.78 138.13 34.91 138.29 35.07C138.46 35.23 138.59 35.43 138.68 35.65C138.76 35.86 138.81 36.09 138.81 36.32C138.81 36.56 138.77 36.79 138.68 37C138.59 37.22 138.46 37.42 138.3 37.58C138.13 37.75 137.93 37.88 137.72 37.96C137.5 38.05 137.27 38.1 137.04 38.09Z" />
		<path id="  125" className="shp0" d="M137.12 49.48C136.89 49.48 136.66 49.44 136.44 49.35C136.23 49.26 136.03 49.13 135.87 48.97C135.7 48.8 135.57 48.61 135.48 48.39C135.39 48.18 135.35 47.94 135.35 47.71C135.35 47.48 135.39 47.25 135.48 47.03C135.57 46.82 135.7 46.62 135.86 46.46C136.03 46.29 136.22 46.16 136.44 46.07C136.65 45.98 136.88 45.94 137.12 45.94C137.35 45.94 137.58 45.98 137.8 46.07C138.01 46.16 138.21 46.29 138.37 46.45C138.54 46.62 138.67 46.81 138.76 47.03C138.84 47.25 138.89 47.48 138.89 47.71C138.89 47.94 138.85 48.17 138.76 48.39C138.67 48.6 138.54 48.8 138.38 48.96C138.21 49.13 138.02 49.26 137.8 49.35C137.58 49.44 137.35 49.48 137.12 49.48Z" />
		<path id="  126" className="shp0" d="M137.06 60.86C136.83 60.87 136.6 60.82 136.38 60.73C136.16 60.65 135.97 60.52 135.8 60.35C135.64 60.19 135.51 59.99 135.42 59.78C135.33 59.56 135.29 59.33 135.29 59.1C135.29 58.86 135.33 58.63 135.42 58.42C135.51 58.2 135.64 58.01 135.8 57.84C135.97 57.68 136.16 57.55 136.38 57.46C136.59 57.37 136.82 57.33 137.06 57.33C137.29 57.32 137.52 57.37 137.74 57.46C137.95 57.54 138.15 57.67 138.31 57.84C138.48 58 138.61 58.2 138.7 58.41C138.78 58.63 138.83 58.86 138.83 59.09C138.83 59.33 138.78 59.56 138.7 59.77C138.61 59.99 138.48 60.18 138.31 60.35C138.15 60.51 137.95 60.64 137.74 60.73C137.52 60.82 137.29 60.86 137.06 60.86Z" />
		<path id="  127" className="shp0" d="M137.14 72.25C136.91 72.25 136.68 72.21 136.46 72.12C136.25 72.03 136.05 71.9 135.88 71.74C135.72 71.57 135.59 71.38 135.5 71.16C135.41 70.94 135.37 70.71 135.37 70.48C135.37 70.25 135.41 70.02 135.5 69.8C135.59 69.59 135.72 69.39 135.88 69.23C136.05 69.06 136.24 68.93 136.46 68.84C136.67 68.75 136.9 68.71 137.14 68.71C137.37 68.71 137.6 68.75 137.82 68.84C138.03 68.93 138.23 69.06 138.39 69.22C138.56 69.39 138.69 69.58 138.78 69.8C138.86 70.01 138.91 70.24 138.91 70.48C138.91 70.71 138.86 70.94 138.78 71.16C138.69 71.37 138.56 71.57 138.39 71.73C138.23 71.9 138.03 72.03 137.82 72.12C137.6 72.2 137.37 72.25 137.14 72.25Z" />
		<path id="  128" className="shp0" d="M137.15 83.56C136.92 83.56 136.69 83.52 136.47 83.43C136.26 83.34 136.06 83.21 135.89 83.05C135.73 82.88 135.6 82.69 135.51 82.47C135.42 82.26 135.38 82.03 135.38 81.79C135.38 81.56 135.42 81.33 135.51 81.12C135.6 80.9 135.73 80.7 135.89 80.54C136.06 80.37 136.25 80.24 136.47 80.16C136.68 80.07 136.91 80.02 137.15 80.03C137.38 80.02 137.61 80.07 137.83 80.15C138.04 80.24 138.24 80.37 138.4 80.54C138.57 80.7 138.7 80.9 138.79 81.11C138.87 81.33 138.92 81.56 138.92 81.79C138.92 82.02 138.87 82.26 138.79 82.47C138.7 82.69 138.57 82.88 138.4 83.05C138.24 83.21 138.04 83.34 137.83 83.43C137.61 83.52 137.38 83.56 137.15 83.56Z" />
		<path id="  129" className="shp0" d="M137.16 95.02C136.93 95.02 136.7 94.97 136.48 94.89C136.26 94.8 136.07 94.67 135.9 94.5C135.74 94.34 135.61 94.14 135.52 93.93C135.43 93.71 135.39 93.48 135.39 93.25C135.39 93.02 135.43 92.79 135.52 92.57C135.61 92.35 135.74 92.16 135.9 91.99C136.07 91.83 136.26 91.7 136.48 91.61C136.69 91.52 136.92 91.48 137.16 91.48C137.39 91.48 137.62 91.52 137.84 91.61C138.05 91.7 138.25 91.83 138.41 91.99C138.58 92.16 138.71 92.35 138.8 92.57C138.88 92.78 138.93 93.01 138.93 93.25C138.93 93.48 138.88 93.71 138.8 93.93C138.71 94.14 138.58 94.34 138.41 94.5C138.25 94.67 138.05 94.8 137.84 94.89C137.62 94.97 137.39 95.02 137.16 95.02Z" />
		<path id="  130" className="shp0" d="M137.17 106.33C136.94 106.33 136.71 106.29 136.49 106.2C136.27 106.11 136.08 105.98 135.91 105.82C135.75 105.65 135.62 105.46 135.53 105.24C135.44 105.03 135.4 104.8 135.4 104.56C135.4 104.33 135.44 104.1 135.53 103.88C135.62 103.67 135.75 103.47 135.91 103.31C136.08 103.14 136.27 103.01 136.49 102.92C136.7 102.84 136.93 102.79 137.17 102.79C137.4 102.79 137.63 102.84 137.85 102.92C138.06 103.01 138.26 103.14 138.42 103.31C138.59 103.47 138.72 103.67 138.8 103.88C138.89 104.1 138.94 104.33 138.94 104.56C138.94 104.79 138.89 105.02 138.81 105.24C138.72 105.46 138.59 105.65 138.42 105.82C138.26 105.98 138.06 106.11 137.85 106.2C137.63 106.29 137.4 106.33 137.17 106.33Z" />
		<path id="  131" className="shp0" d="M137.11 117.71C136.88 117.72 136.64 117.67 136.43 117.58C136.21 117.5 136.02 117.37 135.85 117.2C135.69 117.04 135.56 116.84 135.47 116.63C135.38 116.41 135.34 116.18 135.34 115.95C135.34 115.71 135.38 115.48 135.47 115.27C135.56 115.05 135.69 114.86 135.85 114.69C136.02 114.53 136.21 114.4 136.43 114.31C136.64 114.22 136.87 114.18 137.11 114.18C137.34 114.18 137.57 114.22 137.78 114.31C138 114.4 138.2 114.53 138.36 114.69C138.53 114.85 138.66 115.05 138.74 115.27C138.83 115.48 138.88 115.71 138.87 115.94C138.88 116.18 138.83 116.41 138.75 116.62C138.66 116.84 138.53 117.04 138.36 117.2C138.2 117.37 138 117.5 137.79 117.58C137.57 117.67 137.34 117.72 137.11 117.71Z" />
		<path id="  132" className="shp0" d="M137.19 129.1C136.96 129.1 136.73 129.06 136.51 128.97C136.29 128.88 136.1 128.75 135.93 128.59C135.77 128.42 135.64 128.23 135.55 128.01C135.46 127.8 135.42 127.56 135.42 127.33C135.42 127.1 135.46 126.87 135.55 126.65C135.64 126.44 135.77 126.24 135.93 126.08C136.1 125.91 136.29 125.78 136.51 125.69C136.72 125.61 136.95 125.56 137.19 125.56C137.42 125.56 137.65 125.6 137.86 125.69C138.08 125.78 138.28 125.91 138.44 126.07C138.61 126.24 138.74 126.43 138.82 126.65C138.91 126.87 138.96 127.1 138.96 127.33C138.96 127.56 138.91 127.79 138.83 128.01C138.74 128.22 138.61 128.42 138.44 128.58C138.28 128.75 138.08 128.88 137.87 128.97C137.65 129.06 137.42 129.1 137.19 129.1Z" />
		<path id="  133" className="shp0" d="M129.09 4.02C128.86 4.02 128.63 3.98 128.41 3.89C128.2 3.8 128 3.67 127.84 3.51C127.67 3.34 127.54 3.15 127.45 2.93C127.36 2.72 127.32 2.48 127.32 2.25C127.32 2.02 127.36 1.79 127.45 1.57C127.54 1.36 127.67 1.16 127.83 1C128 0.83 128.19 0.7 128.41 0.61C128.62 0.52 128.86 0.48 129.09 0.48C129.32 0.48 129.55 0.52 129.77 0.61C129.98 0.7 130.18 0.83 130.34 0.99C130.51 1.16 130.64 1.35 130.73 1.57C130.82 1.79 130.86 2.02 130.86 2.25C130.86 2.48 130.82 2.71 130.73 2.93C130.64 3.14 130.51 3.34 130.35 3.5C130.18 3.67 129.99 3.8 129.77 3.89C129.56 3.98 129.32 4.02 129.09 4.02Z" />
		<path id="  134" className="shp0" d="M127.83 14.77C127.12 14.06 127.12 12.93 127.83 12.22C128.53 11.52 129.67 11.52 130.37 12.22C131.08 12.92 131.08 14.06 130.37 14.76C129.67 15.47 128.53 15.47 127.83 14.77Z" />
		<path id="  135" className="shp0" d="M129.11 26.79C128.88 26.79 128.65 26.75 128.43 26.66C128.22 26.57 128.02 26.44 127.86 26.28C127.69 26.11 127.56 25.92 127.47 25.7C127.38 25.48 127.34 25.25 127.34 25.02C127.34 24.79 127.38 24.56 127.47 24.34C127.56 24.13 127.69 23.93 127.85 23.77C128.02 23.6 128.21 23.47 128.43 23.38C128.64 23.29 128.88 23.25 129.11 23.25C129.34 23.25 129.57 23.29 129.79 23.38C130 23.47 130.2 23.6 130.36 23.76C130.53 23.93 130.66 24.12 130.75 24.34C130.83 24.55 130.88 24.78 130.88 25.02C130.88 25.25 130.84 25.48 130.75 25.7C130.66 25.91 130.53 26.11 130.37 26.27C130.2 26.44 130.01 26.57 129.79 26.66C129.57 26.74 129.34 26.79 129.11 26.79Z" />
		<path id="  136" className="shp0" d="M129.12 38.1C128.89 38.1 128.66 38.06 128.44 37.97C128.23 37.88 128.03 37.75 127.87 37.59C127.7 37.42 127.57 37.23 127.48 37.01C127.39 36.8 127.35 36.57 127.35 36.33C127.35 36.1 127.39 35.87 127.48 35.66C127.57 35.44 127.7 35.24 127.86 35.08C128.03 34.91 128.22 34.78 128.44 34.7C128.65 34.61 128.88 34.56 129.12 34.57C129.35 34.56 129.58 34.61 129.8 34.69C130.01 34.78 130.21 34.91 130.37 35.08C130.54 35.24 130.67 35.44 130.76 35.65C130.84 35.87 130.89 36.1 130.89 36.33C130.89 36.56 130.85 36.79 130.76 37.01C130.67 37.23 130.54 37.42 130.38 37.59C130.21 37.75 130.02 37.88 129.8 37.97C129.58 38.06 129.35 38.1 129.12 38.1Z" />
		<path id="  137" className="shp0" d="M129.13 49.41C128.9 49.42 128.67 49.37 128.45 49.28C128.24 49.2 128.04 49.07 127.87 48.9C127.71 48.74 127.58 48.54 127.49 48.33C127.4 48.11 127.36 47.88 127.36 47.65C127.36 47.42 127.4 47.18 127.49 46.97C127.58 46.75 127.71 46.56 127.87 46.39C128.04 46.23 128.23 46.1 128.45 46.01C128.66 45.92 128.89 45.88 129.13 45.88C129.36 45.88 129.59 45.92 129.81 46.01C130.02 46.1 130.22 46.23 130.38 46.39C130.55 46.55 130.68 46.75 130.77 46.97C130.85 47.18 130.9 47.41 130.9 47.65C130.9 47.88 130.86 48.11 130.77 48.32C130.68 48.54 130.55 48.74 130.38 48.9C130.22 49.07 130.02 49.2 129.81 49.28C129.59 49.37 129.36 49.42 129.13 49.41L129.13 49.41Z" />
		<path id="  138" className="shp0" d="M129.14 60.87C128.91 60.87 128.68 60.83 128.46 60.74C128.25 60.65 128.05 60.52 127.88 60.36C127.72 60.19 127.59 60 127.5 59.78C127.41 59.57 127.37 59.34 127.37 59.1C127.37 58.87 127.41 58.64 127.5 58.42C127.59 58.21 127.72 58.01 127.88 57.85C128.05 57.68 128.24 57.55 128.46 57.46C128.67 57.38 128.9 57.33 129.14 57.33C129.37 57.33 129.6 57.38 129.82 57.46C130.03 57.55 130.23 57.68 130.39 57.85C130.56 58.01 130.69 58.21 130.78 58.42C130.86 58.64 130.91 58.87 130.91 59.1C130.91 59.33 130.86 59.56 130.78 59.78C130.69 59.99 130.56 60.19 130.39 60.36C130.23 60.52 130.03 60.65 129.82 60.74C129.6 60.83 129.37 60.87 129.14 60.87L129.14 60.87Z" />
		<path id="  139" className="shp0" d="M127.88 71.76C127.17 71.06 127.17 69.92 127.87 69.21C128.58 68.51 129.72 68.51 130.42 69.21C131.12 69.92 131.13 71.05 130.42 71.76C129.72 72.46 128.58 72.46 127.88 71.76Z" />
		<path id="  140" className="shp0" d="M127.89 83.07C127.18 82.37 127.18 81.23 127.88 80.53C128.59 79.82 129.73 79.82 130.43 80.53C131.13 81.23 131.14 82.37 130.43 83.07C129.73 83.78 128.59 83.78 127.89 83.07Z" />
		<path id="  141" className="shp0" d="M129.17 94.95C128.94 94.95 128.71 94.91 128.49 94.82C128.27 94.73 128.08 94.6 127.91 94.44C127.75 94.28 127.62 94.08 127.53 93.86C127.44 93.65 127.4 93.42 127.4 93.19C127.4 92.95 127.44 92.72 127.53 92.51C127.62 92.29 127.75 92.1 127.91 91.93C128.08 91.77 128.27 91.64 128.49 91.55C128.7 91.46 128.93 91.41 129.17 91.42C129.4 91.41 129.63 91.46 129.85 91.55C130.06 91.63 130.26 91.76 130.42 91.93C130.59 92.09 130.72 92.29 130.8 92.5C130.89 92.72 130.94 92.95 130.94 93.18C130.94 93.42 130.89 93.65 130.81 93.86C130.72 94.08 130.59 94.27 130.42 94.44C130.26 94.6 130.06 94.73 129.85 94.82C129.63 94.91 129.4 94.95 129.17 94.95Z" />
		<path id="  142" className="shp0" d="M129.18 106.41C128.95 106.41 128.72 106.37 128.5 106.28C128.28 106.19 128.09 106.06 127.92 105.9C127.76 105.73 127.63 105.54 127.54 105.32C127.45 105.1 127.41 104.87 127.41 104.64C127.41 104.41 127.45 104.18 127.54 103.96C127.63 103.75 127.76 103.55 127.92 103.39C128.09 103.22 128.28 103.09 128.5 103C128.71 102.91 128.94 102.87 129.18 102.87C129.41 102.87 129.64 102.91 129.86 103C130.07 103.09 130.27 103.22 130.43 103.38C130.6 103.55 130.73 103.74 130.81 103.96C130.9 104.17 130.95 104.41 130.95 104.64C130.95 104.87 130.9 105.1 130.82 105.32C130.73 105.53 130.6 105.73 130.43 105.89C130.27 106.06 130.07 106.19 129.86 106.28C129.64 106.36 129.41 106.41 129.18 106.41Z" />
		<path id="  143" className="shp0" d="M129.19 117.72C128.96 117.72 128.73 117.68 128.51 117.59C128.29 117.5 128.1 117.37 127.93 117.21C127.77 117.04 127.64 116.85 127.55 116.63C127.46 116.42 127.42 116.19 127.42 115.95C127.42 115.72 127.46 115.49 127.55 115.28C127.64 115.06 127.77 114.86 127.93 114.7C128.1 114.53 128.29 114.4 128.51 114.32C128.72 114.23 128.95 114.18 129.19 114.19C129.42 114.18 129.65 114.23 129.86 114.31C130.08 114.4 130.28 114.53 130.44 114.7C130.61 114.86 130.74 115.06 130.82 115.27C130.91 115.49 130.96 115.72 130.95 115.95C130.96 116.18 130.91 116.42 130.83 116.63C130.74 116.85 130.61 117.04 130.44 117.21C130.28 117.37 130.08 117.5 129.87 117.59C129.65 117.68 129.42 117.72 129.19 117.72Z" />
		<path id="  144" className="shp0" d="M127.93 128.61C127.22 127.91 127.22 126.77 127.92 126.07C128.63 125.36 129.76 125.36 130.47 126.06C131.17 126.77 131.17 127.9 130.47 128.61C129.77 129.31 128.63 129.31 127.93 128.61Z" />
		<path id="  145" className="shp0" d="M121.1 3.95C120.87 3.96 120.64 3.91 120.42 3.82C120.21 3.74 120.01 3.61 119.85 3.44C119.68 3.28 119.55 3.08 119.46 2.87C119.37 2.65 119.33 2.42 119.33 2.19C119.33 1.96 119.37 1.72 119.46 1.51C119.55 1.29 119.68 1.1 119.84 0.93C120.01 0.77 120.2 0.64 120.42 0.55C120.63 0.46 120.87 0.42 121.1 0.42C121.33 0.42 121.56 0.46 121.78 0.55C121.99 0.64 122.19 0.77 122.35 0.93C122.52 1.09 122.65 1.29 122.74 1.51C122.83 1.72 122.87 1.95 122.87 2.18C122.87 2.42 122.83 2.65 122.74 2.86C122.65 3.08 122.52 3.28 122.36 3.44C122.19 3.61 122 3.74 121.78 3.82C121.56 3.91 121.33 3.96 121.1 3.95Z" />
		<path id="  146" className="shp0" d="M121.18 15.34C120.95 15.34 120.72 15.3 120.5 15.21C120.29 15.12 120.09 14.99 119.93 14.83C119.76 14.66 119.63 14.47 119.54 14.25C119.45 14.04 119.41 13.81 119.41 13.57C119.41 13.34 119.45 13.11 119.54 12.89C119.63 12.68 119.76 12.48 119.92 12.32C120.09 12.15 120.28 12.02 120.5 11.93C120.71 11.85 120.95 11.8 121.18 11.8C121.41 11.8 121.64 11.84 121.86 11.93C122.07 12.02 122.27 12.15 122.43 12.31C122.6 12.48 122.73 12.67 122.82 12.89C122.91 13.11 122.95 13.34 122.95 13.57C122.95 13.8 122.91 14.03 122.82 14.25C122.73 14.46 122.6 14.66 122.44 14.82C122.27 14.99 122.08 15.12 121.86 15.21C121.65 15.3 121.41 15.34 121.18 15.34Z" />
		<path id="  147" className="shp0" d="M121.12 26.72C120.89 26.73 120.66 26.68 120.44 26.59C120.23 26.51 120.03 26.38 119.87 26.21C119.7 26.05 119.57 25.85 119.48 25.64C119.39 25.42 119.35 25.19 119.35 24.96C119.35 24.72 119.39 24.49 119.48 24.28C119.57 24.06 119.7 23.87 119.86 23.7C120.03 23.54 120.22 23.41 120.44 23.32C120.65 23.23 120.88 23.19 121.12 23.19C121.35 23.19 121.58 23.23 121.8 23.32C122.01 23.4 122.21 23.53 122.37 23.7C122.54 23.86 122.67 24.06 122.76 24.27C122.84 24.49 122.89 24.72 122.89 24.95C122.89 25.19 122.85 25.42 122.76 25.63C122.67 25.85 122.54 26.04 122.38 26.21C122.21 26.37 122.02 26.5 121.8 26.59C121.58 26.68 121.35 26.73 121.12 26.72Z" />
		<path id="  148" className="shp0" d="M121.2 38.11C120.97 38.11 120.74 38.07 120.52 37.98C120.31 37.89 120.11 37.76 119.95 37.6C119.78 37.43 119.65 37.24 119.56 37.02C119.47 36.8 119.43 36.57 119.43 36.34C119.43 36.11 119.47 35.88 119.56 35.66C119.65 35.45 119.78 35.25 119.94 35.09C120.11 34.92 120.3 34.79 120.52 34.7C120.73 34.61 120.97 34.57 121.2 34.57C121.43 34.57 121.66 34.61 121.88 34.7C122.09 34.79 122.29 34.92 122.45 35.08C122.62 35.25 122.75 35.44 122.84 35.66C122.92 35.87 122.97 36.11 122.97 36.34C122.97 36.57 122.93 36.8 122.84 37.02C122.75 37.23 122.62 37.43 122.46 37.59C122.29 37.76 122.1 37.89 121.88 37.98C121.66 38.06 121.43 38.11 121.2 38.11Z" />
		<path id="  149" className="shp0" d="M121.14 49.49C120.91 49.49 120.68 49.45 120.46 49.36C120.25 49.27 120.05 49.14 119.88 48.98C119.72 48.82 119.59 48.62 119.5 48.4C119.41 48.19 119.37 47.96 119.37 47.73C119.37 47.49 119.41 47.26 119.5 47.05C119.59 46.83 119.72 46.63 119.88 46.47C120.05 46.31 120.24 46.18 120.46 46.09C120.67 46 120.9 45.95 121.14 45.96C121.37 45.95 121.6 46 121.82 46.09C122.03 46.17 122.23 46.3 122.39 46.47C122.56 46.63 122.69 46.83 122.78 47.04C122.86 47.26 122.91 47.49 122.91 47.72C122.91 47.96 122.87 48.19 122.78 48.4C122.69 48.62 122.56 48.81 122.39 48.98C122.23 49.14 122.03 49.27 121.82 49.36C121.6 49.45 121.37 49.49 121.14 49.49Z" />
		<path id="  150" className="shp0" d="M121.22 60.88C120.99 60.88 120.76 60.83 120.54 60.75C120.33 60.66 120.13 60.53 119.97 60.36C119.8 60.2 119.67 60 119.58 59.79C119.49 59.57 119.45 59.34 119.45 59.11C119.45 58.88 119.49 58.65 119.58 58.43C119.67 58.22 119.8 58.02 119.96 57.85C120.13 57.69 120.32 57.56 120.54 57.47C120.75 57.38 120.98 57.34 121.22 57.34C121.45 57.34 121.68 57.38 121.9 57.47C122.11 57.56 122.31 57.69 122.47 57.85C122.64 58.02 122.77 58.21 122.86 58.43C122.94 58.64 122.99 58.87 122.99 59.11C122.99 59.34 122.95 59.57 122.86 59.79C122.77 60 122.64 60.2 122.48 60.36C122.31 60.53 122.11 60.66 121.9 60.75C121.68 60.83 121.45 60.88 121.22 60.88Z" />
		<path id="  151" className="shp0" d="M121.16 72.26C120.93 72.26 120.7 72.22 120.48 72.13C120.26 72.04 120.07 71.91 119.9 71.75C119.74 71.58 119.61 71.39 119.52 71.17C119.43 70.96 119.39 70.73 119.39 70.49C119.39 70.26 119.43 70.03 119.52 69.82C119.61 69.6 119.74 69.4 119.9 69.24C120.07 69.07 120.26 68.94 120.48 68.86C120.69 68.77 120.92 68.72 121.16 68.73C121.39 68.72 121.62 68.77 121.84 68.85C122.05 68.94 122.25 69.07 122.41 69.24C122.58 69.4 122.71 69.6 122.8 69.81C122.88 70.03 122.93 70.26 122.93 70.49C122.93 70.72 122.88 70.96 122.8 71.17C122.71 71.39 122.58 71.58 122.41 71.75C122.25 71.91 122.05 72.04 121.84 72.13C121.62 72.22 121.39 72.26 121.16 72.26Z" />
		<path id="  152" className="shp0" d="M121.17 83.57C120.94 83.58 120.71 83.53 120.49 83.44C120.27 83.36 120.08 83.23 119.91 83.06C119.75 82.9 119.62 82.7 119.53 82.49C119.44 82.27 119.4 82.04 119.4 81.81C119.4 81.58 119.44 81.34 119.53 81.13C119.62 80.91 119.75 80.72 119.91 80.55C120.08 80.39 120.27 80.26 120.49 80.17C120.7 80.08 120.93 80.04 121.17 80.04C121.4 80.04 121.63 80.08 121.85 80.17C122.06 80.26 122.26 80.39 122.42 80.55C122.59 80.71 122.72 80.91 122.8 81.13C122.89 81.34 122.94 81.57 122.94 81.81C122.94 82.04 122.89 82.27 122.81 82.48C122.72 82.7 122.59 82.9 122.42 83.06C122.26 83.23 122.06 83.36 121.85 83.44C121.63 83.53 121.4 83.58 121.17 83.57Z" />
		<path id="  153" className="shp0" d="M121.18 95.03C120.95 95.03 120.72 94.99 120.5 94.9C120.28 94.81 120.09 94.68 119.92 94.52C119.76 94.35 119.63 94.16 119.54 93.94C119.45 93.73 119.41 93.5 119.41 93.26C119.41 93.03 119.45 92.8 119.54 92.58C119.63 92.37 119.76 92.17 119.92 92.01C120.09 91.84 120.28 91.71 120.5 91.62C120.71 91.54 120.94 91.49 121.18 91.49C121.41 91.49 121.64 91.54 121.86 91.62C122.07 91.71 122.27 91.84 122.43 92.01C122.6 92.17 122.73 92.37 122.81 92.58C122.9 92.8 122.95 93.03 122.95 93.26C122.95 93.49 122.9 93.72 122.82 93.94C122.73 94.16 122.6 94.35 122.43 94.52C122.27 94.68 122.07 94.81 121.86 94.9C121.64 94.99 121.41 95.03 121.18 95.03Z" />
		<path id="  154" className="shp0" d="M121.19 106.34C120.96 106.35 120.73 106.3 120.51 106.21C120.29 106.13 120.1 106 119.93 105.83C119.77 105.67 119.64 105.47 119.55 105.26C119.46 105.04 119.42 104.81 119.42 104.58C119.42 104.34 119.46 104.11 119.55 103.9C119.64 103.68 119.77 103.49 119.93 103.32C120.1 103.16 120.29 103.03 120.51 102.94C120.72 102.85 120.95 102.81 121.19 102.81C121.42 102.81 121.65 102.85 121.86 102.94C122.08 103.02 122.28 103.15 122.44 103.32C122.61 103.48 122.74 103.68 122.82 103.89C122.91 104.11 122.96 104.34 122.95 104.57C122.96 104.81 122.91 105.04 122.83 105.25C122.74 105.47 122.61 105.66 122.44 105.83C122.28 105.99 122.08 106.12 121.87 106.21C121.65 106.3 121.42 106.35 121.19 106.34Z" />
		<path id="  155" className="shp0" d="M119.93 117.23C119.22 116.53 119.22 115.39 119.92 114.69C120.63 113.98 121.76 113.98 122.47 114.69C123.17 115.39 123.17 116.53 122.47 117.23C121.77 117.94 120.63 117.94 119.93 117.23Z" />
		<path id="  156" className="shp0" d="M121.21 129.11C120.98 129.11 120.74 129.07 120.53 128.98C120.31 128.89 120.12 128.76 119.95 128.6C119.79 128.44 119.66 128.24 119.57 128.02C119.48 127.81 119.44 127.58 119.44 127.35C119.44 127.11 119.48 126.88 119.57 126.67C119.66 126.45 119.79 126.26 119.95 126.09C120.12 125.93 120.31 125.8 120.53 125.71C120.74 125.62 120.97 125.57 121.21 125.58C121.44 125.57 121.67 125.62 121.88 125.71C122.1 125.79 122.3 125.92 122.46 126.09C122.63 126.25 122.76 126.45 122.84 126.66C122.93 126.88 122.98 127.11 122.97 127.34C122.98 127.58 122.93 127.81 122.84 128.02C122.76 128.24 122.63 128.43 122.46 128.6C122.3 128.76 122.1 128.89 121.89 128.98C121.67 129.07 121.44 129.11 121.21 129.11Z" />
		<path id="  157" className="shp0" d="M113.18 3.96C112.95 3.96 112.72 3.92 112.5 3.83C112.29 3.74 112.09 3.61 111.93 3.45C111.76 3.28 111.63 3.09 111.54 2.87C111.45 2.66 111.41 2.43 111.41 2.19C111.41 1.96 111.45 1.73 111.54 1.52C111.63 1.3 111.76 1.1 111.92 0.94C112.09 0.77 112.28 0.64 112.5 0.56C112.71 0.47 112.95 0.42 113.18 0.43C113.41 0.42 113.64 0.47 113.86 0.55C114.07 0.64 114.27 0.77 114.43 0.94C114.6 1.1 114.73 1.3 114.82 1.51C114.91 1.73 114.95 1.96 114.95 2.19C114.95 2.42 114.91 2.66 114.82 2.87C114.73 3.09 114.6 3.28 114.44 3.45C114.27 3.61 114.08 3.74 113.86 3.83C113.65 3.92 113.41 3.96 113.18 3.96Z" />
		<path id="  158" className="shp0" d="M113.19 15.42C112.96 15.42 112.73 15.37 112.51 15.29C112.3 15.2 112.1 15.07 111.94 14.9C111.77 14.74 111.64 14.54 111.55 14.33C111.46 14.11 111.42 13.88 111.42 13.65C111.42 13.42 111.46 13.19 111.55 12.97C111.64 12.75 111.77 12.56 111.93 12.39C112.1 12.23 112.29 12.1 112.51 12.01C112.72 11.92 112.96 11.88 113.19 11.88C113.42 11.88 113.65 11.92 113.87 12.01C114.08 12.1 114.28 12.23 114.44 12.39C114.61 12.56 114.74 12.75 114.83 12.97C114.92 13.18 114.96 13.41 114.96 13.65C114.94 13.87 114.88 14.09 114.78 14.3C114.68 14.5 114.55 14.69 114.39 14.85C114.23 15.01 114.05 15.14 113.84 15.24C113.64 15.33 113.42 15.39 113.19 15.42Z" />
		<path id="  159" className="shp0" d="M113.2 26.73C112.97 26.73 112.74 26.69 112.52 26.6C112.31 26.51 112.11 26.38 111.95 26.22C111.78 26.05 111.65 25.86 111.56 25.64C111.47 25.43 111.43 25.2 111.43 24.96C111.43 24.73 111.47 24.5 111.56 24.28C111.65 24.07 111.78 23.87 111.94 23.71C112.11 23.54 112.3 23.41 112.52 23.32C112.73 23.24 112.97 23.19 113.2 23.19C113.43 23.19 113.66 23.24 113.88 23.32C114.09 23.41 114.29 23.54 114.45 23.71C114.62 23.87 114.75 24.07 114.84 24.28C114.92 24.5 114.97 24.73 114.97 24.96C114.97 25.19 114.93 25.42 114.84 25.64C114.75 25.86 114.62 26.05 114.46 26.22C114.29 26.38 114.1 26.51 113.88 26.6C113.66 26.69 113.43 26.73 113.2 26.73Z" />
		<path id="  160" className="shp0" d="M111.94 37.62C111.23 36.92 111.23 35.78 111.94 35.07C112.64 34.37 113.78 34.37 114.48 35.07C115.19 35.78 115.19 36.91 114.48 37.62C113.78 38.32 112.64 38.32 111.94 37.62Z" />
		<path id="  161" className="shp0" d="M111.95 48.93C111.24 48.23 111.24 47.09 111.95 46.39C112.65 45.68 113.79 45.68 114.49 46.39C115.2 47.09 115.2 48.23 114.49 48.93C113.79 49.64 112.65 49.64 111.95 48.93Z" />
		<path id="  162" className="shp0" d="M113.23 60.81C113 60.81 112.77 60.77 112.55 60.68C112.34 60.6 112.14 60.47 111.97 60.3C111.81 60.14 111.68 59.94 111.59 59.73C111.5 59.51 111.46 59.28 111.46 59.05C111.46 58.81 111.5 58.58 111.59 58.37C111.68 58.15 111.81 57.96 111.97 57.79C112.14 57.63 112.33 57.5 112.55 57.41C112.76 57.32 112.99 57.27 113.23 57.28C113.46 57.27 113.69 57.32 113.91 57.41C114.12 57.49 114.32 57.62 114.48 57.79C114.65 57.95 114.78 58.15 114.87 58.36C114.95 58.58 115 58.81 115 59.04C115 59.28 114.96 59.51 114.87 59.72C114.78 59.94 114.65 60.13 114.49 60.3C114.32 60.46 114.12 60.59 113.91 60.68C113.69 60.77 113.46 60.81 113.23 60.81Z" />
		<path id="  163" className="shp0" d="M113.24 72.27C113.01 72.27 112.78 72.23 112.56 72.14C112.35 72.05 112.15 71.92 111.98 71.76C111.82 71.59 111.69 71.4 111.6 71.18C111.51 70.96 111.47 70.73 111.47 70.5C111.47 70.27 111.51 70.04 111.6 69.82C111.69 69.61 111.82 69.41 111.98 69.25C112.15 69.08 112.34 68.95 112.56 68.86C112.77 68.77 113 68.73 113.24 68.73C113.47 68.73 113.7 68.77 113.92 68.86C114.13 68.95 114.33 69.08 114.49 69.24C114.66 69.41 114.79 69.6 114.88 69.82C114.96 70.03 115.01 70.27 115.01 70.5C115.01 70.73 114.96 70.96 114.88 71.18C114.79 71.39 114.66 71.59 114.49 71.75C114.33 71.92 114.13 72.05 113.92 72.14C113.7 72.23 113.47 72.27 113.24 72.27Z" />
		<path id="  164" className="shp0" d="M113.25 83.58C113.02 83.58 112.79 83.54 112.57 83.45C112.35 83.36 112.16 83.23 111.99 83.07C111.83 82.9 111.7 82.71 111.61 82.49C111.52 82.28 111.48 82.05 111.48 81.81C111.48 81.58 111.52 81.35 111.61 81.14C111.7 80.92 111.83 80.72 111.99 80.56C112.16 80.39 112.35 80.26 112.57 80.18C112.78 80.09 113.01 80.04 113.25 80.05C113.48 80.04 113.71 80.09 113.93 80.18C114.14 80.26 114.34 80.39 114.5 80.56C114.67 80.72 114.8 80.92 114.89 81.13C114.97 81.35 115.02 81.58 115.02 81.81C115.02 82.04 114.97 82.28 114.89 82.49C114.8 82.71 114.67 82.9 114.5 83.07C114.34 83.23 114.14 83.36 113.93 83.45C113.71 83.54 113.48 83.58 113.25 83.58Z" />
		<path id="  165" className="shp0" d="M111.99 94.47C111.28 93.77 111.28 92.63 111.98 91.93C112.69 91.22 113.83 91.22 114.53 91.92C115.23 92.63 115.23 93.77 114.53 94.47C113.83 95.17 112.69 95.17 111.99 94.47Z" />
		<path id="  166" className="shp0" d="M111.93 105.86C111.22 105.15 111.22 104.02 111.92 103.31C112.63 102.61 113.76 102.61 114.47 103.31C115.17 104.01 115.17 105.15 114.47 105.85C113.77 106.56 112.63 106.56 111.93 105.86Z" />
		<path id="  167" className="shp0" d="M113.21 117.73C112.98 117.74 112.74 117.69 112.53 117.61C112.31 117.52 112.12 117.39 111.95 117.22C111.79 117.06 111.66 116.86 111.57 116.65C111.48 116.43 111.44 116.2 111.44 115.97C111.44 115.74 111.48 115.5 111.57 115.29C111.66 115.07 111.79 114.88 111.95 114.71C112.12 114.55 112.31 114.42 112.53 114.33C112.74 114.24 112.97 114.2 113.21 114.2C113.44 114.2 113.67 114.24 113.88 114.33C114.1 114.42 114.3 114.55 114.46 114.71C114.63 114.88 114.76 115.07 114.84 115.29C114.93 115.5 114.98 115.73 114.97 115.97C114.98 116.2 114.93 116.43 114.85 116.64C114.76 116.86 114.63 117.06 114.46 117.22C114.3 117.39 114.1 117.52 113.89 117.6C113.67 117.69 113.44 117.74 113.21 117.73Z" />
		<path id="  168" className="shp0" d="M113.29 129.12C113.06 129.12 112.82 129.08 112.61 128.99C112.39 128.9 112.2 128.77 112.03 128.61C111.87 128.44 111.74 128.25 111.65 128.03C111.56 127.82 111.52 127.59 111.52 127.35C111.52 127.12 111.56 126.89 111.65 126.67C111.74 126.46 111.87 126.26 112.03 126.1C112.2 125.93 112.39 125.8 112.61 125.71C112.82 125.63 113.05 125.58 113.29 125.58C113.52 125.58 113.75 125.62 113.96 125.71C114.18 125.8 114.38 125.93 114.54 126.09C114.71 126.26 114.84 126.46 114.92 126.67C115.01 126.89 115.06 127.12 115.05 127.35C115.06 127.58 115.01 127.81 114.93 128.03C114.84 128.24 114.71 128.44 114.54 128.6C114.38 128.77 114.18 128.9 113.97 128.99C113.75 129.08 113.52 129.12 113.29 129.12Z" />
	</g>
</svg>
)

export default Dots
