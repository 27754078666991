import React ,{Component} from 'react';
import YouTube from 'react-youtube';
import ClickOutsideListener from "./ClickOutsideListener"

class PageBannerVideo extends Component {
	constructor(props) {
		super(props);
		this.playVideo = this.playVideo.bind(this);
		this.closeVideoFunc = this.closeVideoFunc.bind(this);
		this._onReady = this._onReady.bind(this);
		this.player = null;
		this.opts = {
			playerVars: {
				autoplay: 1
			}
		}

		this.state = {
			active: false
		};
	}
	playVideo() {
		this.setState({ active: true });
		document.body.classList.add("disable-scrolling");
	}
	closeVideoFunc(){
		this.setState({ active: false });
		this.player.pauseVideo();
		document.body.classList.remove("disable-scrolling");
	}
	_onReady(event){
		this.player = event.target;
	}
	render() {
		return(
			<>
				<button aria-label="Play" className="play-button" onClick={this.playVideo} >
					<i className="fas fa-play"></i>
				</button>
				<div className={`video-wrap ${this.state.active ? 'active': ''}`}>
					{ this.state.active ?
						<ClickOutsideListener onClickOutside={this.closeVideoFunc}>
							<YouTube
								opts={this.opts}
								videoId="LRB74MbN3Ck"
								className="banner-video"
								onReady={this._onReady}
							/>
						</ClickOutsideListener>
					: null }
				</div>
			</>
		);
	}
}

export default PageBannerVideo
